<p-card *ngIf="financialDetail">
  <div class="grid">
    <div class="col-12 lg:col-6">
      <h1 class="mb-1">Detalle de la Fondadora</h1>
    </div>
    <ng-container *ngIf="financialDetail">
      <div class="col-12 lg:col-6 text-right">
        <p-splitButton label="Acciones de usuario" [menuStyle]="{width: '100%'}"  [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="financialDetail">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-user"></i>
            <span class="ml-2">Detalles de la Fondeadora</span>
          </ng-template>
          <app-financial-detail></app-financial-detail>
          <p-table
            #dtFoundingBalance
            [scrollable]="true"
            scrollHeight="600px"
            [value]="financialDetail.fundingBalances || []"
            [columns]="cols"
            dataKey="_id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [loading]="loading"
            [paginator]="true"
            [tableStyle]="{'width': '100%'}"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} balances"
            [globalFilterFields]="['balanceName', 'balance']"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of cols" [style.min-width.px]="150">
                  <div class="flex align-items-center" [pSortableColumn]="col.field">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </div>
                </th>
                <ng-container *ngIf="authService.userHasAuthorization('FINANCIAL', 'UPDATE')">
                  <th style="min-width:10rem">
                    Acciones
                  </th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fundingBalance>
              <tr>
                <td>{{ fundingBalance.balanceName }}</td>
                <td>{{ fundingBalance.balance | currency }}</td>
                <td>
                  <ng-container *ngIf="authService.userHasAuthorization('FINANCIAL', 'UPDATE')">
                    <td>
                      <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newFunding('UPDATE', fundingBalance)" class="p-button-rounded p-button-text"></button>
                    </td>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="2">No hay balances disponibles para esta fondeadora.</td>
              </tr>
            </ng-template>
          </p-table>

        </p-tabPanel>
      </p-tabView>
    </div>
  </ng-container>

  <p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nuevo Fondeador' : 'Actualizar Fondeador'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
      <app-create-financial [entity]="entity" [submitted]="submitted"></app-create-financial>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</p-card>

<!-- Modal -->
<p-dialog [(visible)]="createFunding" [style]="{ width: '450px' }"  [header]="formAction === 'CREATE' ? 'Nueva Linea de Fondeo' : 'Actualizar Linea de Fondeo'" [modal]="true" styleClass="p-fluid">
<ng-template pTemplate="content">
  <app-founding-balance-modal [entity]="foundingBalanceForm" [submitted]="submitted" [type]="formAction" [fundingBalance]="selectedFundingBalance"> </app-founding-balance-modal>
</ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="handleSave()" [disabled]="!foundingBalanceForm.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<app-disperse-balance [financialDetail]="financialDetail" (balanceUpdated)="disperseFunding($event)"></app-disperse-balance>
<app-increase-balance [financialDetail]="financialDetail" (saveEvent)="handleSaveEvent($event)"></app-increase-balance>
<p-toast></p-toast>




