import {Table} from "primeng/table";
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {DataTableService} from "@data/services/utils/data-table.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {IAccount} from "@modules/private/bank-accounts/data/interfaces/iapi-account.interface";
import {ApiBankAccountService} from "@modules/private/bank-accounts/data/services/api/api-bank-account.service";
import {DatesExportService} from "@data/services/utils/dates-export.service";

@Component({
  selector: 'app-list-accounts',
  templateUrl: './list-accounts.component.html',
  styleUrls: ['./list-accounts.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ListAccountsComponent implements OnInit {
  accounts!: IAccount[];

  cols: any[] = [
    { field: 'name', header: 'Nombre' },
    {field: 'businessName', header: 'Nombre Empresarial'},
    { field: 'agreementDate', header: 'Fecha de Activación' },
    { field: 'bank', header: 'Banco' },
    { field: 'clabe', header: 'Clabe' },
    { field: 'creationDate', header: 'Fecha de Creación' },
    { field: 'isLoanTransactionDefault', header: '¿Dispersión de Creditos Automatica?' },
    { field: 'isSoDTransactionDefault', header: '¿Dispersión de SoD Automatica?' }

  ];

  allCols: any[] = [];


  colsPdf: any[] = [
    { dataKey: 'Nombre', title: 'Nombre' },
    { dataKey: 'Nombre Empresarial', title: 'Nombre Empresarial' },
    { dataKey: 'Fecha de Activacion', title: 'Fecha de Activación' },
    { dataKey: 'Banco', title: 'Banco' },
    { dataKey: 'Clabe', title: 'CLABE' },
    { dataKey: 'Fecha de Creacion', title: 'Fecha de Creación' },
    { dataKey: '¿Dispersion de Credito Automatica?', title: '¿Dispersion de Creditos Automatica?' },
    { dataKey: '¿Dispersion de SoD Automatica?', title: '¿Dispersion de SoD Automatica?' }
  ];

  loading: boolean = true;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;
  formAction: 'CREATE' | 'UPDATE' = 'CREATE';
  createEntity = false;
  @ViewChild('dtAccounts') dtAccounts!: Table;

  constructor(
    private apiAccountService: ApiBankAccountService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService,
    private dateExportService: DatesExportService
  ) {
    this.createMenu();
  }

  ngOnInit() {

    this.allCols = [
      { field: 'name', header: 'Nombre' },
      {field: 'businessName', header: 'Nombre Empresarial'},
      { field: 'agreementDate', header: 'Fecha de Activacion' },
      { field: 'bank', header: 'Banco' },
      { field: 'clabe', header: 'Clabe' },
      { field: 'creationDate', header: 'Fecha de Creacion' },
      { field: 'isLoanTransactionDefault', header: '¿Dispersion de Credito Automatica?' },
      { field: 'isSoDTransactionDefault', header: '¿Dispersion de SoD Automatica?' }

    ];

    this.cols = [...this.allCols];

    this.find();
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiAccountService.getAll().subscribe(r => {
      if (!r.error) {
        this.accounts = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          r.agreementDate = new Date(r.agreementDate)
          return r
        });
      }
      this.loading = false;
    });
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value;
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IAccount | null = null) {
    this.formAction = formAction;
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        businessName: ['', [Validators.required]],
        agreementDate: ['', [Validators.required]],
        clabe: ['', [Validators.required]],
        bank: ['', [Validators.required]],
        isLoanTransactionDefault: [false],
        isSoDTransactionDefault: [false]
      });
    } else {
      this.entity = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        businessName: [data?.businessName, [Validators.required]],
        agreementDate: [data?.agreementDate, [Validators.required]],
        clabe: [data?.clabe, [Validators.required]],
        bank: [data?.bank, [Validators.required]],
        balance: [data?.balance],
        totalDebits: [data?.totalDebits],
        totalDeposits: [data?.totalDeposits],
        id: [data?._id],
        isLoanTransactionDefault: [data?.isLoanTransactionDefault],
        isSoDTransactionDefault: [data?.isSoDTransactionDefault]
      });
    }
    this.entity.clearValidators();
    this.submitted = false;
    this.createEntity = true;
  }

  saveProduct(): void {
    this.submitted = true;
    if (this.entity.valid) {
      const accountData = this.entity.value;
      accountData.clabe = accountData.clabe.replace(/ /g, '');

      if (this.formAction === 'CREATE') {
        this.apiAccountService.createAccount(accountData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'success',
              summary: response.error ? 'Error' : 'Exitoso',
              detail: response.error ? response.msg : 'Registro creado exitosamente.',
              life: 3000
            });
            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al crear el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Error al crear cuenta bancaria: ${error.msg}`,
              life: 3000
            });
          }
        );
      } else {
        accountData._id = this.entity.get('_id')?.value;

        this.apiAccountService.updateAccount(accountData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'success',
              summary: response.error ? 'Error' : 'Exitoso',
              detail: response.error ? `Error al actualizar cuenta bancaria: ${response.msg}` : 'Registro actualizado exitosamente.',
              life: 3000
            });
            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al actualizar el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `No se pudo actualizar el registro: ${error.msg}`,
              life: 3000
            });
          }
        );
      }
    }
  }

  createMenu() {
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const originalValue = this.dtAccounts.filteredValue || this.dtAccounts.value
                this.dtAccounts.value = originalValue.map((row: any) => {
                  return {
                    ...row,
                    agreementDate: this.dateExportService.formatDate(row.agreementDate),
                    creationDate: this.dateExportService.formatDate(row.creationDate),
                  };
                });
                this.dtAccounts.exportCSV();
                this.dtAccounts.value = originalValue;
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })
              }
            },
            {
              label: 'Excel',
              command: () => {
                const accountData = this.dtAccounts.filteredValue || this.dtAccounts.value
                const columns = this.dtAccounts.columns || []
                const formattedAccountData = this.dateExportService.formatDatesExportData(accountData, columns)
                this.dataTableService.exportExcel(formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })

              }
            },
            {
              label: 'PDF',
              command: () => {
                const accountData = this.dtAccounts.filteredValue || this.dtAccounts.value
                const columns = this.dtAccounts.columns || []
                const formattedFinancialData = this.dateExportService.formatDatesExportData(accountData, columns);
                this.dataTableService.exportPdf(this.colsPdf, formattedFinancialData);
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtAccounts.clear();
          this.find();
        }
      },
    ];
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE');
          }
        }
      ];
    }
  }
}
