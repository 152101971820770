import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-financial',
  templateUrl: './create-financial.component.html',
  styleUrls: ['./create-financial.component.scss']
})
export class CreateFinancialComponent implements OnInit {
  @Input() entity!: FormGroup;
  @Input() submitted!: boolean;
  @Input() type: 'CREATE' | 'UPDATE' = 'CREATE';

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    if (!this.entity) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.entity = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      businessName: ['', [Validators.required, Validators.minLength(3)]],
      agreementDate: ['', Validators.required],
      address: [''],
    });
  }

  onSubmit() {
    if (this.entity.valid) {
      this.entity.getRawValue();
    }
  }
}
