import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IFinancial} from '@modules/private/financial/data/interfaces/iapi-financial.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-increase-balance',
  templateUrl: './increase-balance.component.html',
  styleUrls: ['./increase-balance.component.scss']
})
export class IncreaseBalanceComponent implements OnInit{
  @Input() financialDetail!: IFinancial;
  @Output() saveEvent = new EventEmitter<void>();
  displayModal: boolean = false;
  disperseForm!: FormGroup
  minBalanceAmount: number = 0;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.disperseForm = this.fb.group({
      balance: [null, Validators.required],
      balanceSelected: [null, Validators.required],
      _id: [null, Validators.required]
    });

  }

  onBalanceChange(event: any) {
    const selectedBalance = event.value;
    if (selectedBalance) {
      const balanceAmount = selectedBalance.balance || 0;
      this.disperseForm.patchValue({
        balance: balanceAmount,
        _id: selectedBalance._id
      });
      this.minBalanceAmount = balanceAmount;
    }
  }

  showModalDialog() {
    this.displayModal = true;
  }

  hideModalDialog() {
    this.disperseForm.get('selectedBalance')?.setValue(null)
    this.displayModal = false
  }

  resetForm() {
    this.disperseForm.reset();
  }

  save() {
    if (this.disperseForm.valid) {
      const formValue = this.disperseForm.value;
      this.saveEvent.emit(formValue);
      this.hideModalDialog();
    } else {
      Object.keys(this.disperseForm.controls).forEach(controlName => {
        const control = this.disperseForm.get(controlName);
        if (control) {
          if (control.errors) {
            console.error(`Errores en el control ${controlName}:`, control.errors);
          }
        }
      });
    }
  }
}
