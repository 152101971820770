import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IAILoanAnalysis, IPushingFlowDetail} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AiAzureService extends ApiClass{

  constructor() {
    super()
    this.path = 'azure'
  }

  getById(id: string) {
    return this.postCollection({flowId: id}, '', environment.apiUrl, 'azure/aiData').pipe(map((r) => r as { error: boolean; msg: string; data: IAILoanAnalysis}))
  }
}
