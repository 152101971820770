import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BANKS } from '@data/constants/banks.const';

@Component({
  selector: 'app-create-accounts',
  templateUrl: './create-accounts.component.html',
  styleUrls: ['./create-accounts.component.scss']
})
export class CreateAccountsComponent implements OnInit {
  @Input() entity!: FormGroup;
  @Input() submitted!: boolean;
  @Input() type: 'CREATE' | 'UPDATE' = 'CREATE'
  banks = BANKS.map(bank => ({ label: bank.banco, value: bank.banco }));

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.entity) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.entity = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      businessName: ['', [Validators.required, Validators.minLength(3)]],
      agreementDate: ['', Validators.required],
      clabe: ['', [Validators.required]],
      bank: ['', [Validators.required]],
      defaultAccount: [false]
    });
  }


  onSubmit() {
    if (this.entity.valid) {
      this.entity.getRawValue();
    }
  }
}
