import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from "primeng/api";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiPeriodService} from "@modules/private/payroll/data/services/api/api-period.service";
import {LoaderService} from "@data/services/page/loader.service";
import {ActivatedRoute} from "@angular/router";
import {IPeriod} from "@modules/private/payroll/data/interfaces/iperiod.interface";
import {STATUS_CALENDAR_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-calendar-payroll',
  templateUrl: './calendar-payroll.component.html',
  styleUrls: ['./calendar-payroll.component.scss'],
  providers: [MessageService]
})
export class CalendarPayrollComponent  implements OnInit  {
  date: Date[] = []
  paymentDates: Date[] = []
  entity!: FormGroup
  periodDates: {paymentDates: Date[], cutoffDate: Date[], incidentDate: Date[]} = {paymentDates: [], cutoffDate: [], incidentDate: []}
  id!: string
  statuses = STATUS_CALENDAR_ENUM
  constructor(
    private fb: FormBuilder,
    private apiPeriodService: ApiPeriodService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.constructEntity()
    this.getAllDates()
  }

  constructEntity() {
    this.entity = this.fb.group({
      id: [this.id,[Validators.required]],
      dates: this.fb.array([])
    })
  }

  dateIsGraterThanToday() {

  }

  isPaymentDate(day: number, month: number, year: number, kind: 'PAYMENT' | 'CUTOFF' | 'INCIDENT' | 'ALL' | 'DEFAULT') {
    switch (kind) {
      case 'INCIDENT':
        return this.periodDates.incidentDate.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length > 0
      case 'PAYMENT':
        return this.periodDates.paymentDates.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length > 0
      case 'CUTOFF':
        return this.periodDates.cutoffDate.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length > 0
      case 'ALL':
        return this.date.filter(p => (p.getDate() === day && p.getMonth() === month && p.getFullYear() === year)).length === 0
      case 'DEFAULT':
        return this.date.filter(p => (p.getDate() === day && p.getMonth() === month && p.getFullYear() === year)).length >= 1 && this.periodDates.cutoffDate.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length === 0 && this.periodDates.paymentDates.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length === 0 && this.periodDates.incidentDate.filter(p => p.getDate() === day && p.getMonth() === month && p.getFullYear() === year).length === 0
    }
  }

  getAllDates() {
    console.log(this.id)
    this.periodDates = {paymentDates: [], cutoffDate: [], incidentDate: []}
    this.date = []
    this.constructEntity()
    this.apiPeriodService.getAll(this.id).subscribe(r => {
      console.log(r)
      if (!r.error) {
        r.data.forEach(p => {
          const period = {
            _id: p._id,
            paymentDate: new Date(new Date(p.paymentDate).getTime() + 60 * 60 * 6 * 1000),
            cutoffDate: new Date(new Date(p.cutoffDate).getTime() + 60 * 60 * 6 * 1000),
            incidentDate: new Date(new Date(p.incidentDate).getTime() + 60 * 60 * 6 * 1000),
            status: p.status
          }
          this.addDate(period)
          this.periodDates.paymentDates = [...this.periodDates.paymentDates , period.paymentDate]
          this.periodDates.cutoffDate = [...this.periodDates.cutoffDate , period.cutoffDate]
          this.periodDates.incidentDate = [...this.periodDates.incidentDate , period.incidentDate]
          this.date = [...this.date, period.cutoffDate, period.incidentDate, period.paymentDate ]
        })
      }
    })
  }

  createPeriod(date: any) {
    console.log(date.value)
    this.loaderService.showSpinner()
    this.apiPeriodService.create({
      payrollId: this.id,
      paymentDate: date.value.paymentDate,
      incidentDate: date.value.incidentDate,
      cutoffDate: date.value.cutoffDate
    }).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 5000 });
      if (!r.error) {
        this.getAllDates()
      }
      console.log(r)
    })
  }

  updatePeriod(date: any) {
    console.log('UPDATE', date.value)
    this.loaderService.showSpinner()
    this.apiPeriodService.update({
      periodId: date.value._id,
      payrollId: this.id,
      paymentDate: date.value.paymentDate,
      incidentDate: date.value.incidentDate,
      cutoffDate: date.value.cutoffDate
    }).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 5000 })
      if (!r.error) {
        this.getAllDates()
      }
      console.log(r)
    })

  }

  get dates() {
    return this.entity.get('dates') as FormArray
  }

  currentDate(i: number) {
    return this.entity.get('dates')?.value[i]
  }


  addDate(period: IPeriod | null = null) {
    console.log(period)
    this.dates.push(this.fb.group({
      _id: period?._id ?? null,
      cutoffDate: [ period?.cutoffDate ? new Date(period?.cutoffDate): '', [Validators.required]],
      paymentDate: [ period?.paymentDate ? new Date(period?.paymentDate) : '', [Validators.required]],
      incidentDate: [ period?.incidentDate ? new Date(period?.incidentDate): '', [Validators.required]],
      isNextDate: [period !== null && new Date(period?.cutoffDate).getTime() - new Date().getTime() > 0 || period === null],
      status: [period?.status]
    }))
  }

  removeDate(i: number, id: string) {
    if (id === null) this.dates.removeAt(i)
    else {
      this.loaderService.showSpinner()
      this.apiPeriodService.delete(id).subscribe(r => {
        this.messageService.add({
          severity: r.error ? 'error' : 'success',
          summary: r.error ? 'Error' : 'Exitoso',
          detail: r.error ? r.msg : r.msg,
          life: 5000
        });
        if (!r.error) {
          this.dates.removeAt(i)
          this.getAllDates()
        }
        this.loaderService.hideSpinner()
        console.log(r)
      })
    }
  }

  onChange() {
    console.log(this.date)
  }

  save() {
    this.getAllDates()
  }

}
