<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Calendario de Nómina</h1>
      <p class="text-color-secondary">
        El calendario de la nómina permitira configurar las fechas, que afectaran a los contratos, los dias trabajados para el producto de salario on demand y permitira hacer el envio automatico de incidencias.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mb-5">
      <p-calendar
        class="max-calendar-width"
        [(ngModel)]="date"
        [inline]="true"
        [showWeek]="true"
        [lang]="'es'"
        [numberOfMonths]="2"
        (ngModelChange)="onChange()"
        [selectionMode]="'multiple'"
      >
        <ng-template pTemplate="date" let-date>
          <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'ALL')">
      <span style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: black;">
        {{date.day}}
    </span>
          </ng-container>
          <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'INCIDENT')">
      <span [ngStyle]="{backgroundColor: '#27396a'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
        {{date.day}}
    </span>
          </ng-container>
          <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'CUTOFF')">
        <span [ngStyle]="{backgroundColor: '#1a81ae'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
          {{date.day}}
      </span>
          </ng-container>
          <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'PAYMENT')">
        <span [ngStyle]="{backgroundColor: '#1cd16b'}" style="border-radius:50%; padding: .25em; width: 2em; height: 2em; display:flex; justify-content: center; align-items: center;color: white;">
          {{date.day}}
      </span>
          </ng-container>
          <ng-container *ngIf="isPaymentDate(date.day, date.month, date.year, 'DEFAULT')">
      <span style="color: black !important;">
        {{date.day}}
    </span>
          </ng-container>
        </ng-template>
      </p-calendar>
      <p-toast></p-toast>
    </div>
    <div class="col-12 mb-4">
      <div class="grid">
        <!--<div class="col-2 col-offset-10 text-right">
          <button pButton label="Agregar" icon="fa-solid fa-plus" class="p-button-success p-button-sm" (click)="addDate()"></button>
        </div>-->
        <div class="col-12">
          <p-chip styleClass="pl-0 pr-3 mr-2">
          <span class="bg-cutoff-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
            <span class="ml-2 font-medium">
              Fecha de corte
          </span>
          </p-chip>
          <p-chip styleClass="pl-0 pr-3">
          <span class="bg-incident-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
            <span class="ml-2 font-medium">
              Fecha de envio de incidencias
          </span>
          </p-chip>
          <p-chip styleClass="pl-0 pr-3 mr-2">
          <span class="bg-payment-date border-circle w-2rem h-2rem flex align-items-center justify-content-center">
          </span>
            <span class="ml-2 font-medium">
              Fecha de pago
          </span>
          </p-chip>
        </div>
      </div>
    </div>
    <div class="col-12">
      <form [formGroup]="entity">
        <div class="grid" formArrayName="dates">
          <div class="col-12">
            <div class="grid card-calendar" *ngFor="let date of dates.controls; let i = index">
              <div class="col-12" [formGroupName]="i">
                <div class="grid">
                  <div class="col-12">
                    <p>Estatus: <strong>{{date.get('status')?.value | selectEnum: statuses}}</strong></p>

                  </div>
                  <div class="col-12 md:col-6 xl:col-3">
                    <div class="field field-flex">
                      <label for="cutoffDate">Fecha de corte:</label>
                      <p-calendar
                        id="cutoffDate"
                        formControlName="cutoffDate"
                        [showIcon]="true"
                        [showOnFocus]="false"
                        [numberOfMonths]="1"
                        inputId="buttondisplay"
                      />
                      <ng-container *ngIf="entity.get('cutoffDate')?.invalid && (entity.get('cutoffDate')?.dirty || entity.get('cutoffDate')?.touched)">
                        <small class="p-error" *ngIf="entity.get('cutoffDate')?.errors?.['required']">La fecha de corte es obligatoria.</small>
                        <small class="p-error" *ngIf="entity.get('cutoffDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 xl:col-3">
                    <div class="field field-flex">
                      <label for="incidentDate">Fecha de envio de incidencias:</label>
                      <p-calendar
                        [disabled]="true"
                        id="incidentDate" formControlName="incidentDate"
                        [showIcon]="true"
                        [showOnFocus]="false"
                        [numberOfMonths]="1"
                        inputId="buttondisplay" />
                      <ng-container *ngIf="entity.get('incidentDate')?.invalid && (entity.get('incidentDate')?.dirty || entity.get('incidentDate')?.touched)">
                        <small class="p-error" *ngIf="entity.get('incidentDate')?.errors?.['required']">La fecha de envió de incidencias es obligatoria.</small>
                        <small class="p-error" *ngIf="entity.get('incidentDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-12 md:col-6 xl:col-3">
                    <div class="field field-flex">
                      <label for="paymentDate">Fecha de pago:</label>
                      <p-calendar
                        id="paymentDate" formControlName="paymentDate"
                        [showIcon]="true"
                        [showOnFocus]="false"
                        [numberOfMonths]="1"
                        inputId="buttondisplay" />
                      <ng-container *ngIf="entity.get('paymentDate')?.invalid && (entity.get('paymentDate')?.dirty || entity.get('paymentDate')?.touched)">
                        <small class="p-error" *ngIf="entity.get('paymentDate')?.errors?.['required']">La fecha de pago es obligatoria.</small>
                        <small class="p-error" *ngIf="entity.get('paymentDate')?.errors?.['invalidDate']">La primer fecha debe ser menor a hoy.</small>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngIf="dates.controls[i].value.isNextDate">
                    <div class="col-3 flex flex-row justify-content-end align-items-center">
                      <ng-container *ngIf="authService.userHasAuthorization('PAYROLL','DELETE_DATE_CALENDAR') && date.get('status')?.value === 'PENDING' || authService.userHasAuthorization('PAYROLL','DELETE_CURRENT_DATE_CALENDAR')">
                        <button pButton label="Eliminar" icon="fa-solid fa-times" class="p-button-danger p-button-sm button-small" (click)="removeDate(i, currentDate(i)._id )"></button>
                        </ng-container>
                      <ng-container *ngIf="currentDate(i)._id === null; else endUpdate">
                        <button pButton label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm button-small ml-3" (click)="createPeriod(date)" ></button>
                      </ng-container>
                      <ng-template #endUpdate>
                        <ng-container *ngIf="authService.userHasAuthorization('PAYROLL','UPDATE_DATE_CALENDAR') && date.get('status')?.value === 'PENDING' || authService.userHasAuthorization('PAYROLL','UPDATE_CURRENT_DATE_CALENDAR')">
                          <button pButton label="Actualizar" icon="fa-solid fa-pencil" class="p-button-primary p-button-sm button-small ml-3" (click)="updatePeriod(date)" ></button>
                        </ng-container>
                      </ng-template>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</p-card>
