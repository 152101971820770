<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1>Detalle del crédito
        <ng-container *ngIf="data">
          <p-chip styleClass="pl-0 pr-3 ml-2">
            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="fa-solid fa-file-invoice"></i></span>
            <span class="ml-2 font-medium chip">{{data.folio}}</span>
          </p-chip>
          <ng-container *ngIf="data.status !== 'DENIED'; else deniedLoan">
            <p-chip styleClass="pl-0 pr-3 ml-2">
              <span class="bg-danger border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="fa-solid fa-heart-pulse"></i></span>
              <span class="ml-2 font-medium chip" >{{data.lifeStatus | selectEnum: $_lifeStatus }} <ng-container *ngIf="data.lifeStatus === 'RESTRUCTURING' && data.referenceLoanId">DESTINO: <a class="text-decoration-underline" (click)="goLoan(data.referenceLoanId._id)">{{data.referenceLoanId.folio}}</a></ng-container></span>
            </p-chip>
            <ng-container *ngIf="data.kind !== 'ORIGIN'">
              <p-chip styleClass="pl-0 pr-3 ml-2">
                <span class="bg-warning border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="fa-solid fa-circle"></i></span>
                <span class="ml-2 font-medium chip" >{{data.kind | selectEnum: $_loanKind}} ORIGEN: <a class="text-decoration-underline" (click)="goLoan(data.referenceLoanId._id)">{{data.referenceLoanId.folio}}</a></span>
              </p-chip>
            </ng-container>
          </ng-container>
          <ng-template #deniedLoan>
            <p-chip styleClass="pl-0 pr-3 ml-2">
              <span class="bg-danger border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="fa-solid fa-circle"></i></span>
              <span class="ml-2 font-medium chip" >Rechazado</span>
            </p-chip>
          </ng-template>
        </ng-container>
      </h1>
    </div>
    <ng-container *ngIf="data">
      <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-user"></i>
            <span class="ml-2">Detalle del usuario</span>
          </ng-template>
          <app-worker-loan-detail [data]="data" [lastPosition]="lastPosition"></app-worker-loan-detail>
        </p-tabPanel>
        <ng-container *ngIf="data.status === 'PENDING_APPROVAL'; else elseApprovedLoan">
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PROCESSING_RECORD');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-regular fa-folder-open"></i>
                <span class="ml-2">Expediente</span>
              </ng-template>
              <ng-container *ngIf="data && data.pushing._id">
                <app-pushing-detail [id]="data.pushing._id"></app-pushing-detail>
              </ng-container>
            </p-tabPanel>
          </ng-container>
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PROCESSING_OFFER');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-solid fa-money-bill-trend-up"></i>
                <span class="ml-2">Ofertas</span>
              </ng-template>
              <app-loan-offers [data]="data" (updateData)="getData()"></app-loan-offers>
            </p-tabPanel>
          </ng-container>
          <!--<ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PROCESSING_DOCUMENTS');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-solid fa-folder-plus"></i>
                <span class="ml-2">Documentos</span>
              </ng-template>
              <app-loan-documents [data]="data"></app-loan-documents>
            </p-tabPanel>
          </ng-container>
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PROCESSING_VERIFY_IDENTITY');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-solid fa-fingerprint"></i>
                <span class="ml-2">Verificación de identidad</span>
              </ng-template>
              <app-loan-identity-verification [data]="data"></app-loan-identity-verification>
            </p-tabPanel>
          </ng-container>-->
          <!--<ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PROCESSING_SIGN_AGREEMENT');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-solid fa-pen-nib"></i>
                <span class="ml-2">Firma de contrato</span>
              </ng-template>
              <app-loan-sing-aggrement [data]="data"></app-loan-sing-aggrement>
            </p-tabPanel>
          </ng-container>-->
          <ng-container *ngIf="authService.userHasAuthorization('LOAN','UPDATE_STATUS');">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <i class="fa-solid fa-check-double"></i>
                <span class="ml-2">Aprobación</span>
              </ng-template>
              <app-loan-update-status [data]="data" [defaultBankTransaction]="defaultBankTransaction"></app-loan-update-status>
            </p-tabPanel>
          </ng-container>
          <!--<p-tabPanel>
            <ng-template pTemplate="header">
              <i class="pi pi-calendar"></i>
              <span class="ml-2">Historial</span>
            </ng-template>
            <app-loan-historial></app-loan-historial>
          </p-tabPanel>-->

        </ng-container>
        <ng-template #elseApprovedLoan>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="pi pi-calendar"></i>
              <span class="ml-2">Resumen</span>
            </ng-template>
            <app-loan-resume [data]="data" (onDataChange)="getData()"></app-loan-resume>
          </p-tabPanel>
        </ng-template>
      </p-tabView>
    </div>
    </ng-container>
  </div>
</p-card>
