<p-dialog [(visible)]="showModal"  [style]="{ width: '450px', height: '500px' }"  [modal]="true" [closable]="true" (onHide)="hideModalDialog()" header="Registrar una Dispersión" styleClass="p-fluid">
  <form [formGroup]="entity">
    <div class="field">
      <label for="reference">Tipo de Gasto*</label>
      <div class="field field-flex">
        <p-dropdown
          id="reference"
          [panelStyle]="{'width': '100%'}"
          scrollHeight="150px"
          [options]="outcomeSubType"
          placeholder="Seleccione el tipo de gasto"
          formControlName="subType"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="field">
      <label>Monto*</label>
      <p-inputNumber [min]="0" formControlName="amount" inputId="amount" placeholder="Monto" mode="currency" currency="MXN" locale="es-MX" [showClear]="true"></p-inputNumber>
    </div>
    <div class="field field-flex" *ngIf="entity.get('subType')?.value === 'SUPPLIER'">
      <label for="bank">Proveedor*</label>
      <div class="field field-flex">
        <p-dropdown
          id="bank"
          [panelStyle]="{'width': '100%'}"
          scrollHeight="150px"
          [options]="suppliers"
          optionLabel="label"
          placeholder="Seleccione un proveedor"
          formControlName="supplier">
        </p-dropdown>
      </div>
    </div>
    <div class="field">
      <label for="description">Descripción*</label>
      <textarea
        style="min-height: 50px;"
        pInputTextarea
        id="description"
        formControlName="description"
        rows="2"
        cols="30"
        placeholder="Descripción de la colocación..."
        required
        [autoResize]="true">
      </textarea>
    </div>
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideModalDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" [disabled]="!entity.valid" (click)="onSubmit()"></button>
  </form>
</p-dialog>
<p-toast></p-toast>
