<p-table
  #dtPlacements
  [scrollable]="true"
  scrollHeight="600px"
  [value]="placementData.transactions"
  [columns]="cols"
  dataKey="_id"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [loading]="loading"
  [paginator]="true"
  [tableStyle]="{'width': '100%'}"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} movimientos"
  [globalFilterFields]="['folio', 'description', 'subType', 'destinationType']">
  <ng-template pTemplate="caption">
    <div class="flex flex-row justify-content-between">
      <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      <div>
        <p-multiSelect [options]="allCols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas" (onChange)="onColumnToggle($event)"></p-multiSelect>
      </div>
      <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtPlacements.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem"></th>
      <th *ngFor="let col of cols" [style.min-width.px]="150">
        <div class="flex align-items-center" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-transaction let-expanded="expanded">
    <tr>
      <td *ngIf=" transaction.destinationType === 'COMPANY' ">
        <button type="button" pButton pRipple [pRowToggler]="transaction" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td *ngIf=" transaction.destinationType !== 'COMPANY' ">
      </td>
      <td *ngFor="let col of cols">
        <ng-container
          *ngIf="col.field !== 'applicationDate' &&
          col.field !== 'creationDate' &&
          col.field !== 'subType' &&
          col.field !== 'status' &&
          col.field !== 'amount' &&
          col.field !== 'destinationType'"
        >
          <span>{{ transaction[col.field] }}</span>
        </ng-container>
        <ng-container *ngIf="col.field === 'applicationDate'">
          {{ transaction.applicationDate | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-container *ngIf="col.field === 'creationDate'">
          {{ transaction.creationDate | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-container *ngIf="col.field === 'subType'">
          <p-tag [value]="getSubTypeEsp(transaction.subType)" [severity]="getSeverity(transaction.subType)"></p-tag>
        </ng-container>
        <ng-container *ngIf="col.field === 'status'">
          <i (click)="updateStatus(transaction)" class="pi" [ngClass]="{
          'text-green-500 pi-check-circle': transaction.status === 'APPLIED',
          'text-yellow-500 pi-exclamation-circle': transaction.status === 'PENDING',
          'text-red-500 pi-times-circle': transaction.status === 'CANCELED'
          }"></i>
        </ng-container >
        <ng-container *ngIf="col.field === 'amount'">
          {{ transaction.amount | currency }}
        </ng-container>
        <ng-container *ngIf="col.field === 'destinationType'">
          <p *ngIf="transaction.destinationType === 'WORKER' "><i class="pi pi-user"></i> TRABAJADOR</p>
          <p *ngIf="transaction.destinationType === 'COMPANY' "><i class="pi pi-building"></i> NOMINA</p>
          <p *ngIf=" transaction.destinationType !== 'COMPANY' && transaction.destinationType !== 'WORKER' ">-</p>
        </ng-container >
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-transaction>
    <tr>
      <td colspan="12">
        <div class="p-3">
          <p-table [value]="transaction.transactions" dataKey="folio">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="folio">Folio <p-sortIcon field="folio"></p-sortIcon></th>
                <th pSortableColumn="creditFolio">Folio del Credito <p-sortIcon field="creditFolio"></p-sortIcon></th>
                <th>Monto</th>
                <th>Folio de Seguimiento</th>
                <th>Status</th>
                <th>Fecha de aplicación <p-sortIcon field="applicationDate"></p-sortIcon></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaction>
              <tr>
                <td>{{ transaction.folio }}</td>
                <td>{{ transaction.creditFolio }}</td>
                <td>{{ transaction.amount | currency }}</td>
                <td>{{ transaction.trackingId}}</td>
                <td><i class="pi" [ngClass]="{
          'text-green-500 pi-check-circle': transaction.status === 'APPLIED',
          'text-yellow-500 pi-exclamation-circle': transaction.status === 'PENDING',
          'text-red-500 pi-times-circle': transaction.status === 'CANCELED'
          }"></i></td>
                <td>{{ transaction.applicationDate |  date: 'MM/dd/yyyy'}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">No se encontraron movimientos</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
