import { Table } from "primeng/table";
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AuthService } from "@data/services/modules/auth/auth.service";
import { DataTableService } from "@data/services/utils/data-table.service";
import {ConfirmationService, MenuItem, MessageService, SortEvent} from "primeng/api";
import {ITransaction} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";
import {ApiBalanceService} from "@modules/private/bank-accounts/data/services/api/api-balance.service";
import {
  getSubTypeEsp,
  TRANSACTION_STATUS_ESP
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";
import {DatesExportService} from "@data/services/utils/dates-export.service";


@Component({
  selector: 'app-income-table',
  templateUrl: './income-table.component.html',
  styleUrls: ['./income-table.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class IncomeTableComponent implements OnInit{
  protected readonly getSubTypeEsp = getSubTypeEsp;
  @Input() incomes: ITransaction[] = [];
  @Input() accountId!: string
  @Output() updateStatusEvent = new EventEmitter<any>()
  @ViewChild('dtIncomes') dtIncomes!: Table;

  cols: any[] = [
    { field: 'folio', header: 'Folio' },
    { field: 'amount', header: 'Cantidad'},
    { field: 'subType', header: 'Sub Tipo'},
    { field: 'status', header: 'Estatus'},
    { field: 'description', header: 'Descripción' },
    { field: 'originAccount', header: 'ID cuenta Origen' },
    { field: 'applicationDate', header: 'Fecha de Aplicación' },
    { field: 'creationDate', header: 'Fecha de Creacion' }
  ];

  allCols: any[] = [];

  colsPdf: any[] = [
    { dataKey: 'Folio', title: 'Folio' },
    { dataKey: 'Cantidad', title: 'Cantidad' },
    { dataKey: 'Sub Tipo', title: 'Sub Tipo' },
    { dataKey: 'Estatus', title: 'Estatus' },
    { dataKey: 'Descripción', title: 'Descripción' },
    { dataKey: 'ID cuenta Origen', title: 'ID Cuenta Origen' },
    { dataKey: 'Fecha de Aplicación', title: 'Fecha de Aplicación' },
    { dataKey: 'Fecha de Creacion', title: 'Fecha de Creación' }
  ];

  subTypeLabels: { [key: string]: string } = {
    'DEPOSITO_BANCARIO': 'BANK_DEPOSIT',
    'FONDEADORA': 'FUNDING_DEPOSIT',
    'PAGO DE LETRA': 'APPLICATION',
    'PAGO DE SERVICIO': 'SUPPLIER',
    'GASTOS GENERALES': 'GENERAL_EXPENSE',
    'CREDITO': 'LOAN_ALLOCATION',
  }

  loading: boolean = false;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;

  constructor(
    private apiBalanceService: ApiBalanceService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    public authService: AuthService,
    private dateExportService: DatesExportService
  ) {
    this.createMenu();
  }

  ngOnInit() {

    this.allCols = [
      { field: 'folio', header: 'Folio' },
      { field: 'amount', header: 'Cantidad'},
      { field: 'subType', header: 'Sub Tipo'},
      { field: 'status', header: 'Estatus'},
      { field: 'description', header: 'Descripción' },
      { field: 'originAccount', header: 'ID cuenta Origen' },
      { field: 'applicationDate', header: 'Fecha de Aplicación' },
      { field: 'creationDate', header: 'Fecha de Creacion' }

    ];
    this.cols = [...this.allCols];
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiBalanceService.getBalance('INCOME', true, {targetAccountId: this.accountId}).subscribe(r => {
      if (!r.error) {
        this.incomes = r.data.transactions
      }
      this.loading = false;
    });
  }

  getValue($event: Event) {
    return this.translate(($event.target as HTMLInputElement).value)
  }

  createMenu() {
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const data = this.dtIncomes.value.map((row: any) => {
                  return {
                    ...row,
                    creationDate: this.dateExportService.formatDate(row.creationDate),
                    applicationDate: this.dateExportService.formatDate(row.applicationDate),
                    subType: this.translate(row.subType),
                    status: TRANSACTION_STATUS_ESP[row.status as keyof typeof TRANSACTION_STATUS_ESP]
                  };
                });
                const originalValue = this.dtIncomes.value
                this.dtIncomes.value = data
                this.dtIncomes.exportCSV()
                this.dtIncomes.value = originalValue
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' })
              }
            },
            {
              label: 'Excel',
              command: () => {
                const accountData = this.dtIncomes.value
                const columns = this.dtIncomes.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(accountData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS_ESP]
                  }
                })
                this.dataTableService.exportExcel(formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })
              }
            },
            {
              label: 'PDF',
              command: () => {
                const accountData = this.dtIncomes.value
                const columns = this.dtIncomes.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(accountData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS_ESP]
                  }
                })
                this.dataTableService.exportPdf(this.colsPdf, formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtIncomes.clear();
          this.find();
        }
      },
    ];
  }

  getSeverity(status: string) {
    switch (status) {
      case 'BANK_DEPOSIT':
        return 'success';

      case 'FUNDING_DEPOSIT':
        return 'info';
      default:
        return ''
    }
  }

  updateStatus(income: any) {
    this.updateStatusEvent.emit(income)
  }

  customSort(event: SortEvent) {
    if (!event.data) {
      return;
    }
    event.data.sort((data1, data2) => {
      let value1 = this.translate(data1[event.field?event.field:'']);
      let value2 = this.translate(data2[event.field?event.field:'']);
      let result = value1.localeCompare(value2);
      return (event.order === 1) ? result : -result;
    });
  }

  translationMap: { [key: string]: string } = Object.assign(
    {},
    this.subTypeLabels,
    Object.fromEntries(Object.entries(this.subTypeLabels).map(([key, value]) => [value, key]))
  );

  translate(value: string): string {
    return this.translationMap[value.toUpperCase()] || value;
  }

}
