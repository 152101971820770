<p-card *ngIf="accountDetail">
  <div class="grid">
    <div class="col-12 lg:col-6">
      <h1 class="mb-1">Detalle de la Cuenta Bancaria</h1>
    </div>
    <ng-container *ngIf="accountDetail">
      <div class="col-12 lg:col-6 text-right">
        <p-splitButton label="Acciones de usuario" [menuStyle]="{width: '100%'}"  [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="accountDetail">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-user"></i>
            <span class="ml-2">Detalle</span>
          </ng-template>
          <app-account-detail [accountDetail]="accountDetail" [incomeData]="totalIncomes" [outcomeData]="totalOutcomes"></app-account-detail>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="pi pi-calendar"></i>
            <span class="ml-2">Balance</span>
          </ng-template>
          <ng-template pTemplate="content">
            <app-balance-detail [accountDetail]="accountDetail" [incomeData]="incomeData" [outcomeData]="outcomeData" [placementData]="placementData" ></app-balance-detail>
            <div class="col-12">
              <div class="main-title">LISTADO DE MOVIMIENTOS</div>
            </div>
            <div class="col-12">
              <p-tabView styleClass="tabview-custom">
                <p-tabPanel>
                  <ng-template pTemplate="header">
                    <i class="fa-solid fa-user"></i>
                    <span class="ml-2">Tabla de Ingresos</span>
                  </ng-template>
                  <app-income-table [incomes]="incomeTableData" [accountId]="accountId" (updateStatusEvent)="updateStatus($event)"></app-income-table>
                </p-tabPanel>
                <p-tabPanel>
                  <ng-template pTemplate="header">
                    <i class="pi pi-calendar"></i>
                    <span class="ml-2">Tabla de Egresos</span>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <app-expense-table [outcomes]="outcomeData.transactions" [accountId]="accountId"></app-expense-table>
                  </ng-template>
                </p-tabPanel>
                <p-tabPanel>
                  <ng-template pTemplate="header">
                    <i class="pi pi-calendar"></i>
                    <span class="ml-2">Tabla de Colocaciones (aplicaciones y creditos)</span>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <app-placement-table [placementData]="placementData" [accountId]="accountId" (updateStatusEvent)="updateStatus($event)"></app-placement-table>
                  </ng-template>
                </p-tabPanel>
              </p-tabView>
            </div>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </ng-container>

</p-card>
<app-placement-modal [showModal]="showCreatePlacement" [bankAccountData]="accountDetail" [entity]="entity" (hideModalDialogEvent)="hideModalDialog($event)" (onSubmitEvent)="onSubmit()"></app-placement-modal>
<app-outcome-modal [showModal]="showOutcomeModal" [bankAccountData]="accountDetail" (hideModalDialogEvent)="hideModalDialog($event)" (onSubmitEvent)="onSubmit()"></app-outcome-modal>
<app-complete-modal [showModal]="showCompleteModal" [bankAccountData]="accountDetail" [transactionData]="transactionData" (hideModalDialogEvent)="hideModalDialog($event)" (completeTransactionEvent)="onSubmit()"></app-complete-modal>
<p-dialog [(visible)]="showMassiveColocation" [style]="{ width: '650px' }" [header]="'Registrar Colocación Masiva'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-massive-colocation [bankAccountData]="accountDetail" (onSubmitEvent)="onSubmit()"></app-massive-colocation>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<app-loader *ngIf="loading"></app-loader>





