import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiCompanyService} from "@modules/private/company/data/services/api/api-company.service";
import {ApiSupplyService} from "@modules/private/supply/data/services/api/api-supply.service";
import {Subject, takeUntil} from "rxjs";
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-outcome-modal',
  templateUrl: './outcome-modal.component.html',
  styleUrls: ['./outcome-modal.component.scss']
})
export class OutcomeModalComponent implements OnInit{
  entity!: FormGroup;
  @Input() bankAccountData: any;
  @Input() showModal= false
  @Output() hideModalDialogEvent = new EventEmitter<any>()
  @Output() onSubmitEvent = new EventEmitter<any>()
  suppliers: { id:string; label: string; value: string; }[] = [];
  outcomeSubType = [
    { label: 'Gasto General', value: 'GENERAL_EXPENSE' },
    { label: 'Proveedor', value: 'SUPPLIER' },
    { label: 'SoD', value: 'SOD' }
  ];
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private apiCompanyService: ApiCompanyService,
    private apiSupplyService: ApiSupplyService,
    private apiTransactionService: ApiTransactionService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.apiCompanyService.getAll().pipe(takeUntil(this.destroy$)).subscribe(r => {
      r.data.forEach(data => {
        this.suppliers.push({ id:data._id, label: data.name, value: data.name });
      });
    });
    this.apiSupplyService.getAll().pipe(takeUntil(this.destroy$)).subscribe(r => {
      r.data.forEach( data => {
        this.suppliers.push({ id:data._id, label: data.name, value: data.name })
      })
    })
  }

  initializeForm() {
    this.entity = this.fb.group({
      originAccount: [this.bankAccountData._id],
      targetAccount: [''],
      amount: ['', Validators.required],
      type: ['OUTCOME'],
      supplier:[''],
      subType: [''],
      description: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  hideModalDialog() {
    this.hideModalDialogEvent.emit('OUTCOME')
  }

  onSubmit() {
    const dataToSave= {
      originAccount: this.bankAccountData._id,
      targetAccount: this.entity.value.subType === "SUPPLIER" ? this.entity.value.supplier.id : '',
      type: this.entity.value.type,
      subType: this.entity.value.subType,
      amount: this.entity.value.amount,
      description: this.entity.value.description
    }
    this.apiTransactionService.addIncomeOutcome(dataToSave).subscribe( r => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Transacción exitosa', detail: r.error ? r.msg: r.msg, life: 3000 })
      if (!r.error ) {
        this.hideModalDialog()
      }
    })
    this.onSubmitEvent.emit();
  }
}
