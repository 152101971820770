<ng-container *ngIf="validations">
  <div class="grid">
    <div class="col-12">
      <p-table [value]="rules" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>Propiedad</th>
            <th>Estatus</th>
            <th>Valor de la regla</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rules>
          <tr>
            <td>Meses de antigüedad:</td>
            <td><p-chip styleClass="{{validations.antiquityMonths >= 12 ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.antiquityMonths >= 12 ? 'Válido' : 'Inválido'}}" icon="{{validations.antiquityMonths >= 12  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Minimo 12 meses</td>
          </tr>
          <tr>
            <td>Dirección de INE y comprobante de domicilio:</td>
            <td><p-chip styleClass="{{validations.ineAddressAndProofOfAddressAreSame ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.ineAddressAndProofOfAddressAreSame ? 'Válido' : 'Inválido'}}" icon="{{validations.ineAddressAndProofOfAddressAreSame  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Deben ser iguales</td>
          </tr>
          <tr>
            <td>Comprobante de domicilio:</td>
            <td><p-chip styleClass="{{validations.proofOfAddressIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.proofOfAddressIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.proofOfAddressIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Máximo 3 meses de antigüedad</td>
          </tr>
          <tr>
            <td>INE:</td>
            <td><p-chip styleClass="{{validations.ineIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.ineIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.ineIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Debe ser vigente</td>
          </tr>
          <tr>
            <td>Comprobantes de nómina:</td>
            <td><p-chip styleClass="{{!validations.bothProofOfPayrollAreSame ? 'chip-valid' : 'chip-invalid'}}" label="{{!validations.bothProofOfPayrollAreSame ? 'Válido' : 'Inválido'}}" icon="{{!validations.bothProofOfPayrollAreSame  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Deben ser diferentes</td>
          </tr>
          <tr>
            <td>Balance de cuenta:</td>
            <td><p-chip styleClass="{{validations.balanceAccountIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.balanceAccountIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.balanceAccountIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Máximo 3 meses de antigüedad</td>
          </tr>
          <tr>
            <td>Penúltimo comprobante de nómina:</td>
            <td><p-chip styleClass="{{validations.penultimateProofOfPayrollDays <= 60 ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.penultimateProofOfPayrollDays <= 60 ? 'Válido' : 'Inválido'}}" icon="{{validations.penultimateProofOfPayrollDays <= 60  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Máximo 3 meses de antigüedad</td>
          </tr>
          <tr>
            <td>Último comprobante de nómina:</td>
            <td><p-chip styleClass="{{validations.lastProofOfPayrollDays <= 60 ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.lastProofOfPayrollDays <= 60 ? 'Válido' : 'Inválido'}}" icon="{{validations.lastProofOfPayrollDays <= 60  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></td>
            <td>Máximo 3 meses de antigüedad</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!--<div class="col-12">
      <h4>Meses de antigüedad:</h4>
      <small>{{validations.antiquityMonths}} Meses</small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">Dirección de INE y comprobante de domicilio son iguales:</h4>
      <small><p-chip styleClass="{{validations.ineAddressAndProofOfAddressAreSame ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.ineAddressAndProofOfAddressAreSame ? 'Válido' : 'Inválido'}}" icon="{{validations.ineAddressAndProofOfAddressAreSame  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">El comprobante de domicilio es válido:</h4>
      <small><p-chip styleClass="{{validations.proofOfAddressIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.proofOfAddressIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.proofOfAddressIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">La INE es válida:</h4>
      <small><p-chip styleClass="{{validations.ineIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.ineIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.ineIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">Los comprobantes de nómina son distintos:</h4>
      <small><p-chip styleClass="{{!validations.bothProofOfPayrollAreSame ? 'chip-valid' : 'chip-invalid'}}" label="{{!validations.bothProofOfPayrollAreSame ? 'Válido' : 'Inválido'}}" icon="{{!validations.bothProofOfPayrollAreSame  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">El balance de cuenta es válido:</h4>
      <small><p-chip styleClass="{{validations.balanceAccountIsValid ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.balanceAccountIsValid ? 'Válido' : 'Inválido'}}" icon="{{validations.balanceAccountIsValid  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">El penultimo comprobante de nómina es valido:</h4>
      <small><p-chip styleClass="{{validations.penultimateProofOfAddressIsCurrent ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.penultimateProofOfAddressIsCurrent ? 'Válido' : 'Inválido'}}" icon="{{validations.penultimateProofOfAddressIsCurrent  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>
    <div class="col-12">
      <h4 class="mb-2">El último comprobante de nómina es valido:</h4>
      <small><p-chip styleClass="{{validations.lastProofOfAddressIsCurrent ? 'chip-valid' : 'chip-invalid'}}" label="{{validations.lastProofOfAddressIsCurrent ? 'Válido' : 'Inválido'}}" icon="{{validations.lastProofOfAddressIsCurrent  ? 'fa-solid fa-check': 'fa-solid fa-times'}}"/></small>
    </div>-->
  </div>
</ng-container>
