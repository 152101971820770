<p-dialog [(visible)]="displayModal" [style]="{ width: '450px', height: '400px'}" [modal]="true" [closable]="true" header="Incrementar Saldo" styleClass="p-fluid" (onHide)="resetForm()">
  <form [formGroup]="disperseForm">
    <div class="p-field">
      <label>Indica la línea de fondeo de donde se tomará el dinero</label>
      <p-dropdown
        id="selectedBalance"
        [options]="financialDetail.fundingBalances"
        optionLabel="balanceName"
        formControlName="balanceSelected"
        scrollHeight="150px"
        placeholder="Seleccione un balance"
        (onChange)="onBalanceChange($event)"></p-dropdown>
    </div>
    <div class="p-field pt-3">
      <label for="amount">Monto</label>
      <p-inputNumber
        id="amount"
        [showButtons]="true"
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        [step]="1"
        decrementButtonClass="p-button-danger"
        incrementButtonClass="p-button-success"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        mode="currency"
        currency="MEX"
        [min]="minBalanceAmount"
        formControlName="balance">
      </p-inputNumber>
    </div>
  </form>
  <p-footer>
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideModalDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="save()" [disabled]="!disperseForm.valid"></button>
  </p-footer>
</p-dialog>
