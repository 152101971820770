import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Subject, takeUntil} from "rxjs";
import {TYPE_PERIOD_ENUM} from "@modules/private/company/data/enums/typePeriods.enum";
import {ApiPayrollService} from "@modules/private/payroll/data/services/api/api-payroll.service";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service";
import {MessageService} from "primeng/api";
import {formatDate} from "@angular/common";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-placement-modal',
  templateUrl: './placement-modal.component.html',
  styleUrls: ['./placement-modal.component.scss']
})
export class PlacementModalComponent implements OnInit {
  @Input() entity!: FormGroup;
  @Input() bankAccountData: any;
  @Input() showModal= false
  @Output() hideModalDialogEvent = new EventEmitter<any>()
  @Output() onSubmitEvent = new EventEmitter<any>()
  payrolls!: IPayroll[]
  references = [
    { label: 'Compañia', value: 'COMPANY' },
    { label: 'Empleado', value: 'WORKER' }
  ];

  constructor(
    private fb: FormBuilder,
    private apiPayrollService: ApiPayrollService,
    private apiTransactionService: ApiTransactionService,
    private messageService: MessageService,
    private loaderService: LoaderService,
  ) { }
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.initializeForm();

    this.apiPayrollService.getAll().pipe(takeUntil(this.destroy$)).subscribe(r => {
      if(!r.error) {
        this.payrolls = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          r.periodType = TYPE_PERIOD_ENUM[`${r.periodType}` as keyof typeof TYPE_PERIOD_ENUM]
          return r
        })
      }
    })
  }

  initializeForm() {
    this.entity = this.fb.group({
      originAccount: [''],
      subType: ['APPLICATION', Validators.required],
      destinationType: [''],
      amount: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(3)]],
      folio: [''],
      applicationDate: [''],
      trackingId: ['', [Validators.minLength(5)]],
      clabe: [''],
      company: [''],
    });
  }

  hideModalDialog() {
    this.hideModalDialogEvent.emit('PLACEMENT')
  }

  onSubmit() {
    this.loaderService.showSpinner()
    const formData = this.entity.getRawValue();
    const incomeData = {
      originAccount: this.bankAccountData._id,
      targetAccount:formData.destinationType === 'COMPANY' ? formData.company._id : '',
      amount:formData.amount,
      type:"INCOME",
      subType:formData.subType,
      description:formData.description,
      applicationDate:formData.applicationDate ? formatDate(formData.applicationDate, 'yyyy-MM-dd', 'en-US') : '',
      destinationType: formData.destinationType,
      creditFolio:formData.destinationType === 'WORKER'? formData.folio : '',
      trackingId:formData.destinationType === 'WORKER'? formData.trackingId : '',
      clabe: formData.destinationType === 'WORKER'? this.bankAccountData.clabe : ''
    }
    this.apiTransactionService.addIncomeOutcome(incomeData).subscribe( r => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Transacción exitosa', detail: r.error ? r.msg: r.msg, life: 5000 })
      if (!r.error ) {
        this.hideModalDialog()
        setTimeout(() => {
        }, 1000);
      }
      this.loaderService.hideSpinner()
      this.onSubmitEvent.emit()
    })
  }
}
