import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  IApiAccountList,
  IAccount
} from '@modules/private/bank-accounts/data/interfaces/iapi-account.interface';
import { ApiClass } from '@data/class/api-class';
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiBankAccountService extends ApiClass {

  constructor() {
    super();
    this.path = 'bankAccount';
  }

  getAll(): Observable<IApiAccountList> {
    return this.getCollections().pipe(map((r) => r as IApiAccountList));
  }

  getById(id: string): Observable<{ error: boolean; msg: string; data: IAccount }> {
    return this.getCollection(id).pipe(map((r) => r as { error: boolean; msg: string; data: IAccount }));
  }

  createAccount(data: IAccount): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as any));
  }

  updateAccount(data: IAccount): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as any));
  }

  getDefaultTransactionBankAccount(): Observable<{ error: boolean; msg: string; data: IAccount }> {
    return this.getCollection('', environment.apiUrl,this.path+'/transaction').pipe(map((r) => r as { error: boolean; msg: string; data: IAccount }))
  }

}
