import {Component, Input, OnInit} from '@angular/core';
import { IAccount } from "@modules/private/bank-accounts/data/interfaces/iapi-account.interface";
import {ITransactionList} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent {
  @Input() accountDetail!: IAccount;
  @Input() incomeData!: ITransactionList
  @Input() outcomeData!: ITransactionList

  constructor(
  ) {}

}


