import {Component, Input, OnInit} from '@angular/core';
import {IAccount} from "@modules/private/workers/data/interfaces/iaccount.interface";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ApiAccountService} from "@modules/private/workers/data/services/api/api-account.service";
import {IAddress} from "@modules/private/workers/data/interfaces/iaddress.interface";
import {ApiAddressService} from "@modules/private/workers/data/services/api/api-address.service";

@Component({
  selector: 'app-worker-address',
  templateUrl: './worker-address.component.html',
  styleUrls: ['./worker-address.component.scss']
})
export class WorkerAddressComponent implements OnInit {
  address: IAddress[] = [];
  @Input() data!: {worker: IWorker, position: IPosition, payroll: IPayroll}
  constructor(
    private apiAddressService: ApiAddressService
  ) {
  }

  ngOnInit() {
    this.getAddress()
  }

  getAddress() {
    this.apiAddressService.getById(this.data.worker._id).subscribe((r) => {
      console.log(r)
      this.address = r.data
    })
  }
}
