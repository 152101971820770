import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IExpressLeague} from "@modules/private/workers/data/interfaces/iexpressLeague.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiExpressLeagueService extends ApiClass {
  constructor() {
    super();
    this.path = 'expressLeague'
  }

  create(data: any) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: {expressLeague: IExpressLeague, token: string}}))
  }
}
