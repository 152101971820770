import {Component, Input, OnInit} from '@angular/core';
import {ApiAccountService} from "@modules/private/workers/data/services/api/api-account.service";
import {IAccount} from "@modules/private/workers/data/interfaces/iaccount.interface";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";

@Component({
  selector: 'app-worker-bank-accounts',
  templateUrl: './worker-bank-accounts.component.html',
  styleUrls: ['./worker-bank-accounts.component.scss']
})
export class WorkerBankAccountsComponent implements OnInit {
  accounts: IAccount[] = [];
  @Input() data!: {worker: IWorker, position: IPosition, payroll: IPayroll}
  constructor(
    private apiAccountService: ApiAccountService
  ) {
  }

  ngOnInit() {
    this.getAccounts()
  }

  getAccount(account: string) {
    return `${account.slice(0, 4)} ${account.slice(4, 8)} ${account.slice(8, 12)} ${account.slice(12, 16)} ${account.slice(16)}`;

  }

  getAccounts() {
    this.apiAccountService.getById(this.data.worker._id).subscribe((r) => {
      console.log(r)
      this.accounts = r.data
    })
  }
}
