<p-card>
  <div class="row">
    <div class="col-12 mb-5 mb-3">
      <h1 class="mb-3">Fondeadoras</h1>
      <p class="text-color-secondary">
        Las Fondeadoras son entidades que proporcionan recursos financieros para respaldar las operaciones de la empresa. Estas entidades facilitan la disponibilidad de capital que puede ser utilizado para la concesión de préstamos o para otros fines relacionados con la gestión de fondos.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtFinancials
    [scrollable]="true"
    scrollHeight="600px"
    [value]="financials"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} fondeadoras"
    [globalFilterFields]="['name', 'agreementDate', 'businessName', 'address']">
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="allCols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas" (onChange)="onColumnToggle($event)"></p-multiSelect>
        </div>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtFinancials.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [style.min-width.px]="150">
          <div *ngIf="col.field !== 'agreementDate'" class="flex align-items-center" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-columnFilter [type]="col.type || 'text'" [field]="col.field" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </div>
          <div *ngIf="col.field === 'agreementDate'" class="flex align-items-center" pSortableColumn="agreementDate">
            Fecha de Acuerdo
            <p-columnFilter type="date" field="agreementDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="agreementDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('FINANCIAL', 'UPDATE')">
          <th style="min-width:10rem">
            Acciones
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-financial>
      <tr>
        <td *ngFor="let col of cols">
          <ng-container *ngIf="col.field !== 'agreementDate'">
            <a class="link-subsection" *ngIf="col.field === 'name'" [routerLink]="['/financial', financial._id]">{{ financial[col.field] }}</a>
            <span *ngIf="col.field !== 'name'">{{ financial[col.field] }}</span>
          </ng-container>
          <ng-container *ngIf="col.field === 'agreementDate'">
            {{ financial.agreementDate | date: 'dd/MM/yyyy' }}
          </ng-container>
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('FINANCIAL', 'UPDATE')">
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', financial)" class="p-button-rounded p-button-text"></button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No se encontraron pagadoras</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE FINANCIAL---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nuevo Fondeador' : 'Actualizar Fondeador'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-financial [entity]="entity" [submitted]="submitted" [type]="formAction"></app-create-financial>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
