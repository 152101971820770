import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IFinancial } from '@modules/private/financial/data/interfaces/iapi-financial.interface';
import {ApiFinancialService} from "@modules/private/financial/data/services/api/api-financial.service";

@Component({
  selector: 'app-financial-detail',
  templateUrl: './financial-detail.component.html',
  styleUrls: ['./financial-detail.component.scss']
})

export class FinancialDetailComponent implements OnInit {
  financialId!: string;
  financialDetail!: IFinancial;

  constructor(
    private route: ActivatedRoute,
    private apiFinancialService: ApiFinancialService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.financialId = params['id'];
      this.getFinancialDetail(this.financialId);
    });
  }

  getFinancialDetail(id: string) {
    this.apiFinancialService.getById(id).subscribe(response => {
      if (!response.error) {
        this.financialDetail = response.data;
      }
    });
  }
}
