import {Component, Input, OnInit} from '@angular/core';
import {IAILoanAnalysis, IPushingFlowDetail} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {AiAzureService} from "@modules/private/pushing/data/services/ai-azure.service";

@Component({
  selector: 'app-pushing-detail-ai-analyse',
  templateUrl: './pushing-detail-ai-analyse.component.html',
  styleUrls: ['./pushing-detail-ai-analyse.component.scss']
})
export class PushingDetailAiAnalyseComponent implements OnInit {
  @Input() data!: IPushingFlowDetail
  rules = [1]
  validations!: IAILoanAnalysis
  constructor(
    public aiAzure: AiAzureService
  ) {
  }

  ngOnInit() {
    this.getData()
  }

  getData () {
    this.aiAzure.getById(this.data.pushing._id).subscribe(r => {
      console.log(r)
      this.validations = r.data
    })
  }
}
