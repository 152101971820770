<div class="container">
  <div class="grid">
    <div class="col-12">
      <small>Listado de todas las cuentas banacarias del usuario</small>
    </div>
    <ng-container *ngFor="let account of accounts">
      <div class="col-12 md:col-6 xl:col-3">
        <div class="bank-account">
          <i class="fa-solid fa-pen-to-square bank-edit"></i>
          <i class="fa-solid fa-trash bank-delete"></i>
          <p class="mb-2">{{account.bank ? account.bank : "BANCO NO DEFINIDO"}}</p>
          <h3>{{getAccount(account.number)}}</h3>
          <i class="fa-solid fa-copy bank-copy"></i>
        </div>
      </div>
    </ng-container>
  </div>
</div>
