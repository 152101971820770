
<p-panel [toggleable]="true" [collapsed]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <span class="font-bold flex align-items-center">
          Configuración del crédito
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-wrap align-items-center justify-content-end gap-3">
      <small class="p-text-secondary text-right">
          Updated 2 hours ago
      </small>
    </div>
  </ng-template>
  <ng-template pTemplate="icons">
    <button class="p-panel-header-icon p-link mr-2" (click)="menu.toggle($event)">
      <span class="pi pi-cog"></span>
    </button>
    <p-menu #menu [model]="items" [popup]="true" />
  </ng-template>
  <div class="grid">
    <div class="col-12 flex align-items-center justify-content-end">
      <h4 class="mr-2">Colapsar todo </h4>
      <p-inputSwitch [(ngModel)]="collapsedAll" />
    </div>
  </div>
  <p-panel [collapsed]="collapsedAll" header="Calculadora" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-12">
        <form [formGroup]="entity" (change)="updateItems()">
          <div class="grid">
            <div class="col-12">
              <div class="main-title">
                Configuración del crédito
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field field-flex">
                <label for="amount">Monto del crédito:</label>
                <p-inputNumber formControlName="amount" inputId="amount" mode="currency" currency="MXN" locale="es-MX" (onKeyDown)="updateItems()"></p-inputNumber>
                <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
                  <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El monto del crédito es obligatorio.</small>
                  <small class="p-error" *ngIf="entity.get('amount')?.errors?.['min']">El valor minimo es {{entity.get('amount')?.errors?.['min']['min']}}.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field field-flex">
                <label for="installmentCount">Número de letras:</label>
                <p-inputNumber formControlName="installmentCount" inputId="installmentCount" (onKeyDown)="updateItems()"></p-inputNumber>
                <ng-container *ngIf="entity.get('installmentCount')?.invalid && (entity.get('installmentCount')?.dirty || entity.get('installmentCount')?.touched)">
                  <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['required']">El número de letras es obligatorio.</small>
                  <small class="p-error" *ngIf="entity.get('installmentCount')?.errors?.['min']">El valor minimo de letras es {{entity.get('installmentCount')?.errors?.['min']['min']}}.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field field-flex">
                <label for="rate">Tasa de intéres:</label>
                <input type="number" pInputText min="0" step="0.01" id="rate" formControlName="rate" placeholder="0.00" (onKeyDown)="updateItems()" required />
                <ng-container *ngIf="entity.get('rate')?.invalid && (entity.get('rate')?.dirty || entity.get('rate')?.touched)">
                  <small class="p-error" *ngIf="entity.get('rate')?.errors?.['required']">La tasa de intéres es obligatoria.</small>
                  <small class="p-error" *ngIf="entity.get('rate')?.errors?.['min']">El valor minimo es {{entity.get('rate')?.errors?.['min']['min']}}.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field field-flex">
                <label for="creationFee">Comisión por apertura:</label>
                <p-inputNumber formControlName="creationFee" inputId="creationFee" mode="currency" currency="MXN" locale="es-MX" (onKeyDown)="updateItems()"></p-inputNumber>
                <ng-container *ngIf="entity.get('creationFee')?.invalid && (entity.get('creationFee')?.dirty || entity.get('creationFee')?.touched)">
                  <small class="p-error" *ngIf="entity.get('creationFee')?.errors?.['required']">La comisión por apertura es obligatoria.</small>
                  <small class="p-error" *ngIf="entity.get('creationFee')?.errors?.['min']">El valor minimo es {{entity.get('rate')?.errors?.['min']['min']}}.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field field-flex">
                <label for="accountNumber">Cuenta CLABE:</label>
                <input type="text" pInputText id="accountNumber" formControlName="accountNumber" placeholder="424242424242424242" (onKeyDown)="updateItems()" required />
                <ng-container *ngIf="entity.get('accountNumber')?.invalid && (entity.get('accountNumber')?.dirty || entity.get('accountNumber')?.touched)">
                  <small class="p-error" *ngIf="entity.get('accountNumber')?.errors?.['required']">El campo es obligatorio.</small>
                  <small class="p-error" *ngIf="entity.get('accountNumber')?.errors?.['pattern']">La cuenta clabe debe tener 18 digitos.</small>
                </ng-container>
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
              <p class="mr-2 mb-2">¿Cuenta con asistencia IKE? </p>
              <p-inputSwitch formControlName="hasIkeAttendance"/>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
              <p class="mr-2 mb-2">Usar configuraciones por defecto </p>
              <p-inputSwitch formControlName="defaultProperties" (onChange)="setDefaultProperties()"/>
            </div>
            <div class="col-12">
              <div class="main-title">
                Accesorios Obligatorios
              </div>
            </div>
            <div class="col-12">
              <div class="grid">
                <ng-container *ngFor="let accessory of requiredAccessories">
                  <div class="col-12 md:col-6 lg:col-4">
                    <div class="accessory">
                      <h4>{{accessory.name}}</h4>
                      <ng-container *ngIf="accessory.isFinanced; else elsePrices;">
                        <small class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.weekly | currency) : (accessory.price.weekly + '% sobre el monto solicitado')}} / semanal</strong></small><br>
                        <small class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.biweekly | currency) : (accessory.price.biweekly + '% sobre el monto solicitado')}} / catorcenal</strong></small><br>
                        <small class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.semimonthly | currency) : (accessory.price.semimonthly + '% sobre el monto solicitado')}} / quincenal</strong></small><br>
                        <small class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.monthly | currency) : (accessory.price.monthly + '% sobre el monto solicitado')}} / mensual</strong></small><br>
                      </ng-container>
                      <ng-template #elsePrices>
                        <small class="accessory-property">Costo: <strong>{{accessory.calculation === 'FIXED' ? (accessory.price.creation | currency) : (accessory.price.creation + '% sobre el monto solicitado')}} </strong></small><br>
                      </ng-template>
                      <small class="accessory-property">Términos y condiciones: <strong>{{accessory.termsAndCondition === '' ? '---':accessory.termsAndCondition}}</strong></small><br>
                      <small class="accessory-property-description">{{accessory.description}}</small><br>
                      <p-tag [value]="accessory.isFinanced ? 'Financiado': 'No financiado'" [severity]="accessory.isFinanced ? 'danger':'success'"></p-tag>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-12">
              <div class="main-title">
                Accesorios opcionales
              </div>
            </div>
            <div class="col-12">
              <div class="field field-flex">
                <label for="optionalAccessories">Accesorios opcionales:</label>
                <p-multiSelect [options]="accessories" [group]="true" formControlName="optionalAccessories" placeholder="Selecciona los accesorios" scrollHeight="200px" display="chip">
                  <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                      <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + group.value" style="width: 20px" />
                      <span>{{ group.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Tabla de amortización (previsualización)" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-12">
        <p-table [value]="installmentsCalculated" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th>Número de letra</th>
              <th>Monto de capital</th>
              <th>Monto del intéres</th>
              <th>Monto de accesorios</th>
              <th>Monto de intéres de accesorios</th>
              <th>Monto total</th>
              <th>Fecha de exigibilidad</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-installment>
            <tr>
              <td>{{ +installment.index + 1}}</td>
              <td>{{ installment.principalAmount | currency }}</td>
              <td>{{ installment.revenueAmount | currency }}</td>
              <td>{{ installment.accessoryPrincipalAmount | currency }}</td>
              <td>{{ installment.accessoryRevenueAmount | currency }}</td>
              <td>{{ (installment.revenueAmount + installment.principalAmount + installment.accessoryPrincipalAmount + installment.accessoryRevenueAmount) | currency }}</td>
              <td>{{ transformDate(installment.dueDate) | date: 'dd-MM-YYYY' }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Perfil del cliente" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">Periodo de pago:</h4>
        <small>{{data.loanCalculated.paymentPeriod}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tipo de ingreso:</h4>
        <small>{{data.loanCalculated.typeIngress}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tipo de comprobante:</h4>
        <small>{{data.loanCalculated.typeReceipt}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Fecha de ingreso:</h4>
        <small>{{data.loanCalculated.ingressDate | date: 'dd-MM-YYYY'}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Posición:</h4>
        <small>{{data.loanCalculated.position}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Prestamos finiquitados:</h4>
        <small>{{data.loanCalculated.loansPaid}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Prestamos activos:</h4>
        <small>{{data.loanCalculated.activeLoans ? 'SI':'NO'}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Antigüedad:</h4>
        <small>{{data.loanCalculated.antiquityInMonths}} EN MESES | {{data.loanCalculated.antiquityInYears}} EN AÑOS</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Ingreso promedio mensual:</h4>
        <small>{{data.loanCalculated.averageIngressByMonth | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Descuento promedio mensual:</h4>
        <small>{{data.loanCalculated.averageDiscountsByMonth | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tipo de calculo:</h4>
        <small>{{data.loanCalculated.typeCalculation}}</small>
      </div>
      <div class="col-12">
        <p-divider></p-divider>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Perfil del prestamo" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">Monto maximo del prestamo:</h4>
        <small>{{data.loanCalculated.profileLoan.maxAmount | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Descuento maximo mensual:</h4>
        <small>{{data.loanCalculated.profileLoan.maxDiscountByMonth | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Descuento maximo del periodo:</h4>
        <small>{{data.loanCalculated.profileLoan.maxDiscountByPeriod | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Plazo máximo del prestamo:</h4>
        <small>{{data.loanCalculated.profileLoan.maxTermOfLoan}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes anual:</h4>
        <small>{{data.loanCalculated.profileLoan.yearInterestRate | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes anual con iva:</h4>
        <small>{{data.loanCalculated.profileLoan.yearInterestRatePlusIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes mensual:</h4>
        <small>{{data.loanCalculated.profileLoan.monthInterestRate | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes mensual con iva:</h4>
        <small>{{data.loanCalculated.profileLoan.monthInterestRatePlusIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tipo de prestamo sugerido:</h4>
        <small>{{data.loanCalculated.typeProduct}}</small>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Información del prestamo" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">Monto del prestamo:</h4>
        <small>{{data.loanCalculated.loanInfo.amount | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tiene asistencia IKE:</h4>
        <small>{{data.loanCalculated.loanInfo.hasIkeAssistance ? 'SI': 'NO'}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Costo asistencia IKE:</h4>
        <small>{{data.loanCalculated.loanInfo.ikeAssistanceAmount | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Plazo del prestamo:</h4>
        <small>{{data.loanCalculated.loanInfo.installmentCount}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Comision por apertura:</h4>
        <small>{{data.loanCalculated.loanInfo.creationFee | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Saldo pendiente:</h4>
        <small>{{data.loanCalculated.loanInfo.pendingBalance | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Monto a transferir:</h4>
        <small>{{data.loanCalculated.loanInfo.amountToDisperse | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Cuota de administración total:</h4>
        <small>{{data.loanCalculated.loanInfo.administrationFee | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Interes total:</h4>
        <small>{{data.loanCalculated.loanInfo.totalInterest | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Monto de la letra:</h4>
        <small>{{data.loanCalculated.loanInfo.installmentAmount | currency}}</small>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Configuración plataforma PULPI" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">Monto solicitado:</h4>
        <small>{{data.loanCalculated.loanInfo.amount | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">No de letras:</h4>
        <small>{{data.loanCalculated.loanInfo.installmentCount}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes anual:</h4>
        <small>{{data.loanCalculated.loanInfo.yearRealRateWithIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Comision por apertura:</h4>
        <small>{{data.loanCalculated.loanInfo.creationFee | currency}}</small>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Configuración caratula" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">CAT:</h4>
        <small>{{data.loanCalculated.loanInfo.CAT | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes anual sin iva:</h4>
        <small>{{data.loanCalculated.loanInfo.yearInterestRateWithoutIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa de interes mensual sin iva:</h4>
        <small>{{data.loanCalculated.loanInfo.monthInterestRateWithoutIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Comision por apertura con iva:</h4>
        <small>{{data.loanCalculated.loanInfo.creationFeeWithIva | currency}}</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Monto a transferir:</h4>
        <small>{{data.loanCalculated.loanInfo.amountToDisperse | currency}}</small>
      </div>
    </div>
  </p-panel>
  <p-panel [collapsed]="collapsedAll" header="Datos adicionales" [toggleable]="true" [styleClass]="'inside-header'">
    <div class="grid">
      <div class="col-3">
        <h4 class="mb-2">Tasa real del periodo con iva:</h4>
        <small>{{data.loanCalculated.loanInfo.interestRateOfPeriodWithIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa real del periodo sin iva:</h4>
        <small>{{data.loanCalculated.loanInfo.interestRateOfPeriodWithoutIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa real anual con iva:</h4>
        <small>{{data.loanCalculated.loanInfo.yearRealRateWithIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">Tasa real anual sin iva:</h4>
        <small>{{data.loanCalculated.loanInfo.yearRealRateWithoutIva | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">TIR del periodo:</h4>
        <small>{{data.loanCalculated.loanInfo.periodTIR | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">TIR anual:</h4>
        <small>{{data.loanCalculated.loanInfo.yearTIR | number:'1.2-2'}}%</small>
      </div>
      <div class="col-3">
        <h4 class="mb-2">CAT:</h4>
        <small>{{data.loanCalculated.loanInfo.CAT | number:'1.2-2'}}%</small>
      </div>
    </div>
  </p-panel>
</p-panel>
<p-toast></p-toast>
