
  <ng-container *ngIf="data && type === 'INE'">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          INE
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Primer Nombre:</h4>
        <small>{{getObject(data).firstName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Segundo Nombre:</h4>
        <small>{{getObject(data).secondName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Paterno:</h4>
        <small>{{getObject(data).fatherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Materno:</h4>
        <small>{{getObject(data).motherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CURP:</h4>
        <small>{{getObject(data).curp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Genero:</h4>
        <small>{{getObject(data).sex}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>birthDate:</h4>
        <small>{{getObject(data).birthDate | date:'YYYY-MM-dd'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Fecha de registro:</h4>
        <small>{{getObject(data).registeredDate | date:'YYYY-MM-dd'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Fecha de validación:</h4>
        <small>{{getObject(data).validity.init | date:'YYYY'}} - {{getObject(data).validity.end | date:'YYYY'}}</small>
      </div>
      <div class="col-12">
        <h4>Dirección:</h4>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Estado:</h4>
        <small>{{getObject(data).address.state}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Colonia:</h4>
        <small>{{getObject(data).address.town}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CP:</h4>
        <small>{{getObject(data).address.cp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Calle:</h4>
        <small>{{getObject(data).address.street}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número externo:</h4>
        <small>{{getObject(data).address.externalNumber}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número interno:</h4>
        <small>{{getObject(data).address.internalNumber}}</small>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data && type === 'PULPI_PROOF_OF_ADDRESS'">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Comprobante de domiciliio
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Primer Nombre:</h4>
        <small>{{getObject(data).firstName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Segundo Nombre:</h4>
        <small>{{getObject(data).secondName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Paterno:</h4>
        <small>{{getObject(data).fatherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Materno:</h4>
        <small>{{getObject(data).motherLastName}}</small>
      </div>
      <div class="col-12">
        <h4>Dirección:</h4>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Estado:</h4>
        <small>{{getObject(data).address.state}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Colonia:</h4>
        <small>{{getObject(data).address.neighborhood}} {{getObject(data).address.town}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CP:</h4>
        <small>{{getObject(data).address.cp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Calle:</h4>
        <small>{{getObject(data).address.street}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número externo:</h4>
        <small>{{getObject(data).address.externalNumber}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número interno:</h4>
        <small>{{getObject(data).address.internalNumber}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Monto a pagar:</h4>
        <small>{{getObject(data).amountToPay | currency: 'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Fecha limite de pago:</h4>
        <small>{{getObject(data).endDate}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Teléfono:</h4>
        <small>{{getObject(data).phone}}</small>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data && type === 'PULPI_BALANCE_ACCOUNT'">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          Estado de cuenta
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Primer Nombre:</h4>
        <small>{{getObject(data).firstName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Segundo Nombre:</h4>
        <small>{{getObject(data).secondName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Paterno:</h4>
        <small>{{getObject(data).fatherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Materno:</h4>
        <small>{{getObject(data).motherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>RFC:</h4>
        <small>{{getObject(data).rfc}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CURP:</h4>
        <small>{{getObject(data).curp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Periodo:</h4>

      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Numero de cuenta:</h4>
        <small>{{getObject(data).numberAccount}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Cuenta clabe:</h4>
        <small>{{getObject(data).clabe}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Banco:</h4>
        <small>{{getObject(data).bank}}</small>
      </div>
      <div class="col-12">
        <h4>Dirección:</h4>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Estado:</h4>
        <small>{{getObject(data).address.state}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Colonia:</h4>
        <small>{{getObject(data).address.neighborhood}} {{getObject(data).address.town}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CP:</h4>
        <small>{{getObject(data).address.cp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Calle:</h4>
        <small>{{getObject(data).address.street}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número externo:</h4>
        <small>{{getObject(data).address.externalNumber}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número interno:</h4>
        <small>{{getObject(data).address.internalNumber}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Entre calles:</h4>
        <small>{{getObject(data).address.betweenStreets}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Monto promedio:</h4>
        <small>{{getObject(data).averageBalance | currency: 'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Balance anterior:</h4>
        <small>{{getObject(data).previousBalance | currency: 'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Depositos:</h4>
        <small>{{getObject(data).deposits | currency: 'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Cargos:</h4>
        <small>{{getObject(data).charges | currency: 'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Balance Final:</h4>
        <small>{{getObject(data).finalBalance | currency: 'MXN'}}</small>
      </div>
      <div class="col-12">
        <h4>Movimientos:</h4>
      </div>
      <ng-container *ngFor="let movement of getObject(data).movements;">
        <div class="col-12 md:col-6 xl:col-4 mb-2">
          <small><strong>Referencia:</strong> {{movement.reference}}</small><br>
          <small><strong>Monto:</strong> {{movement.charges | currency: 'MXN'}}</small><br>
          <small><strong>Tipo de operación:</strong> {{movement.typeOperation}}</small><br>
          <small><strong>Descripción:</strong> {{movement.description}}</small><br>
          <small><strong>Fecha de operación:</strong> {{movement.operationDate | date:'YYYY-MM-dd'}}</small><br>
          <small><strong>Fecha de cargo:</strong> {{movement.settlementDate | date:'YYYY-MM-dd'}}</small><br>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="data && type === 'PULPI_LAST_PROOF_OF_PAYROLL' || type === 'PULPI_PENULTIMATE_PROOF_OF_PAYROLL'">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">
          {{type === 'PULPI_LAST_PROOF_OF_PAYROLL' ? 'Ultimo comprobante de nómina': 'Penultimo comprobante de nómina'}}
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Primer Nombre:</h4>
        <small>{{getObject(data).firstName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Segundo Nombre:</h4>
        <small>{{getObject(data).secondName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Paterno:</h4>
        <small>{{getObject(data).fatherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Apellido Materno:</h4>
        <small>{{getObject(data).motherLastName}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>RFC:</h4>
        <small>{{getObject(data).rfc}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>CURP:</h4>
        <small>{{getObject(data).curp}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Tipo de periodo:</h4>
        <small>{{getObject(data).period.type}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Nombre del periodo:</h4>
        <small>{{getObject(data).period.name}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Periodo:</h4>
        <small>{{getObject(data).period.init | date: 'YYYY-MM-dd'}} {{getObject(data).period.end | date: 'YYYY-MM-dd'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Número de seguro social:</h4>
        <small>{{getObject(data).nss}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Tipo de salario:</h4>
        <small>{{getObject(data).typeSalary}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Puesto:</h4>
        <small>{{getObject(data).position}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Dias de pago:</h4>
        <small>{{getObject(data).paymentDays}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Fecha de pago:</h4>
        <small>{{getObject(data).paymentDate | date: 'YYYY-MM-dd'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Salario bruto:</h4>
        <small>{{getObject(data).grossSalary | currency:'MXN'}}</small>
      </div>
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <h4>Salario neto:</h4>
        <small>{{getObject(data).netSalary | currency:'MXN'}}</small>
      </div>
      <div class="col-12">
        <h4>Descuentos:</h4>
      </div>
      <ng-container *ngFor="let discount of getObject(data).discounts;">
        <div class="col-12 md:col-6 xl:col-4 mb-2">
          <small><strong>Nombre del descuento:</strong> {{discount.name}}</small><br>
          <small><strong>Monto:</strong> {{discount.amount | currency: 'MXN'}}</small><br>
          <small><strong>Tipo de descuento:</strong> {{discount.type}}</small><br>
        </div>
      </ng-container>
    </div>
  </ng-container>
