<p-card>
  <div class="row">
    <div class="col-12 mb-5 mb-3">
      <h1 class="mb-3">Proveedores</h1>
      <p class="text-color-secondary">
        Los Proveedores son entidades que ofrecen bienes o servicios a la empresa o a sus usuarios. Se encargan de suministrar los productos necesarios para el funcionamiento de la operación o la entrega de servicios financieros.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtSuppliers
    [scrollable]="true"
    scrollHeight="600px"
    [value]="suppliers"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
    [globalFilterFields]="['name', 'businessName','address', 'market', 'subMarket', 'createdDate']"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="allCols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas" (onChange)="onColumnToggle($event)"></p-multiSelect>
        </div>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtSuppliers.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [style.min-width.px]="150">
          <div *ngIf="col.field !== 'createdDate'" class="flex align-items-center" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-columnFilter *ngIf="col.field !== 'createdDate'" [type]="col.type || 'text'" [field]="col.field" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </div>
          <div *ngIf="col.field === 'createdDate'" class="flex align-items-center" pSortableColumn="createdDate">
            Fecha de creación
            <p-columnFilter type="date" field="createdDate" display="menu" class="mt-1"></p-columnFilter>
            <p-sortIcon field="createdDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('SUPPLIER', 'UPDATE')">
          <th style="min-width:10rem">
            Acciones
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-supply>
      <tr>
        <td *ngFor="let col of cols">
          <ng-container *ngIf="col.field !== 'createdDate'">
            <a *ngIf="col.field === 'name'">{{ supply[col.field] }}</a>
            <span *ngIf="col.field !== 'name'">{{ supply[col.field] }}</span>
          </ng-container>
          <ng-container *ngIf="col.field === 'createdDate'">
            {{ supply.createdDate | date: 'MM/dd/yyyy' }}
          </ng-container>
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('SUPPLIER', 'UPDATE')">
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', supply)" class="p-button-rounded p-button-text"></button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No se encontraron pagadoras</td> <!-- Ajusta el colspan según el número total de columnas -->
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE SUPPLIER ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '450px' }" [header]="formAction === 'CREATE' ? 'Nuevo Proveedor' : 'Actualizar Proveedor'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-supply [entity]="entity" [submitted]="submitted"></app-create-supply>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
