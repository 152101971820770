import {Table} from "primeng/table";
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {DataTableService} from "@data/services/utils/data-table.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {IFinancial} from "@modules/private/financial/data/interfaces/iapi-financial.interface";
import {ApiFinancialService} from "@modules/private/financial/data/services/api/api-financial.service";
import {DatesExportService} from "@data/services/utils/dates-export.service";

@Component({
  selector: 'app-list-financial',
  templateUrl: './list-financial.component.html',
  styleUrls: ['./list-financial.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ListFinancialComponent implements OnInit {
  financials!: IFinancial[];

  cols: any[] = [];

  allCols: any[] = [];


  colsPdf: any[] = [
    { dataKey: 'ID', title: 'ID' },
    { dataKey: 'Nombre', title: 'Nombre' },
    { dataKey: 'Fecha de Acuerdo', title: 'Fecha de Acuerdo' },
    { dataKey: 'Nombre Comercial', title: 'Nombre Comercial' },
    { dataKey: 'Direccion', title: 'Dirección' }
  ];

  loading: boolean = true;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;
  formAction: 'CREATE' | 'UPDATE' = 'CREATE';
  createEntity = false;
  @ViewChild('dtFinancials') dtFinancials!: Table;

  constructor(
    private apiFinancialService: ApiFinancialService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService,
    private datesExportService: DatesExportService
  ) {
    this.createMenu();
  }

  ngOnInit() {

    this.allCols = [
      { field: '_id', header: 'ID'},
      { field: 'name', header: 'Nombre' },
      { field: 'agreementDate', header: 'Fecha de Acuerdo' },
      { field: 'businessName', header: 'Nombre Comercial' },
      { field: 'address', header: 'Direccion' }
    ];

    this.cols = [...this.allCols];

    this.find();
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiFinancialService.getAll().subscribe(r => {
      if (!r.error) {
        this.financials = r.data.map(r => {
          r.agreementDate = new Date(r.agreementDate)
          return r
        });
      }
      this.loading = false
    });
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value;
  }


  newEntity(formAction: 'CREATE' | 'UPDATE', data: IFinancial | null = null) {
    this.formAction = formAction;
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        businessName: ['', [Validators.required]],
        agreementDate: ['', [Validators.required]],
        address: ['']
      });
    } else {
      this.entity = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        businessName: [data?.businessName, [Validators.required]],
        agreementDate: [data?.agreementDate, [Validators.required]],
        address: [data?.address, [Validators.required]],
        id: [data?._id]
      });
    }
    this.entity.clearValidators();
    this.submitted = false;
    this.createEntity = true;
  }

  saveProduct(): void {
    this.submitted = true;
    if (this.entity.valid) {
      const financialData = this.entity.value;

      if (this.formAction === 'CREATE') {
        this.apiFinancialService.createFinancial(financialData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'success',
              summary: response.error ? 'Error' : 'Completado',
              detail: response.error ? response.msg : 'Registro creado exitosamente.',
              life: 3000
            });
            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al crear el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo crear el registro.',
              life: 3000
            });
          }
        );
      } else {
        this.apiFinancialService.updateFinancial(financialData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'success',
              summary: response.error ? 'Error' : 'Completado',
              detail: response.error ? response.msg : 'Registro actualizado exitosamente.',
              life: 3000
            });
            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al actualizar el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo actualizar el registro.',
              life: 3000
            });
          }
        );
      }
    }
  }

  createMenu() {
    if (this.authService.userHasAuthorization('FINANCIAL', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const originalValue = this.dtFinancials.filteredValue || this.dtFinancials.value
                this.dtFinancials.value = originalValue.map((row: any) => {
                  return {
                    ...row,
                    agreementDate: this.datesExportService.formatDate(row.agreementDate),
                  };
                });
                this.dtFinancials.exportCSV();
                this.dtFinancials.value = originalValue;
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })
              }
            },
            {
              label: 'Excel',
              command: () => {
                const financialData = this.dtFinancials.filteredValue || this.dtFinancials.value
                const columns = this.dtFinancials.columns || []
                const formattedFinancialData = this.datesExportService.formatDatesExportData(financialData, columns);
                this.dataTableService.exportExcel(formattedFinancialData);
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                const financialData = this.dtFinancials.filteredValue || this.dtFinancials.value
                const columns = this.dtFinancials.columns || []
                const formattedFinancialData = this.datesExportService.formatDatesExportData(financialData, columns);
                this.dataTableService.exportPdf(this.colsPdf, formattedFinancialData);
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtFinancials.clear();
          this.find();
        }
      },
    ];
    if (this.authService.userHasAuthorization('FINANCIAL', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE');
          }
        }
      ];
    }
  }
}
