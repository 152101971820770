<ng-container *ngIf="entity">
  <form [formGroup]="entity" (ngSubmit)="onSubmit()">
    <div class="field">
      <label for="name">Nombre Comercial*</label>
      <input type="text" pInputText id="name" formControlName="name" placeholder="Fondeadora ABC" required autofocus />
      <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="businessName">Razon Social*</label>
      <input type="text" pInputText id="businessName" formControlName="businessName" placeholder="Nombre" required />
      <ng-container *ngIf="entity.get('businessName')?.invalid && (entity.get('businessName')?.dirty || entity.get('businessName')?.touched)">
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('businessName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field field-flex">
      <label for="agreementDate">Fecha de Inicio*</label>
      <p-calendar id="agreementDate" formControlName="agreementDate" [showIcon]="true" appendTo="body"></p-calendar>
      <ng-container *ngIf="entity.get('agreementDate')?.invalid && (entity.get('agreementDate')?.dirty || entity.get('agreementDate')?.touched)">
        <small class="p-error" *ngIf="entity.get('agreementDate')?.errors?.['required']">La fecha de inicio es obligatoria.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="address">Direccion*</label>
      <input type="text" pInputText id="address" formControlName="address" placeholder="Direccion" required />
    </div>
  </form>
</ng-container>

