import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableService } from "@data/services/utils/data-table.service";
import { Table } from "primeng/table";
import { ApiSupplyService } from "@modules/private/supply/data/services/api/api-supply.service";
import { ISupplier } from "@modules/private/supply/data/interfaces/iapi-supply.interface";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@data/services/modules/auth/auth.service";
import {DatesExportService} from "@data/services/utils/dates-export.service";

@Component({
  selector: 'app-list-supply',
  templateUrl: './list-supply.component.html',
  styleUrls: ['./list-supply.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ListSupplyComponent implements OnInit {
  suppliers!: ISupplier[];
  cols: any[] = [
    { field: 'name', header: 'Nombre' },
    {field: 'businessName', header: 'Nombre del negocio'},
    { field: 'address', header: 'Dirección' },
    { field: 'market', header: 'Mercado' },
    { field: 'subMarket', header: 'Sub Mercado' },
    { field: 'createdDate', header: 'Fecha de creación' },
  ];

  allCols: any[] = [];


  colsPdf: any[] = [
    { dataKey: 'Nombre', title: 'Nombre' },
    {dataKey: 'Nombre del negocio', title: 'Nombre del negocio'},
    { dataKey: 'Direccion', title: 'Direccion' },
    { dataKey: 'Mercado', title: 'Mercado' },
    { dataKey: 'Sub Mercado', title: 'Sub Mercado' },
    { dataKey: 'Fecha de creacion', title: 'Fecha de creacion' },
  ];

  loading: boolean = true;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;
  formAction: 'CREATE' | 'UPDATE' = 'CREATE';
  createEntity = false;
  @ViewChild('dtSuppliers') dtSuppliers!: Table;

  constructor(
    private apiSupplyService: ApiSupplyService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService,
    private datesExportService: DatesExportService
  ) {
    this.createMenu();
  }

  ngOnInit() {

    this.allCols = [
      { field: 'name', header: 'Nombre' },
      {field: 'businessName', header: 'Nombre del negocio'},
      { field: 'address', header: 'Direccion' },
      { field: 'market', header: 'Mercado' },
      { field: 'subMarket', header: 'Sub Mercado' },
      { field: 'createdDate', header: 'Fecha de creacion' },
    ];

    this.cols = [...this.allCols];

    this.find();
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiSupplyService.getAll().subscribe(r => {
      if (!r.error) {
        this.suppliers = r.data.map(r => {
          r.createdDate = new Date(r.createdDate)
          return r
        });
      }
      this.loading = false
    });
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value;
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: ISupplier | null = null) {
    this.formAction = formAction;
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        businessName: ['', [Validators.required]],
        address: [''],
        market: ['', [Validators.required]],
        subMarket: ['', [Validators.required]],
        createdDate: ['', [Validators.required]],
      });

    } else {
      this.entity = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        businessName: [data?.businessName, [Validators.required]],
        address: [data?.address],
        market: [data?.market, [Validators.required]],
        subMarket: [data?.subMarket, [Validators.required]],
        createdDate: [data?.createdDate, [Validators.required]],
        id: [data?._id]
      });
    }
    this.entity.clearValidators();
    this.submitted = false;
    this.createEntity = true;
  }


  saveProduct(): void {
    this.submitted = true;

    if (this.entity.valid) {
      const supplierData = this.entity.value;
      if (this.formAction === 'CREATE') {
        this.apiSupplyService.createSupply(supplierData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'Completado',
              summary: response.error ? 'Error' : 'Completado',
              detail: response.error ? response.msg : 'Registro creado exitosamente.',
              life: 3000
            });

            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al crear el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo crear el registro.',
              life: 3000
            });
          }
        );
      } else {
        this.apiSupplyService.updateSupply(supplierData).subscribe(
          (response) => {
            this.messageService.add({
              severity: response.error ? 'error' : 'success',
              summary: response.error ? 'Error' : 'Completado',
              detail: response.error ? response.msg : 'Registro actualizado exitosamente.',
              life: 3000
            });

            if (!response.error) {
              this.find();
              this.hideDialog();
            }
          },
          (error) => {
            console.error('Error al actualizar el registro:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo actualizar el registro.',
              life: 3000
            });
          }
        );
      }
    }
  }


  createMenu() {
    if (this.authService.userHasAuthorization('SUPPLIER', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const data = this.dtSuppliers.value.map((row: any) => {
                  return {
                    ...row,
                    createdDate: this.datesExportService.formatDate(row.createdDate),
                  };
                });
                const originalValue = this.dtSuppliers.value;
                this.dtSuppliers.value = data;
                this.dtSuppliers.exportCSV();
                this.dtSuppliers.value = originalValue;
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {
                const supplierData = this.dtSuppliers.value;
                const columns = this.dtSuppliers.columns || [];
                const formattedFinancialData = this.datesExportService.formatDatesExportData(supplierData, columns);
                this.dataTableService.exportExcel(formattedFinancialData);
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                const supplierData = this.dtSuppliers.value;
                const columns = this.dtSuppliers.columns || [];
                const formattedFinancialData = this.datesExportService.formatDatesExportData(supplierData, columns);
                this.dataTableService.exportPdf(this.colsPdf, formattedFinancialData);
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtSuppliers.clear();
          this.find();
        }
      },
    ];
    if (this.authService.userHasAuthorization('SUPPLIER', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE');
          }
        }
      ];
    }
  }
}
