<ng-container *ngIf="accountDetail">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">Datos Generales</div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nombre:</h3>
      <p>{{accountDetail.name}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nombre de Negocio:</h3>
      <p>{{accountDetail.businessName}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Banco:</h3>
      <p>{{accountDetail.bank}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Cuenta Clabe:</h3>
      <p>{{accountDetail.clabe}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de Inicio de Convenio:</h3>
      <p>{{accountDetail.agreementDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de Creacion:</h3>
      <p>{{accountDetail.creationDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">Saldos Globales</div>
    </div>
    <app-loader *ngIf="accountDetail.balance===-1 || incomeData.totalAmount===-1 || outcomeData.totalAmount===-1"></app-loader>
    <div class="col-4">
        <h3 class="mb-2">Saldo Disponible:</h3>
      <p>{{ '$' + (accountDetail.balance | number:'1.3-3') }}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Ingresos Totales:</h3>
        <p>{{ '$' + (incomeData.totalAmount | number:'1.3-3') }}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Gastos Totales:</h3>
        <p>{{ '$' + (outcomeData.totalAmount | number:'1.3-3') }}</p>
      </div>
  </div>
</ng-container>
