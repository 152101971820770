import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ILoanCalculator, ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";
import {PERIOD_TYPE_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {LOAN_STATUS_ENUM} from "@modules/private/loan/data/enums/loan.enum";
import {MenuItem, MessageService, SelectItemGroup} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {ApiAccessoryService} from "@modules/private/accesory/data/services/api-accessory.service";
import {IAccessory, IApiAccessoryList} from "@modules/private/accesory/data/interfaces/iaccessory.interface";
import {CurrencyPipe} from "@angular/common";
import {IVoBo} from "@modules/private/loan/data/interfaces/ivobo.interface";
import {ApiVoboService} from "@modules/private/loan/data/services/api/api-vobo.service";
import {VOBO_ENUM} from "@modules/private/loan/data/enums/vobo.enum";
import {ButtonModule} from "primeng/button";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-loan-offers',
  templateUrl: './loan-offers.component.html',
  styleUrls: ['./loan-offers.component.scss'],
  providers: [MessageService, CurrencyPipe]
})
export class LoanOffersComponent implements OnInit{
  isUpdating = false
  @Input() data!: ILoanPopulated & {amountToPay: number, amountToPayToReferenceLoan: number, VoBo: IVoBo, loanCalculated: ILoanCalculator}
  entity!: FormGroup
  installmentsCalculated: IInstallmentCalculated[] = []
  $_periodTypeEnum = PERIOD_TYPE_ENUM
  $_statusLoanEnum = LOAN_STATUS_ENUM
  @Output() updateData = new EventEmitter()
  accessories: SelectItemGroup[] = []
  requiredAccessories: IAccessory[] = []
  $_voboStatusEnum = VOBO_ENUM
  items: MenuItem[] = [];
  collapsedAll = false
  constructor(
    private fb: FormBuilder,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    public authService: AuthService,
    private accessoryService: ApiAccessoryService,
    private currencyPipe: CurrencyPipe,
    private voBoService: ApiVoboService,
    private loaderService: LoaderService
  ) {

  }

  ngOnInit() {
    this.entity = this.fb.group({
      amount: [this.data.amount, [Validators.required, Validators.min(500)]],
      installmentCount: [this.data.installmentCount, [Validators.required, Validators.min(1)]],
      rate: [(this.data.defaultProperties ? (this.data.loanCalculated.defaultRate).toFixed(2): (this.data.rate).toFixed(2)), [Validators.required, Validators.min(0)]],
      creationFee:  [this.data.defaultProperties ? this.data.loanCalculated.defaultCreationFee : this.data.creationFee, [Validators.required, Validators.min(0)]],
      accountNumber: [this.data.accountNumber, [Validators.required, Validators.pattern(/^\d{18}$/)]],
      optionalAccessories: this.data.accessories ? [this.data.accessories.filter(a => a._id && a.type === 'OPTIONAL').map(a => a._id)]: [],
      defaultProperties: [this.data.defaultProperties],
      hasIkeAttendance: [this.data.hasIkeAttendance, [Validators.required]]
    })
    this.getAmortizationTable()
    this.getAllAccessories()
    this.updateItems()
  }

  setDefaultProperties() {
    console.log("inside")
    if (this.entity.get('defaultProperties')?.value) {
      console.log("patching")
      this.entity.patchValue({
        rate: (this.data.loanCalculated.defaultRate).toFixed(2),
        creationFee: this.data.loanCalculated.defaultCreationFee
      })
      this.entity.get('rate')?.disable()
      this.entity.get('creationFee')?.disable()
    } else {
      this.entity.get('rate')?.enable()
      this.entity.get('creationFee')?.enable()
    }
  }

  updateItems(){
    console.log('updating items')
    this.items = [
      {
        label: 'Enviar a visto bueno',
        icon: 'pi pi-cog',
        disabled: this.entity.invalid || this.isUpdating,
        command: () => {
          this.sendToVoBo()
        }
      },
      {
        icon: 'fa-solid fa-times',
        label: 'Enviar a firmas',
        disabled: this.entity.invalid || this.isUpdating,
        command: () => {
          this.saveAndRecalculate(true)
        }
      },
      {
        icon: 'fa-solid fa-times',
        label: 'Recalcular',
        disabled: this.entity.invalid || this.isUpdating,
        command: () => {
          console.log('recalculating')
          this.saveAndRecalculate()
        }
      }
    ]
  }

  getAllAccessories() {
    this.accessoryService.getAll().subscribe(r => {
      console.log(r)
      this.requiredAccessories = r.data.filter(a => a.type === 'REQUIRED')
      this.accessories = [
        {
          label: 'Accesorios opcionales',
          value: 'Accesorios opcionales',
          items: r.data.filter(a => a.type !== 'REQUIRED').map(a => {
            const prices =  a.isFinanced ? `${ a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.weekly) : a.price.weekly + '%'} semanal | ${ a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.biweekly) : a.price.biweekly + '%'} catorcenal | ${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.semimonthly) : a.price.semimonthly + '%'} quincenal | ${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.monthly) : a.price.monthly + '%'} mensual` : `${a.calculation === 'FIXED' ? this.currencyPipe.transform(a.price.creation) : a.price.creation + '%' } una sola exibicion`
            return {
              label: `${a.name} | Costo: ${prices} | ${a.isFinanced ? 'Es financiado' : 'No financiado'}`,
              value: a._id,
              checked: true
            }
          })
        }
      ]
    })
  }


  getAmortizationTable() {
    this.apiLoanService.getCalculatedInstallments(this.data._id).subscribe(r => {
      console.log(r)
      this.installmentsCalculated = r.data
    })
  }

  saveAndRecalculate(sendAgreement = false) {
    this.isUpdating = true
    console.log(this.entity.value)
    this.loaderService.showSpinner()
    this.apiLoanService.update({...this.entity.value, sendAgreement, id: this.data._id, requiredAccessories: this.requiredAccessories.map(a => a._id)}).subscribe((r) => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      if(!r.error) {
        this.getAmortizationTable()
        this.updateData.emit()
      }
      this.loaderService.hideSpinner()
    })
  }

  transformDate(date: string) {
    return date.substring(0,10)
  }

  get getTotalAmounts() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.revenueAmount
      totals.capital += i.principalAmount
      totals.total = totals.total + i.revenueAmount + i.principalAmount
    })
    return totals
  }

  get getAccessoryAmounts() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.accessoryRevenueAmount
      totals.capital += i.accessoryPrincipalAmount
      totals.total = totals.total + i.accessoryRevenueAmount + i.accessoryPrincipalAmount
    })
    return totals
  }

  get getResumeTotals() {
    const totals = {interests: 0, capital: 0, total: 0}
    this.installmentsCalculated.forEach(i => {
      totals.interests += i.accessoryRevenueAmount + i.revenueAmount
      totals.capital += i.accessoryPrincipalAmount + i.principalAmount
      totals.total = totals.total + i.accessoryRevenueAmount + i.accessoryPrincipalAmount + i.principalAmount + i.revenueAmount
    })
    return totals
  }

  sendToVoBo() {
    this.loaderService.showSpinner()
    this.voBoService.create({productId: this.data._id}).subscribe(r => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.loaderService.hideSpinner()
    })
  }
}
