<ng-container *ngIf="entity">
  <form [formGroup]="entity" (ngSubmit)="onSubmit()">
    <div class="field">
      <label for="businessName">Nombre Comercial*</label>
      <input type="text" pInputText id="businessName" formControlName="businessName" placeholder="Proveedor ABC" required autofocus />
      <ng-container *ngIf="entity.get('businessName')?.invalid && (entity.get('businessName')?.dirty || entity.get('businessName')?.touched)">
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('businessName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="name">Nombre*</label>
      <input type="text" pInputText id="name" formControlName="name" placeholder="Nombre" required />
      <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="address">Direccion</label>
      <input type="text" pInputText id="address" formControlName="address" placeholder="Direccion"/>
    </div>
    <div class="field">
      <label for="market">Mercado*</label>
      <input type="text" pInputText id="market" formControlName="market" placeholder="Mercado" required />
      <ng-container *ngIf="entity.get('market')?.invalid && (entity.get('market')?.dirty || entity.get('market')?.touched)">
        <small class="p-error" *ngIf="entity.get('market')?.errors?.['market']">El mercado es obligatoria.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="subMarket">SubMercado*</label>
      <input type="text" pInputText id="subMarket" formControlName="subMarket" placeholder="SubMercado" required />
      <ng-container *ngIf="entity.get('subMarket')?.invalid && (entity.get('subMarket')?.dirty || entity.get('subMarket')?.touched)">
        <small class="p-error" *ngIf="entity.get('subMarket')?.errors?.['subMarket']">El mercado es obligatoria.</small>
      </ng-container>
    </div>
    <div class="field field-flex">
      <label for="createdDate">Fecha de Inicio*</label>
      <p-calendar id="createdDate" formControlName="createdDate" [showIcon]="true" appendTo="body"></p-calendar>
      <ng-container *ngIf="entity.get('createdDate')?.invalid && (entity.get('createdDate')?.dirty || entity.get('createdDate')?.touched)">
        <small class="p-error" *ngIf="entity.get('createdDate')?.errors?.['required']">La fecha de inicio es obligatoria.</small>
      </ng-container>
    </div>
  </form>
</ng-container>

