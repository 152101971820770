<ng-container *ngIf="financialDetail">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">Datos Generales</div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nombre:</h3>
      <p>{{financialDetail.name}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Razón Social:</h3>
      <p>{{financialDetail.businessName}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Dirección:</h3>
      <p>{{financialDetail.address}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de Inicio de Convenio:</h3>
      <p>{{financialDetail.agreementDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de Alta:</h3>
      <p>{{financialDetail.agreementDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">Lineas de Fondeo</div>
    </div>
  </div>
</ng-container>
