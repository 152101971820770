import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ViewSaverService {
  table:any = {}

  saveFilters(tableKey: string, filters: any, page: any, limit: any, data: any = [], search: boolean = true, totalRecords = 0) {
    this.table[tableKey] = { filters, page, limit, data, search, totalRecords };
    console.log('Saved state for table:', tableKey, this.table[tableKey], page, totalRecords);
    //return this.table[tableKey];
  }

  changeSearchStatus(tableKey: string, status: boolean) {
    this.table[tableKey] = {  ...this.table[tableKey], search: status };
}

  updateData(tableKey: string, data: any, totalRecords: number = 0) {
    this.table[tableKey] = {  ...this.table[tableKey], data, totalRecords };
  }

  getFilters(tableKey: string): { filters: any; page: number; limit: number, data: any, search: boolean, totalRecords: number } | null {
    return this.table[tableKey] ? this.table[tableKey] : null;
  }


  clearFilters(tableKey: string, dataTable: any, callback: () => void) {
    sessionStorage.removeItem(tableKey);
    dataTable.clear();
    callback();
  }
}
