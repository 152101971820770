export enum PERIOD_TYPE_ENUM {
  BIWEEKLY_PERIOD = 'Catorcenal',
  MONTHLY_PERIOD = 'Mensual',
  SEMIMONTHLY_PERIOD = 'Quincenal',
  WEEKLY_PERIOD = 'Semanal'
}

export enum PERIOD_TYPE_ENUM_COLOR {
  BIWEEKLY_PERIOD = 'secondary',
  MONTHLY_PERIOD = 'help',
  SEMIMONTHLY_PERIOD = 'success',
  WEEKLY_PERIOD = 'info'
}

export enum STATUS_CALENDAR_ENUM {
  PENDING = 'PENDIENTE',
  IN_PROGRESS = 'ACTUAL',
  COMPLETED = 'COMPLETADA'
}
