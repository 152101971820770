import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map, Observable} from "rxjs";
import {ILoan} from "@modules/private/loan/data/interfaces/iloan.interface";
import {IVoBo} from "@modules/private/loan/data/interfaces/ivobo.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiVoboService extends ApiClass {
  constructor() {
    super();
    this.path = 'voBo'
  }

  create(data: any): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IVoBo}))
  }
}
