<ng-container *ngIf="entity">
  <form [formGroup]="entity" (ngSubmit)="onSubmit()">
    <div class="field">
      <label for="balanceName">Nombre del Balance</label>
      <input type="text" pInputText id="balanceName" formControlName="balanceName" placeholder="Nombre" required autofocus />
      <ng-container *ngIf="entity.get('balanceName')?.invalid && (entity.get('balanceName')?.dirty || entity.get('balanceName')?.touched)">
        <small class="p-error" *ngIf="entity.get('balanceName')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('balanceName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('balanceName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="balance">Balance</label>
      <input type="number" pInputText id="balance" formControlName="balance" placeholder="Balance" required />
      <ng-container *ngIf="entity.get('balance')?.invalid && (entity.get('balance')?.dirty || entity.get('balance')?.touched)">
        <small class="p-error" *ngIf="entity.get('balance')?.errors?.['required']">El balance es obligatorio.</small>
      </ng-container>
    </div>
  </form>
</ng-container>



