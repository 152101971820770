import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {catchError, map, of} from "rxjs";
import {
  IApplicationAttempt,
  IApplicationMassive,
  IRecordRequestApplicationList
} from "@modules/private/application/data/interfaces/application.interface";
import {IApiLoanList} from "@modules/private/loan/data/interfaces/iloan.interface";
import {IResponseArray} from "@data/interfaces/iresponseArray.interface";
import {environment} from "@env/environment";
import {IReport} from "@modules/private/reports/data/interfaces/ireport.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiApplicationService extends ApiClass{

  constructor() {
    super();
    this.path = 'application'
  }

  getAll(page = 1, limit = 10, filters = {}) {
    const response: IRecordRequestApplicationList = {error: true, msg: '', data: [], totalItems: 0, status: 200, page: 1, itemsByPage: 10}
    return this.http.post<IResponseArray>(environment.apiUrl + this.path + '/all' , {page, limit, filters}).pipe(
      map((r) => r as IRecordRequestApplicationList),
      catchError((e) => of(response))
    )
  }

  getAllAttempts() {
    return this.postCollection('' , 'attempts').pipe(map((r) => r as {data: IApplicationAttempt[]; msg: string; error: boolean;}))
  }

  upload(data: IApplicationMassive[], typeProduct: string, skipPaymentAlreadyApplied: boolean, skipOverpaidProduct: boolean) {
    return this.postCollection({
      typeProduct,
      skipPaymentAlreadyApplied,
      skipOverpaidProduct,
      data
    }).pipe(map((r) => r as {error: boolean, msg: string, data: {errorType: string;urlReport: string; urlOrigin: string}}), catchError((e) => {
      return of({error: true, msg: e.message, data: {errorType: '', urlReport: null, urlOrigin: null}})
    }))
  }
}
