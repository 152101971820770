import { Table } from "primeng/table";
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AuthService } from "@data/services/modules/auth/auth.service";
import { DataTableService } from "@data/services/utils/data-table.service";
import { MenuItem, MessageService } from "primeng/api";
import { ITransactionList} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";
import {ApiBalanceService} from "@modules/private/bank-accounts/data/services/api/api-balance.service";
import {
  getSubTypeEsp,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_ESP
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";
import {DatesExportService} from "@data/services/utils/dates-export.service";

@Component({
  selector: 'app-placement-table',
  templateUrl: './placement-table.component.html',
  styleUrls: ['./placement-table.component.scss']
})
export class PlacementTableComponent implements OnInit{
  @Input() accountId!:string
  @Input() placementData!: ITransactionList;
  @Output() updateStatusEvent = new EventEmitter<any>()

  cols: any[] = [
    { field: 'name', header: 'Nombre' },
    { field: 'folio', header: 'Folio' },
    {field: 'amount', header: 'Cantidad'},
    {field: 'subType', header: 'Sub Tipo'},
    {field: 'destinationType', header: 'Tipo de Destino'},
    {field: 'status', header: 'Estatus'},
    { field: 'description', header: 'Descripción' },
    { field: 'applicationDate', header: 'Fecha de Aplicación' },
    { field: 'creationDate', header: 'Fecha de Creacion' }

  ];

  allCols: any[] = [];


  colsPdf: any[] = [
    { dataKey: 'Folio', title: 'Folio' },
    { dataKey: 'Cantidad', title: 'Cantidad' },
    { dataKey: 'Sub Tipo', title: 'Sub Tipo' },
    { dataKey: 'Tipo de Destino', title: 'Tipo de Destino' },
    { dataKey: 'Estatus', title: 'Estatus' },
    { dataKey: 'Descripción', title: 'Descripción' },
    { dataKey: 'Fecha de Aplicación', title: 'Fecha de Aplicación' },
    { dataKey: 'Fecha de Creacion', title: 'Fecha de Creación' }
  ];

  typeLabels: { [key: string]: string } = {
    'DEPOSITO_BANCARIO': 'BANK_DEPOSIT',
    'FONDEADORA': 'FUNDING_DEPOSIT',
    'PAGO DE LETRA': 'APPLICATION',
    'PAGO DE SERVICIO': 'SUPPLIER',
    'GASTOS GENERALES': 'GENERAL_EXPENSE',
    'CREDITO': 'LOAN_ALLOCATION',
    'TRABAJADOR': 'WORKER',
    'NOMINA': 'COMPANY'
  }

  loading: boolean = false;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;
  formAction: 'CREATE' | 'UPDATE' = 'CREATE';
  createEntity = false;
  @ViewChild('dtPlacements') dtPlacements!: Table;

  constructor(
    private apiBalanceService: ApiBalanceService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    public authService: AuthService,
    private dateExportService: DatesExportService
  ) {
    this.createMenu();
  }

  ngOnInit() {

    this.allCols = [
      { field: 'folio', header: 'Folio' },
      {field: 'amount', header: 'Cantidad'},
      {field: 'subType', header: 'Sub Tipo'},
      {field: 'destinationType', header: 'Tipo de Destino'},
      {field: 'status', header: 'Estatus'},
      { field: 'description', header: 'Descripción' },
      { field: 'applicationDate', header: 'Fecha de Aplicación' },
      { field: 'creationDate', header: 'Fecha de Creacion' }

    ];

    this.cols = [...this.allCols];
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiBalanceService.getBalance('INCOME', true, {originAccountId: this.accountId}).subscribe(r => {
      this.placementData = r.data.transactions.transactions.filter((it:any) => it.subType=== 'APPLICATION' || it.subType === 'LOAN_ALLOCATION')
    })
    this.loading = false;

  }

  getValue($event: Event) {
    return this.translate(($event.target as HTMLInputElement).value)
  }

  createMenu() {
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const data = this.dtPlacements.value.map((row: any) => {
                  return {
                    ...row,
                    creationDate: this.dateExportService.formatDate(row.creationDate),
                    applicationDate: this.dateExportService.formatDate(row.applicationDate),
                    subType: this.translate(row.subType),
                    destinationType: row.destinationType === 'WORKER' ? 'TRABAJADOR' : 'NOMINA',
                    status: TRANSACTION_STATUS_ESP[row.status as keyof typeof TRANSACTION_STATUS],
                  };
                });
                const originalValue = this.dtPlacements.value;
                this.dtPlacements.value = data;
                this.dtPlacements.exportCSV();
                this.dtPlacements.value = originalValue;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {
                const accountData = this.dtPlacements.value
                const columns = this.dtPlacements.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(accountData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Tipo de Destino': row['Tipo de Destino'] === 'WORKER' ? 'TRABAJADOR' : 'NOMINA',
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS],
                  }
                })
                this.dataTableService.exportExcel(formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })
              }
            },
            {
              label: 'PDF',
              command: () => {
                const accountData = this.dtPlacements.value
                const columns = this.dtPlacements.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(accountData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Tipo de Destino': row['Tipo de Destino'] === 'WORKER' ? 'TRABAJADOR' : 'NOMINA',
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS],
                  }
                })
                this.dataTableService.exportPdf(this.colsPdf, formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' })
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtPlacements.clear();
          this.find();
        }
      },
    ];
  }

  updateStatus(income: any) {
    if (income.subType === 'APPLICATION' && income.status === TRANSACTION_STATUS.PENDING)
      this.updateStatusEvent.emit(income)
  }

  getSeverity(status: string) {
    switch (status) {
      case 'APPLICATION':
        return 'success'
      case 'LOAN_ALLOCATION':
        return 'warning'
      case 'SOD':
        return 'info'
      default:
        return 'help'
    }
  }

  translationMap: { [key: string]: string } = Object.assign(
    {},
    this.typeLabels,
    Object.fromEntries(Object.entries(this.typeLabels).map(([key, value]) => [value, key]))
  );

  translate(value: string): string {
    return this.translationMap[value.toUpperCase()] || value;
  }

  protected readonly getSubTypeEsp = getSubTypeEsp;
}
