import { Table } from "primeng/table";
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AuthService } from "@data/services/modules/auth/auth.service";
import { DataTableService } from "@data/services/utils/data-table.service";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import {ITransaction} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";
import {ApiBalanceService} from "@modules/private/bank-accounts/data/services/api/api-balance.service";
import {
  getSubTypeEsp,
  TRANSACTION_STATUS_ESP
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";
import {DatesExportService} from "@data/services/utils/dates-export.service";

@Component({
  selector: 'app-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: ['./expense-table.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ExpenseTableComponent implements OnInit{
  protected readonly getSubTypeEsp = getSubTypeEsp;
  @Input() outcomes: ITransaction[] = []
  @Input() accountId!: string
  @ViewChild('dtExpenses') dtExpenses!: Table
  cols: any[] = [
    { field: 'folio', header: 'Folio' },
    {field: 'amount', header: 'Cantidad'},
    {field: 'subType', header: 'Sub Tipo'},
    {field: 'status', header: 'Estatus'},
    { field: 'description', header: 'Descripción' },
    { field: 'applicationDate', header: 'Fecha de Aplicación' },
    { field: 'creationDate', header: 'Fecha de Creacion' }

  ];

  allCols: any[] = [];


  colsPdf: any[] = [
    { dataKey: 'Folio', title: 'Folio' },
    { dataKey: 'Cantidad', title: 'Cantidad' },
    { dataKey: 'Sub Tipo', title: 'Sub Tipo' },
    { dataKey: 'Estatus', title: 'Estatus' },
    { dataKey: 'Descripción', title: 'Descripción' },
    { dataKey: 'Fecha de Aplicación', title: 'Fecha de Aplicación' },
    { dataKey: 'Fecha de Creacion', title: 'Fecha de Creación' }
  ];

  typeLabels: { [key: string]: string } = {
    'DEPOSITO_BANCARIO': 'BANK_DEPOSIT',
    'FONDEADORA': 'FUNDING_DEPOSIT',
    'PAGO DE LETRA': 'APPLICATION',
    'PAGO DE SERVICIO': 'SUPPLIER',
    'GASTOS_GENERALES': 'GENERAL_EXPENSE',
    'CREDITO': 'LOAN_ALLOCATION',
    'TRABAJADOR': 'WORKER',
    'NOMINA': 'COMPANY'
  }

  loading: boolean = false;
  items: MenuItem[] = [];
  entity!: FormGroup;
  submitted = false;

  constructor(
    private apiBalanceService: ApiBalanceService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    public authService: AuthService,
    private dateExportService: DatesExportService

  ) {
    this.createMenu();
  }

  ngOnInit() {
    this.allCols = [
      { field: 'folio', header: 'Folio' },
      {field: 'amount', header: 'Cantidad'},
      {field: 'subType', header: 'Sub Tipo'},
      {field: 'status', header: 'Estatus'},
      { field: 'description', header: 'Descripción' },
      { field: 'applicationDate', header: 'Fecha de Aplicación' },
      { field: 'creationDate', header: 'Fecha de Creacion' }

    ];

    this.cols = [...this.allCols];
  }

  onColumnToggle(event: any) {
    const selectedCols = event.value;
    this.cols = this.allCols.filter(col => selectedCols.includes(col));
  }

  find() {
    this.loading = true;
    this.apiBalanceService.getBalance('OUTCOME', true, {originAccountId:this.accountId}).subscribe(r => {
      if (!r.error) {
        this.outcomes = r.data.transactions
      }
      this.loading = false;
    });
  }

  getValue($event: Event) {
    return this.translate(($event.target as HTMLInputElement).value)
  }

  createMenu() {
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'CSV',
              command: () => {
                const data = this.dtExpenses.value.map((row: any) => {
                  return {
                    ...row,
                    creationDate: this.dateExportService.formatDate(row.creationDate),
                    applicationDate: this.dateExportService.formatDate(row.applicationDate),
                    subType: this.translate(row.subType),
                    status: TRANSACTION_STATUS_ESP[row.status as keyof typeof TRANSACTION_STATUS_ESP]
                  };
                });
                const originalValue = this.dtExpenses.value
                this.dtExpenses.value = data
                this.dtExpenses.exportCSV();
                this.dtExpenses.value = originalValue;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'Excel',
              command: () => {
                const expenseData = this.dtExpenses.value
                const columns = this.dtExpenses.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(expenseData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS_ESP]
                  }
                })
                this.dataTableService.exportExcel(formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                const expenseData = this.dtExpenses.value
                const columns = this.dtExpenses.columns || []
                let formattedAccountData = this.dateExportService.formatDatesExportData(expenseData, columns)
                formattedAccountData = formattedAccountData.map((row: any) => {
                  return {
                    ...row,
                    'Sub Tipo': this.translate(row['Sub Tipo']),
                    'Estatus': TRANSACTION_STATUS_ESP[row['Estatus'] as keyof typeof TRANSACTION_STATUS_ESP]
                  }
                })
                this.dataTableService.exportPdf(this.colsPdf, formattedAccountData)
                this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find();
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtExpenses.clear();
          this.find();
        }
      },
    ];
  }

  getSeverity(status: string) {
    switch (status) {
      case 'SUPPLIER':
        return 'success';

      case 'GENERAL_EXPENSE':
        return 'info';
      default:
        return ''
    }
  }

  translationMap: { [key: string]: string } = Object.assign(
    {},
    this.typeLabels,
    Object.fromEntries(Object.entries(this.typeLabels).map(([key, value]) => [value, key]))
  );

  translate(value: string): string {
    return this.translationMap[value.toUpperCase()] || value;
  }
}

