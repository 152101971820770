import {Component, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@data/services/modules/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { IAccount } from "@modules/private/bank-accounts/data/interfaces/iapi-account.interface";
import {ApiBalanceService} from "@modules/private/bank-accounts/data/services/api/api-balance.service";
import {ApiBankAccountService} from "@modules/private/bank-accounts/data/services/api/api-bank-account.service";
import {ITransaction, ITransactionList} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";
import {
  TRANSACTION_STATUS,
  TRANSACTION_SUB_TYPE
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";
import {Subject, takeUntil} from "rxjs";
import {ApiPayrollService} from "@modules/private/payroll/data/services/api/api-payroll.service";


@Component({
  selector: 'app-detail-accounts',
  templateUrl: './detail-accounts.component.html',
  styleUrls: ['./detail-accounts.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class DetailAccountsComponent implements OnInit{
  accountId!: string;
  accountDetail: IAccount = {
    _id: '',
    name: '',
    businessName: '',
    agreementDate: new Date(),
    clabe: '',
    bank: '',
    balance: -1,
    totalDebits: '',
    creationDate: new Date(),
    totalDeposits: 0,
    isLoanTransactionDefault: false,
    isSoDTransactionDefault: false
  }
  items: MenuItem[] = [];
  formAction: 'CREATE' | 'UPDATE' = 'UPDATE';
  entity!: FormGroup;
  showCreatePlacement = false;
  showOutcomeModal = false;
  showMassiveColocation = false;
  loading: boolean = false;
  accounts!: IAccount[];
  incomeData!: ITransactionList
  placementData!: ITransactionList
  outcomeData!: any
  incomeTableData!: any
  showCompleteModal = false
  transactionData!: any
  totalIncomes: ITransactionList = {totalAmount:-1, transactions: []}
  totalOutcomes: ITransactionList = {totalAmount:-1, transactions: []}
  cols: any[] = [
    {field: 'balanceName', header: 'Nombre'},
    {field: 'balance', header: 'Balance'}
  ];
  private destroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private apiAccountService: ApiBankAccountService,
    public authService: AuthService,
    private apiBalanceService: ApiBalanceService,
    private apiPayrollService: ApiPayrollService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe( async params => {
      this.accountId = params['id'];
      await this.getAccountDetail(this.accountId);
    });
  }

  async getAccountDetail(id: string) {
    this.apiAccountService.getById(id).subscribe(response => {
      if (!response.error) {
        this.accountDetail = response.data;
        this.createMenu();
      }
    });

    this.apiBalanceService.getBalance('INCOME', true, {targetAccountId: id, subType: [TRANSACTION_SUB_TYPE.FUNDING_DEPOSIT, TRANSACTION_SUB_TYPE.BANK_DEPOSIT]}).subscribe(r => {
      this.incomeData = r.data
      this.totalIncomes.transactions = this.incomeData.transactions.filter(
        (it: ITransaction) => (it.status == TRANSACTION_STATUS.APPLIED || it.status == TRANSACTION_STATUS.PENDING)
      )
      this.incomeTableData = this.incomeData.transactions
    })

    this.apiBalanceService.getBalance('ALL', true,
      {
        originAccountId: id,
        subType: [TRANSACTION_SUB_TYPE.LOAN_ALLOCATION, TRANSACTION_SUB_TYPE.SOD, TRANSACTION_SUB_TYPE.APPLICATION]
      }).subscribe(r => {
      this.placementData = r.data
    })

    this.apiBalanceService.getBalance('OUTCOME', true, {originAccountId: id, subType: [TRANSACTION_SUB_TYPE.GENERAL_EXPENSE, TRANSACTION_SUB_TYPE.SUPPLIER, TRANSACTION_SUB_TYPE.SOD]}).subscribe(r => {
      this.outcomeData = r.data
      this.totalIncomes.totalAmount = this.totalIncomes.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
      this.totalOutcomes.transactions = this.outcomeData.transactions.filter((it: ITransaction) => (it.status==TRANSACTION_STATUS.APPLIED || it.status==TRANSACTION_STATUS.PENDING))
      this.totalOutcomes.totalAmount = this.totalOutcomes.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    })
  }

  hideModalDialog(modal: string) {
    switch (modal) {
      case 'OUTCOME':
        this.showOutcomeModal = false
        break
      case 'PLACEMENT':
        this.showCreatePlacement = false
        break
      case 'COMPLETE_MODAL':
        this.showCompleteModal = false
    }
  }

  createMenu() {
    if (this.authService.userHasAuthorization('BANK_ACCOUNT', 'UPDATE')) {
      const items = []
      items.push({
          label: 'Registrar una Colocación',
          icon: 'fa-solid fa-pen',
          command: () => {
            this.showCreatePlacement = true
            this.showMassiveColocation = false
          }
        },
        {
          label: 'Colocación Masiva',
          icon: 'fa-solid fa-pen',
          command: () => {
            this.showCreatePlacement = false
            this.showOutcomeModal = false
            this.showMassiveColocation = true
          }
        },
        {
          label: 'Dispersar Saldo',
          icon: 'fa-solid fa-money-bill-trend-up',
          command: () => {
            this.showOutcomeModal = true
            this.showMassiveColocation = false
          }
        })
      this.items = items
    }
  }

  onSubmit() {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  updateStatus(data: any) {
    this.loading=true
    if (data.subType === TRANSACTION_SUB_TYPE.APPLICATION) {
      this.apiPayrollService.getById(data.targetAccount).pipe(takeUntil(this.destroy$)).subscribe(r => {
        data.payroll = {id: r.error? '-' : r.data._id, name: r.error? '-' : r.data.name}
        this.transactionData = data
        this.showCompleteModal = true
        this.loading=false
      })
    } else {
      this.transactionData = data
      this.showCompleteModal = true
      this.loading=false
    }
  }

}

