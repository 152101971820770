import {ITableCol} from "@data/interfaces/itable.interface";
import {GENDER_WORKER, GENDER_WORKER_COLOR} from "@modules/private/workers/data/enums/gender.enum";
import {
  WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM, WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM_COLOR,
  WORKER_STATUS_ENUM,
  WORKER_STATUS_ENUM_COLOR,
  WORKER_STATUS_VERIFICATION_ENUM, WORKER_STATUS_VERIFICATION_ENUM_COLOR
} from "@modules/private/workers/data/enums/workerStatus.enum";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_COLOR} from "@modules/private/company/data/enums/typePeriods.enum";

export const WORKER_COLUMNS : ITableCol[] = [
  //{type: 'text', field: 'rfc', header: 'RFC', showFilter: true, freeze: true},
  {type: 'text', field: 'firstName', header: 'Primer nombre', showFilter: true},
  {type: 'text', field: 'secondName', header: 'Segundo Nombre', showFilter: true},
  {type: 'text', field: 'fatherLastName', header: 'Apellido Paterno', showFilter: true},
  {type: 'text', field: 'motherLastName', header: 'Apellido Materno', showFilter: true},
  {type: 'text', field: 'fullName', header: 'Nombre completo', showFilter: true},
  {type: 'text', field: 'curp', header: 'CURP', showFilter: true},
  {type: 'text', field: 'correo', header: 'Email', showFilter: true},
  {type: 'text', field: 'phoneNumber', header: 'Teléfono', showFilter: true},
  {type: 'text', field: 'gender', header: 'Genero', showFilter: true, dataType: 'enum', enum: GENDER_WORKER, enumColor: GENDER_WORKER_COLOR},
  {type: 'text', field: 'registerStatus', header: 'Estatus de registro', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_ENUM, enumColor: WORKER_STATUS_ENUM_COLOR},
  {type: 'text', field: 'verificationSatus', header: 'Estatus de verificación', showFilter: true, dataType: 'enum', enum: WORKER_STATUS_VERIFICATION_ENUM, enumColor: WORKER_STATUS_VERIFICATION_ENUM_COLOR},
  {type: 'text', field: 'minimumRequieredDataStatus', header: 'Estatus de minima informacion requerida', showFilter: true, dataType: 'enum', enum: WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM, enumColor: WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM_COLOR},
  {type: 'boolean', field: 'isActiveLoan', header: 'Estatus de créditos', dataType: 'boolean', showFilter: true},
  {type: 'boolean', field: 'isActiveWithdrawal', header: 'Estatus de retiros', dataType: 'boolean', showFilter: true},
  {type: 'text', field: 'referralCode', header: 'Código de Referido', showFilter: true},
  {type: 'text', field: 'positionName', header: 'Nombre de posición', showFilter: true},
  {type: 'date', field: 'startDate', header: 'Fecha de ingreso', dataType: 'date', showFilter: true},
  {type: 'date', field: 'endDate', header: 'Fecha de egreso', dataType: 'date', showFilter: true},
  {type: 'date', field: 'activationDate', header: 'Fecha de activación', dataType: 'date', showFilter: true},
  {type: 'date', field: 'creationDate', header: 'Fecha de creación', dataType: 'date', showFilter: true},
  {type: 'numeric', field: 'salary', header: 'Salario', dataType: 'currency', showFilter: true},
  {type: 'text', field: 'company', header: 'Corporativo', showFilter: true},
  {type: 'text', field: 'agreement', header: 'Razón social', showFilter: true},
  {type: 'text', field: 'remunerator', header: 'Pagadora', showFilter: true},
  {type: 'text', field: 'payroll', header: 'Nómina', showFilter: true},
  {type: 'text', field: 'period', header: 'Periodo',showFilter: true, dataType: 'enum', enum: TYPE_PERIOD_ENUM, enumColor: TYPE_PERIOD_ENUM_COLOR},
  {type: 'text', field: 'token', header: 'Token', showFilter: true},
  {type: 'text', field: 'totalWithdrawals', header: 'Retiros totales', showFilter: true},
  {type: 'text', field: 'withdrawalsPaid', header: 'Retiros pagados', showFilter: true},
  {type: 'numeric', field: 'withdrawalsAmountPaid', header: 'Monto total pagado de retiros', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'withdrawalsAmountPending', header: 'Monto total por pagar de retiros', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'withdrawalsAmounTotalToPaid', header: 'Monto total en retiros', dataType: 'currency', showFilter: true},
  {type: 'text', field: 'loansApproved', header: 'Créditos aprobados', showFilter: true},
  {type: 'text', field: 'loansRejected', header: 'Créditos rechazados', showFilter: true},
  {type: 'text', field: 'loansPaid', header: 'Créditos pagados', showFilter: true},
  {type: 'numeric', field: 'loansPendingToPaid', header: 'Créditos en proceso de pago', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'loansAmountPaid', header: 'Monto total pagado de créditos', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'loansAmountTotalToPaid', header: 'Monto total en créditos', dataType: 'currency', showFilter: true},
  {type: 'numeric', field: 'loansAmountPending', header: 'Monto total por pagar de créditos', dataType: 'currency', showFilter: true},
]
