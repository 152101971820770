<ng-container *ngIf="entity">
  <form [formGroup]="entity" (ngSubmit)="onSubmit()">
    <div class="field">
      <label for="name">Nombre*</label>
      <input type="text" pInputText id="name" formControlName="name" placeholder="Cuenta de Banco ABC" required autofocus />
      <ng-container *ngIf="entity.get('name')?.invalid && (entity.get('name')?.dirty || entity.get('name')?.touched)">
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('name')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('name')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="businessName">Nombre Comercial*</label>
      <input type="text" pInputText id="businessName" formControlName="businessName" placeholder="Nombre" required />
      <ng-container *ngIf="entity.get('businessName')?.invalid && (entity.get('businessName')?.dirty || entity.get('businessName')?.touched)">
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['required']">El nombre es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('businessName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('businessName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
      </ng-container>
    </div>
    <div class="field field-flex" *ngIf="type === 'CREATE'">
      <label for="agreementDate">Fecha de Activación*</label>
      <p-calendar id="agreementDate" formControlName="agreementDate" [showIcon]="true" appendTo="body" placeholder="Selecciona una fecha"></p-calendar>
      <ng-container *ngIf="entity.get('agreementDate')?.invalid && (entity.get('agreementDate')?.dirty || entity.get('agreementDate')?.touched)">
        <small class="p-error" *ngIf="entity.get('agreementDate')?.errors?.['required']">La fecha de inicio es obligatoria.</small>
      </ng-container>
    </div>
    <div class="field">
      <label for="clabe">Cuenta CLABE*</label>
      <p-inputMask mask="9999 9999 9999 9999 99" id="clabe" formControlName="clabe" placeholder="Cuenta clabe"  autofocus [disabled]="type === 'UPDATE'" [minlength]="18" [required]="true"></p-inputMask>
      <ng-container *ngIf="entity.get('clabe')?.invalid && (entity.get('clabe')?.dirty || entity.get('clabe')?.touched)">
        <small class="p-error" *ngIf="entity.get('clabe')?.errors?.['required']">La cuenta clabe es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('clabe')?.errors?.['minlength']">La cuenta clabe debe tener contener {{entity.get('clabe')?.errors?.['minlength']['requiredLength']}} digitos.</small>
      </ng-container>
    </div>
    <div class="field field-flex">
      <label for="bank">Selecciona el banco*</label>
      <div class="field field-flex">
        <p-dropdown
          id="bank"
          scrollHeight="150px"
          [options]="banks"
          placeholder="Seleccione un banco"
          formControlName="bank"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="field field-flex">
      <label class="font-bold">¿Cuenta para dispersión automatica?</label>
      <div class="col-12 flex align-items-center">
        <p-inputSwitch formControlName="isLoanTransactionDefault" ></p-inputSwitch>
        <span class="ml-2">Activar cuenta para disperción de Creditos</span>
      </div>
      <div class="col-12 flex align-items-center">
        <p-inputSwitch formControlName="isSoDTransactionDefault" ></p-inputSwitch>
        <span class="ml-2">Activar cuenta para disperción de SoD</span>
      </div>
    </div>
  </form>
</ng-container>

