import {Component, Input, OnInit} from '@angular/core';
import { IAccount } from "@modules/private/bank-accounts/data/interfaces/iapi-account.interface";
import {ITransaction, ITransactionList} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface";
import {
  TRANSACTION_STATUS,
  TRANSACTION_SUB_TYPE
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";


@Component({
  selector: 'app-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: ['./balance-detail.component.scss']
})

export class BalanceDetailComponent implements OnInit {
  @Input() accountDetail!: IAccount;
  @Input() incomeData!: ITransactionList
  @Input() outcomeData!: ITransactionList
  @Input() placementData!: ITransactionList
  isLoading=true;
  pendingIncomeTotal : ITransactionList = {totalAmount:0, transactions: []}
  pendingOutcomeTotal : ITransactionList = {totalAmount:0, transactions: []}
  fundingIncomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  bankIncomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  supplierOutcomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  generalOutcomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  incomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  creditOutcomeTotal: ITransactionList = {totalAmount:0, transactions: []}
  sodOutcomeTotal: ITransactionList = {totalAmount:0, transactions: []}


  constructor() {}

  async ngOnInit() {
    this.isLoading = true
    this.fundingIncomeTotal.transactions = this.incomeData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.FUNDING_DEPOSIT && it.status==TRANSACTION_STATUS.APPLIED)
    this.fundingIncomeTotal.totalAmount = this.fundingIncomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.bankIncomeTotal.transactions = this.incomeData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.BANK_DEPOSIT && it.status==TRANSACTION_STATUS.APPLIED)
    this.bankIncomeTotal.totalAmount = this.bankIncomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.supplierOutcomeTotal.transactions = this.outcomeData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.SUPPLIER && it.status === TRANSACTION_STATUS.APPLIED )
    this.supplierOutcomeTotal.totalAmount = this.supplierOutcomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.generalOutcomeTotal.transactions = this.outcomeData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.GENERAL_EXPENSE && it.status === TRANSACTION_STATUS.APPLIED )
    this.generalOutcomeTotal.totalAmount = this.generalOutcomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.incomeTotal = {
      totalAmount:this.fundingIncomeTotal.totalAmount +  this.bankIncomeTotal.totalAmount,
      transactions:[...this.fundingIncomeTotal.transactions, ...this.bankIncomeTotal.transactions]
    }
    this.creditOutcomeTotal.transactions = this.placementData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.LOAN_ALLOCATION && it.status === TRANSACTION_STATUS.APPLIED)
    this.creditOutcomeTotal.totalAmount = this.creditOutcomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.sodOutcomeTotal.transactions = this.placementData.transactions.filter((it: ITransaction) => it.subType === TRANSACTION_SUB_TYPE.SOD && it.status === TRANSACTION_STATUS.APPLIED)
    this.sodOutcomeTotal.totalAmount = this.sodOutcomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)

    this.pendingIncomeTotal.transactions = this.incomeData.transactions.filter(
      (it: ITransaction) =>
        (it.subType === TRANSACTION_SUB_TYPE.FUNDING_DEPOSIT || it.subType === TRANSACTION_SUB_TYPE.BANK_DEPOSIT)
        && (it.status==TRANSACTION_STATUS.PENDING)
    )
    this.pendingIncomeTotal.totalAmount = this.pendingIncomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.pendingOutcomeTotal.transactions = this.outcomeData.transactions.filter(
      (it: ITransaction) =>
        (it.subType === TRANSACTION_SUB_TYPE.GENERAL_EXPENSE || it.subType === TRANSACTION_SUB_TYPE.SUPPLIER)
        && (it.status==TRANSACTION_STATUS.PENDING)
    )
    const pendingApplications= this.placementData.transactions.filter(
      (it: ITransaction) =>
        (it.subType === TRANSACTION_SUB_TYPE.APPLICATION)
        && (it.status==TRANSACTION_STATUS.PENDING)
    )
    this.pendingOutcomeTotal.totalAmount = this.pendingOutcomeTotal.transactions.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.pendingOutcomeTotal.totalAmount += pendingApplications.reduce((sum, transaction) => {return sum+transaction.amount},0)
    this.isLoading = false
  }

}


