export const MENU_AUTHORIZATION: {
  title: string,
    modules: {
    label: string;
    icon: string;
    routerLink: string;
    module: string;
    permission: string
  }[]
}[] = [
  {
    title: 'Empresas',
    modules: [
      {
        label: 'Corporativos',
        icon: 'fa-solid fa-building',
        routerLink: '/company',
        module: 'COMPANY',
        permission: 'READ_LIST'
      },
      {
        label: 'Pagadoras',
        icon: 'fa-solid fa-money-bills',
        routerLink: '/payer',
        module: 'PAYER',
        permission: 'READ_LIST'
      },
      {
        label: 'Convenios',
        icon: 'fa-solid fa-receipt',
        routerLink: '/agreement',
        module: 'AGREEMENT',
        permission: 'READ_LIST'
      },
      {
        label: 'Nominas',
        icon: 'fa-solid fa-users',
        routerLink: '/payroll',
        module: 'PAYROLL',
        permission: 'READ_LIST'
      }
    ]
  },
  {
    title: 'Tesoreria',
    modules: [
      {
        label: 'Fondeadoras',
        icon: 'fa-solid fa-wallet',
        routerLink: '/financial',
        module: 'FINANCIAL',
        permission: 'READ_LIST'
      },
      {
        label: 'Proveedores',
        icon: 'fa-solid fa-handshake',
        routerLink: '/supply',
        module: 'SUPPLIER',
        permission: 'READ_LIST'
      },
      {
        label: 'Cuentas Bancarias',
        icon: 'fa-solid fa-money-check-dollar',
        routerLink: '/bankAccount',
        module: 'BANK_ACCOUNT',
        permission: 'READ_LIST'
      }
    ]
  },
  {
    title: 'Colaboradores',
    modules: [
      {
        label: 'Listado',
        icon: 'fa-solid fa-user-tie',
        routerLink: '/worker',
        module: 'WORKER',
        permission: 'READ_LIST'
      },
      {
        label: 'Carga masiva',
        icon: 'fa-solid fa-users-gear',
        routerLink: '/worker/massive',
        module: 'WORKER',
        permission: 'MASSIVE_UPLOAD'
      }
    ]
  },
  {
    title: 'Productos',
    modules: [
      {
        label: 'SoD',
        icon: 'fa-solid fa-money-bill-transfer',
        routerLink: '/withdrawal',
        module: 'WITHDRAWAL',
        permission: 'READ_LIST'
      },
      {
        label: 'Créditos',
        icon: 'fa-solid fa-landmark',
        routerLink: '/loan',
        module: 'LOAN',
        permission: 'READ_LIST'
      },
      {
        label: 'Accesorios',
        icon: 'fa-solid fa-puzzle-piece',
        routerLink: '/accessory',
        module: 'ACCESSORY',
        permission: 'READ_LIST'
      },
      {
        label: 'Asignación masiva',
        icon: 'fa-solid fa-arrow-right-arrow-left',
        routerLink: '/assignment',
        module: 'ASSIGNMENT',
        permission: 'MASSIVE_UPDATE'
      }
    ]
  },
  {
    title: 'Cobranza',
    modules: [
      {
        label: 'Pagos',
        icon: 'fa-solid fa-file-invoice',
        routerLink: '/payment',
        module: 'PAYMENT',
        permission: 'READ_LIST'
      },
      {
        label: 'Letras',
        icon: 'fa-solid fa-file-invoice-dollar',
        routerLink: '/installment',
        module: 'INSTALLMENT',
        permission: 'READ_LIST'
      },
      {
        label: 'Condonaciones',
        icon: 'fa-solid fa-file-invoice-dollar',
        routerLink: '/writeoff',
        module: 'WRITEOFF',
        permission: 'READ_LIST'
      },
      {
        label: 'Aplicaciones',
        icon: 'fa-solid fa-layer-group',
        routerLink: '/application',
        module: 'APPLICATION',
        permission: 'READ_LIST'
      },
      {
        label: 'Aplicacion masiva',
        icon: 'fa-solid fa-cash-register',
        routerLink: '/application/apply',
        module: 'APPLICATION',
        permission: 'MASSIVE_APPLY'
      }
    ]
  },
  {
    title: 'Miscelaneos',
    modules: [
      {
        label: 'Reportes',
        icon: 'fa-solid fa-book',
        routerLink: '/reports',
        module: 'REPORT',
        permission: 'READ_LIST'
      },
      {
        label: 'Omnicanal',
        icon: 'fa-solid fa-bullhorn',
        routerLink: '/omnicanal',
        module: 'OMNICANAL',
        permission: 'READ'
      }
    ]
  },
  {
    title: 'Configuración',
    modules: [
      {
        label: 'Accesos',
        icon: 'fa-solid fa-user-lock',
        routerLink: '/admin',
        module: 'ADMIN',
        permission: 'READ_LIST'
      },
      {
        label: 'Sistema',
        icon: 'fa-solid fa-gear',
        routerLink: '/platform-settings',
        module: 'SYSTEM',
        permission: 'READ'
      }
    ]
  },
]
