import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {map} from "rxjs";
import {environment} from "@env/environment";
import {IAccount} from "@modules/private/workers/data/interfaces/iaccount.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiAccountService extends ApiClass {
  constructor() {
    super();
    this.path = 'account'
  }

  getById(id: string) {
    return this.getCollection(id, environment.apiUrl, this.path + '/worker').pipe(map((r) => r as {data: IAccount[], error: boolean; msg: string}))
  }
}
