export enum TRANSACTION_TYPE  {
  INCOME =  'INCOME',
  OUTCOME =  'OUTCOME'
}

export enum TRANSACTION_SUB_TYPE {
  APPLICATION =  'APPLICATION',
  SUPPLIER =  'SUPPLIER',
  GENERAL_EXPENSE =  'GENERAL_EXPENSE',
  LOAN_ALLOCATION =  'LOAN_ALLOCATION',
  BANK_DEPOSIT =  'BANK_DEPOSIT',
  FUNDING_DEPOSIT =  'FUNDING_DEPOSIT',
  SOD = 'SOD'
}

export enum TRANSACTION_SUB_TYPE_ESP {
  APPLICATION =  'Pago de Letra',
  SUPPLIER =  'Pago de servicio',
  GENERAL_EXPENSE =  'Gastos_Generales',
  LOAN_ALLOCATION =  'Colocación de Credito',
  BANK_DEPOSIT =  'Deposito_Bancario',
  FUNDING_DEPOSIT =  'Fondeadora',
  SOD = 'Adelanto_Pulpi'
}

export function getSubTypeEsp(type: string) {
  return TRANSACTION_SUB_TYPE_ESP[type as keyof typeof TRANSACTION_SUB_TYPE_ESP];
}

export enum TRANSACTION_STATUS  {
  APPLIED = 'APPLIED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED'
}

export enum TRANSACTION_STATUS_ESP  {
  APPLIED = 'APLICADO',
  PENDING = 'PENDIENTE',
  CANCELED = 'CANCELADO'
}
