import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IApiSupplyList, ISupplier } from '@modules/private/supply/data/interfaces/iapi-supply.interface';
import { ApiClass } from '@data/class/api-class';

@Injectable({
  providedIn: 'root'
})
export class ApiSupplyService extends ApiClass {

  constructor() {
    super();
    this.path = 'supplier';
  }

  getAll(): Observable<IApiSupplyList> {
    return this.getCollections().pipe(map((r) => r as IApiSupplyList));
  }

  createSupply(data: ISupplier): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as any));
  }

  updateSupply(data: ISupplier): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as any));
  }
}

