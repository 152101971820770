import {AfterViewInit, ChangeDetectorRef, OnInit, Component, Input, ViewChild, OnDestroy} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {
  IApiWithdrawal,
  IRecordsRequestWithdrawal
} from "@modules/private/withdrawal/data/interfaces/iwithdrawal.interface";
import {ApiWithdrawalService} from "@modules/private/withdrawal/data/services/api/api-withdrawal.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {
  TYPE_PERIOD_ENUM,
  TYPE_PERIOD_ENUM_COLOR,
  TYPE_PERIOD_ENUM_ES
} from "@modules/private/company/data/enums/typePeriods.enum";
import {LOAN_STATUS_ENUM, LOAN_STATUS_ENUM_COLOR} from "../../loan/data/enums/loan.enum";
import {PERIOD_TYPE_ENUM_COLOR} from "@modules/private/payroll/data/enums/payroll.enum";
import {WORKER_COLUMNS} from "../../workers/data/constants/workerColumns.constant";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {LoaderService} from "@data/services/page/loader.service";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {ViewSaverService} from "@data/services/page/viewSaver.service";

interface Column {
  field: string;
  header: string;
  type: string;
}

@Component({
  selector: 'app-list-withdrawal',
  templateUrl: './list-withdrawal.component.html',
  styleUrls: ['./list-withdrawal.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListWithdrawalComponent implements OnInit, AfterViewInit, OnDestroy{
  items: MenuItem[] = [];
  withdrawals!: IRecordsRequestWithdrawal[];
  totalRecords = 0
  itemsByPage = 10
  selectedWithdrawals!: IRecordsRequestWithdrawal[] | null;
  $_typePeriod = TYPE_PERIOD_ENUM
  $_typePeriodColor = PERIOD_TYPE_ENUM_COLOR
  _selectedColumns!: Column[]
  cols: (Column & { dataType?: string, showFilter?: boolean, linkField?: string, enum?: any, enumColor?: any })[] = [
    { type: 'text', field: 'folio', header: 'Folio', showFilter: true },
    { type: 'date', field: 'withdrawalDate', header: 'Fecha de dispersión', dataType: 'date', showFilter: true },
    { type: 'numeric', field: 'capital', header: 'Monto', dataType: 'currency', showFilter: true },
    { type: 'numeric', field: 'fee', header: 'Comision', dataType: 'currency', showFilter: true },
    { type: 'numeric', field: 'totalPayable', header: 'Total', dataType: 'currency',showFilter: true },
    { type: 'text',  field: 'firstName', header: 'Nombre del colaborador',showFilter: true },
    { type: 'text',  field: 'lastName', header: 'Apellido del colaborador', showFilter: true },
    { type: 'text',  field: 'email', header: 'Correo', showFilter: true },
    { type: 'text',  field: 'phone', header: 'Teléfono', showFilter: true },
    { type: 'text',  field: 'company', header: 'Nombre de empresa', showFilter: true },
    { type: 'text',  field: 'period', header: 'Tipo de periodo', dataType: 'enum', enum: this.$_typePeriod, enumColor: this.$_typePeriodColor, showFilter: true },
    { type: 'text',  field: 'remunerator', header: 'Pagadora', showFilter: true },
    { type: 'text',  field: 'contract', header: 'Contrato', showFilter: true },
    { type: 'text',  field: 'payroll', header: 'Nomina', showFilter: true },
    { type: 'text',  field: 'withdrawalType', header: 'Tipo de Retiro', showFilter: true },
    //{ type: 'date', field: 'cutOffDate', header: 'Fecha de corte', dataType: 'date' },
    { type: 'date', field: 'dueDate', header: 'Fecha esperada de pago', dataType: 'date', showFilter: true },
    { type: 'numeric', field: 'feePaid', header: 'Comisión pagada', dataType: 'currency', showFilter: true },
    { type: 'date', field: 'dateFeePaid', header: 'Fecha de comisión pagada', dataType: 'date', showFilter: true },
    { type: 'date', field: 'dateFeeApplicationPaid', header: 'Fecha de aplicación de comisión pagada', dataType: 'date', showFilter: true },
    { type: 'numeric', field: 'capitalPaid', header: 'Capital pagado', dataType: 'currency', showFilter: true },
    { type: 'date', field: 'dateCapitalPaid', header: 'Fecha de capital pagado', dataType: 'date', showFilter: true },
    { type: 'date', field: 'dateCapitalApplicationPaid', header: 'Fecha de aplicación de capital pagado', dataType: 'date', showFilter: true },
    { type: 'numeric', field: 'subtotalPaid', header: 'Subtotal Pagado', dataType: 'currency', showFilter: true },
    { type: 'numeric', field: 'totalPaid', header: 'Total Pagado', dataType: 'currency', showFilter: true },
    { type: 'numeric', field: 'pendingRecovery', header: 'Monto pendiente de recuperar', dataType: 'currency', showFilter: true },
    //{ type: 'date', field: 'dateComplementPayment', header: 'Fecha de pago de complemento', dataType: 'date' },
    { type: 'text',  field: 'status', header: 'Estatus de dispersion', showFilter: true },
    { type: 'text',  field: 'statusPaid', header: 'Estatus de pago', showFilter: true },
    { type: 'numeric',  field: 'amountComplement1', header: 'Monto de complemento 1', dataType: 'currency', showFilter: true },
    { type: 'numeric',  field: 'amountComplementInterest1', header: 'Monto de interes de complemento 1', dataType: 'currency',showFilter: true },
    { type: 'numeric',  field: 'amountComplementCapital1', header: 'Monto de capital de complemento 1', dataType: 'currency'  ,showFilter: true},
    { type: 'date',  field: 'applicationDateComplement1', header: 'Fecha de aplicacion de complemento 1', showFilter: true,  dataType: 'date'},
    { type: 'date',  field: 'dateComplement1', header: 'Fecha de complemento 1', showFilter: true,  dataType: 'date'},
    { type: 'numeric',  field: 'amountComplement2', header: 'Monto de complemento 2', dataType: 'currency', showFilter: true },
    { type: 'numeric',  field: 'amountComplementInterest2', header: 'Monto de interes de complemento 2', dataType: 'currency' , showFilter: true},
    { type: 'numeric',  field: 'amountComplementCapital2', header: 'Monto de capital de complemento 2', dataType: 'currency' , showFilter: true},
    { type: 'date',  field: 'applicationDateComplement2', header: 'Fecha de aplicacion de complemento 2', showFilter: true,  dataType: 'date'},
    { type: 'date',  field: 'dateComplement2', header: 'Fecha de complemento 2', showFilter: true,  dataType: 'date' },
    { type: 'numeric',  field: 'amountComplement3', header: 'Monto de complemento 3', dataType: 'currency', showFilter: true },
    { type: 'numeric',  field: 'amountComplementInterest3', header: 'Monto de interes de complemento 3', dataType: 'currency' , showFilter: true},
    { type: 'numeric',  field: 'amountComplementCapital3', header: 'Monto de capital de complemento 3', dataType: 'currency' , showFilter: true},
    { type: 'date',  field: 'applicationDateComplement3', header: 'Fecha de aplicacion de complemento 3', showFilter: true,  dataType: 'date'},
    { type: 'date',  field: 'dateComplement3', header: 'Fecha de complemento 3', showFilter: true,  dataType: 'date' },
    { type: 'numeric',  field: 'amountComplement4', header: 'Monto de complemento 4', dataType: 'currency', showFilter: true },
    { type: 'numeric',  field: 'amountComplementInterest4', header: 'Monto de interes de complemento 4', dataType: 'currency', showFilter: true },
    { type: 'numeric',  field: 'amountComplementCapital4', header: 'Monto de capital de complemento 4', dataType: 'currency' , showFilter: true},
    { type: 'date',  field: 'applicationDateComplement4', header: 'Fecha de aplicacion de complemento 4', showFilter: true,  dataType: 'date'},
    { type: 'date',  field: 'dateComplement4', header: 'Fecha de complemento 4', showFilter: true,  dataType: 'date' },

  ]
  colsPdf: any[] = [
    {dataKey: 'name', title: 'Nombre de nómina'},
    {dataKey: 'companyName', title: 'Nombre del corporativo'},
    {dataKey: 'contractName', title: 'Nombre del convenio'},
    {dataKey: 'payerName', title: 'Nombre de la pagadora'},
    {dataKey: 'periodType', title: 'Tipo de periodo'},
    {dataKey: 'loanCreationFeeRate', title: 'Comisión por apertura'},
    {dataKey: 'loanRate', title: 'Tasa de interés anual'},
    {dataKey: 'withdrawableSalaryRatio', title: 'Porcentaje disponible del salario'},
    {dataKey: 'withdrawalFee', title: 'Comisión del retiro'},
    {dataKey: 'loansEnabled', title: 'Estatus de créditos'},
    {dataKey: 'withdrawalsEnabled', title: 'Estatus de retiros'},
    {dataKey: 'creationDate', title: 'Fecha de creación'},
    {dataKey: 'hubspotId', title: 'HubspotId'},
  ]
  loading: boolean = true;
  periods = [
    {label: 'Mensual', value: 'Mensual'},
    {label: 'Quincenal', value: 'Quincenal'},
    {label: 'Catorcenal', value: 'Catorcenal'},
    {label: 'Semanal', value: 'Semanal'}
  ]
  status = [
    {label: 'Activo', value: 'Activo'},
    {label: 'Pendiente de activación', value: 'Pendiente de activación'},
    {label: 'Desactivado', value: 'Desactivado'}
  ]
  genders = [
    {label: 'Masculino', value: 'Masculino'},
    {label: 'Femenino', value: 'Femenino'},
  ]
  @ViewChild('dtSoD') dtSoD!: Table;
  tableKey = 'sodTable';
  filters!: { [key: string]: any; };
  constructor(
    private apiWithdrawalService: ApiWithdrawalService,
    public dataTableService: DataTableService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    public authService: AuthService,
    private tableFilterService: ViewSaverService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.createMenu()
    this.selectedColumns = this.cols
  }

  @Input() get selectedColumns(): any[] {
   return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  ngAfterViewInit() {
    const savedData =  this.tableFilterService.getFilters(this.tableKey)
    console.log(savedData, "savedData")
    if (savedData && !savedData.search) {
      this.itemsByPage = this.itemsByPage
      this.withdrawals = savedData.data
      this.dtSoD.filters = savedData.filters
      this.totalRecords = savedData.totalRecords
      this.dtSoD.first = savedData.page * this.itemsByPage
    }
    this.tableFilterService.changeSearchStatus(this.tableKey, true)
  }

  ngOnInit() {
    /*this.find(
      this.tableFilterService.getFilters(this.tableKey)?.page,
      this.tableFilterService.getFilters(this.tableKey)?.limit,
      this.tableFilterService.getFilters(this.tableKey)?.filters,
    )*/
  }

  find(page = 0, limit = this.itemsByPage, filters = {}) {
    this.loading = true;
    let downFilters = {}
    console.log("finding", page)
    this.apiWithdrawalService.getAll(page, limit, filters).subscribe(r => {
      this.totalRecords = r.totalItems
      this.withdrawals = r.data
      this.loading = false
      if (this.dtSoD) this.tableFilterService.updateData(this.tableKey, this.withdrawals, this.totalRecords);
    })
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadWithdrawals(e: any) {
    const savedData =  this.tableFilterService.getFilters(this.tableKey)
    if (!(savedData && !savedData.search)) {
      const currentPage = (e.first / this.itemsByPage) // e.first / this.itemsByPage
      this.itemsByPage = e.rows
      this.totalRecords = e.totalRecords
      const finalFilters = {...e.filters}
      if (e.filters.global) finalFilters['folio'] = e.filters.global
      this.find(currentPage, this.itemsByPage, finalFilters)
      if(Object.keys(finalFilters).length <= 0) {
        setTimeout(() => {
          this.tableFilterService.saveFilters(this.tableKey, this.dtSoD.filters, currentPage, this.itemsByPage, this.withdrawals, true, this.totalRecords);
        }, 1000)
      } else {
        this.tableFilterService.saveFilters(this.tableKey, finalFilters, currentPage, this.itemsByPage, this.withdrawals, true, this.totalRecords);
      }

    } else {
      this.loading = false
    }

  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiWithdrawalService.getAll(0, 100000000, this.dtSoD.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        {dataKey: 'rfc', title: 'RFC'},
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiWithdrawalService.getAll(0, 100000000, this.dtSoD.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)
      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {RFC: i.RFC}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000});
    })
  }

  convertData(d: IRecordsRequestWithdrawal[]) {
    return d.map(i => {
      return {
        ...i,
        withdrawalDate: this.datePipe.transform(i.withdrawalDate, 'dd-MM-YYYY mm:hh:ss'),
        capital: this.currencyPipe.transform(i.capital),
        fee: this.currencyPipe.transform(i.fee),
        totalPayable: this.currencyPipe.transform(i.totalPayable),
        endDate: this.datePipe.transform(i.endDate, 'dd-MM-YYYY'),
        period: this.selectEnumPipe.transform(i.period, TYPE_PERIOD_ENUM),
        subtotalPaid: this.currencyPipe.transform(i.subtotalPaid),
        capitalPaid: this.currencyPipe.transform(i.capitalPaid),
        feePaid: this.currencyPipe.transform(i.feePaid),
        dateFeePaid: this.datePipe.transform(i.dateFeePaid, 'dd-MM-YYYY'),
        dateFeeApplicationPaid: this.datePipe.transform(i.dateFeeApplicationPaid, 'dd-MM-YYYY'),
        dateCapitalPaid: this.datePipe.transform(i.dateCapitalPaid, 'dd-MM-YYYY'),
        dateCapitalApplicationPaid: this.datePipe.transform(i.dateCapitalApplicationPaid, 'dd-MM-YYYY'),
        dueDate: this.datePipe.transform(i.dueDate, 'dd-MM-YYYY'),
        totalPaid: this.currencyPipe.transform(i.totalPaid),
        pendingRecovery: this.currencyPipe.transform(i.pendingRecovery),
        withdrawalPaid: this.currencyPipe.transform(i.withdrawalPaid),
        amountComplement1: this.currencyPipe.transform(i.amountComplement1),
        amountComplementInterest1: this.currencyPipe.transform(i.amountComplementInterest1),
        amountComplementCapital1: this.currencyPipe.transform(i.amountComplementCapital1),
        applicationDateComplement1: this.datePipe.transform(i.applicationDateComplement1, 'dd-MM-YYYY'),
        dateComplement1: this.datePipe.transform(i.dateComplement1, 'dd-MM-YYYY'),
        amountComplement2: this.currencyPipe.transform(i.amountComplement2),
        amountComplementInterest2: this.currencyPipe.transform(i.amountComplementInterest2),
        amountComplementCapital2: this.currencyPipe.transform(i.amountComplementCapital2),
        applicationDateComplement2: this.datePipe.transform(i.applicationDateComplement2, 'dd-MM-YYYY'),
        dateComplement2: this.datePipe.transform(i.dateComplement2, 'dd-MM-YYYY'),
        amountComplement3: this.currencyPipe.transform(i.amountComplement3),
        amountComplementInterest3: this.currencyPipe.transform(i.amountComplementInterest3),
        amountComplementCapital3: this.currencyPipe.transform(i.amountComplementCapital3),
        applicationDateComplement3: this.datePipe.transform(i.applicationDateComplement3, 'dd-MM-YYYY'),
        dateComplement3: this.datePipe.transform(i.dateComplement3, 'dd-MM-YYYY'),
        amountComplement4: this.currencyPipe.transform(i.amountComplement4),
        amountComplementInterest4: this.currencyPipe.transform(i.amountComplementInterest4),
        amountComplementCapital4: this.currencyPipe.transform(i.amountComplementCapital4),
        applicationDateComplement4: this.datePipe.transform(i.applicationDateComplement4, 'dd-MM-YYYY'),
        dateComplement4: this.datePipe.transform(i.dateComplement4, 'dd-MM-YYYY'),
      }
    })
  }


  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

  confirmStatusChange(event: Event, id: string) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: '¿Estas seguro de cancelar el retiro?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true
        this.apiWithdrawalService.cancel(id).subscribe(r => {
          this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
          this.loading = false
          if (!r.error) {
            this.withdrawals.map(w => {
              if (w._id === id) {
                w.status = 'Fallido'
              }
            })
          }
        })
      }
    });
  }

  protected readonly LOAN_STATUS_ENUM_COLOR = LOAN_STATUS_ENUM_COLOR;
  protected readonly LOAN_STATUS_ENUM = LOAN_STATUS_ENUM;
  protected readonly WORKER_COLUMNS = WORKER_COLUMNS;

  createMenu() {
    if (this.authService.userHasAuthorization('WITHDRAWAL','EXPORT')) {
      this.items.push(
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'Excel',
              command: () => {
                this.exportExcel()
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.exportPdf()
              }
            }
          ]
        }
      )
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtSoD.clear()
          this.find()
        }
      }
    ]
  }

  ngOnDestroy() {
    if (this.dtSoD) this.tableFilterService.changeSearchStatus(this.tableKey, false)

  }
}
