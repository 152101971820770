import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Table} from "primeng/table";
import {ITableCol} from "@data/interfaces/itable.interface";
import {LoaderService} from "@data/services/page/loader.service";
import {filter, Observable} from "rxjs";
import {DataTableService} from "@data/services/utils/data-table.service";
import {LOAN_COLUMNS} from "@modules/private/loan/data/columns/loanColumns.constant";
import {ViewSaverService} from "@data/services/page/viewSaver.service";
@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class MainTableComponent implements OnInit, AfterViewInit{
  menu!: MenuItem[];
  @ViewChild('dtMainTable') dtMainTable!: Table;
  entities!: any[]
  filters = {}
  @Input() totalRecords = 0
  @Input() itemsByPage = 10
  loadingTable = true
  selectedEntities!: any[] | null;
  _selectedColumns!: ITableCol[]
  @Input() additionalFilters = {}
  @Input() columns: ITableCol[] = []
  @Input() apiService!: {
    getAll(page: number, limit: number, filters: any, tableIdentifier: any): Observable<any>
  }
  @Input() actions: {icon: string, color: string, action: string,  title: string, showConfirm?: boolean, confirmMessage?: string, rule?: {field:  string; value: any, type: string}}[] = []
  @Output() onAction = new EventEmitter()
  @Output() onMainAction = new EventEmitter()
  @Input() singleEntityName = ''
  @Input() pluralEntityName = ''
  @Input() additionalMainActions: {label: string, icon: string, action: string;}[] = []
  @Input() searchInServer = true
  @Input() concatRFCToExports = true
  @Input() tableIdentifier = 'table'
  constructor(
    public dataTableService: DataTableService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
  ) {

  }

  ngOnInit() {
    console.log(this.tableIdentifier,this.additionalFilters)

    this.selectedColumns = this.columns
    this.constructItems()
    if (!this.searchInServer) this.findRecords()
  }

  ngAfterViewInit() {
    this.dtMainTable.filters = {...this.dtMainTable.filters, ...this.additionalFilters};
  }

  constructItems() {
    this.menu = [
      {
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'Excel',
            command: () => {
              this.exportExcel()
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.exportPdf()
            }
          },
        ]
      },
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.findRecords()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtMainTable.clear()
        }
      },
    ]
    for (const additionalMainAction of this.additionalMainActions) {
      this.menu.push({
        label: additionalMainAction.label,
        icon: additionalMainAction.icon,
        command: () => {
          this.onMainAction.emit(additionalMainAction.action)
        }
      })
    }
  }

  findRecords(
    page = 0,
    limit = this.itemsByPage,
    filters = {}
  ) {
    this.loadingTable = true
    this.apiService.getAll(page, limit, {...filters, ...this.additionalFilters}, this.tableIdentifier).subscribe(r => {
      this.totalRecords = r.totalItems ? r.totalItems : r.data.length
      this.entities = r.data
      this.loadingTable = false
    })
  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiService.getAll(0, 100000000, {...this.dtMainTable.filters, ...this.additionalFilters}, this.tableIdentifier).subscribe(r => {
      const data = []
      if (this.concatRFCToExports) data.push({dataKey: 'rfc', title: 'RFC'})
      this.dataTableService.exportPdf([
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
        .then(pdfResponse => {
          this.loaderService.hideSpinner()
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Descarga exitosa', life: 5000 });
        })
        .catch(e => {
          this.loaderService.hideSpinner()
          this.messageService.add({ severity: 'danger', summary: 'Error', detail: 'Error al descargar el archivo', life: 5000 });
        })
    })
  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiService.getAll(0, 100000000, {...this.dtMainTable.filters, ...this.additionalFilters}, this.tableIdentifier).subscribe(r => {
      const dataConverted = this.convertData(r.data)
      this.dataTableService.exportExcel(dataConverted.map((i: any) => {
        const response: any = {}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Descarga exitosa', life: 5000});
    })
  }

  convertData(data: any) {
    return data.map((i: any) => {
      const response = {
        ...i
      }
      for (const item of this.columns) {
        if (item.dataType === 'date') {
          response[item.field]  = this.datePipe.transform(i[item.field], 'dd-MM-YYYY')
        } else if (item.dataType === 'enum') {
          response[item.field] = this.selectEnumPipe.transform(i[item.field], item.enum)
        } else if (item.dataType === 'currency') {
          response[item.field] = this.currencyPipe.transform(i[item.field])
        }
      }
      return response
    })
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }

  clear(table: Table) {
    table.clear();
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadEntities(e: any) {
    const currentPage = e.first / this.itemsByPage
    this.itemsByPage = e.rows
    this.filters = e.filters
    this.findRecords(currentPage, this.itemsByPage, e.filters)
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') value = obj[keysArray[key]]
      else value = value[keysArray[key]]
    }
    return value
  }

  roundNumber(n: number) {
    return Math.round(n)
  }

  showAction(data: any, rule: {field:  string; value: any, type: string} | undefined) {
    if (rule === undefined) return true
    else if (rule.type === 'equals' && data[rule.field] === rule.value) return true
    else if (rule.type === 'notEquals' && data[rule.field] !== rule.value) return true
    else if (rule.type === 'gte' && data[rule.field] >= rule.value ) return true
    return false
  }

  confirmAction(event: Event, confirmMessage: any, data: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: confirmMessage ? confirmMessage : '¿Estas seguro que deseas continuar?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.onAction.emit(data)
      }
    });
  }
  protected readonly LOAN_COLUMNS = LOAN_COLUMNS;
  @Output() onLazyLoad = new EventEmitter<unknown>();
  @Input() lazy!: boolean;
}
