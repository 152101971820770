<p-table
  [paginator]="true"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 20, 50]"
  currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} reportes"
  [value]="attempts" [tableStyle]="{ 'min-width': '100%' }">
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="total">
          Registro totales
          <p-columnFilter type="number" field="total" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="total"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="success">
          Exitosos
          <p-columnFilter type="number" field="success" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="success"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="totalErrors">
          Errores
          <p-columnFilter type="number" field="totalErrors" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="totalErrors"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="status">
          Estatus
          <p-columnFilter type="text" field="status" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="status"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="urlReport">
          Url del reporte
          <p-columnFilter type="text" field="urlReport" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="urlReport"></p-sortIcon>
        </div>
      </th>
      <th style="min-width:15rem">
        <div class="flex align-items-center" pSortableColumn="creationDate">
          Creacion de reporte
          <p-columnFilter type="date" field="creationDate" display="menu" class="mt-1"></p-columnFilter>
          <p-sortIcon field="creationDate"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-attempt>
    <tr>
      <td>{{ attempt.total }}</td>
      <td>{{ attempt.success }}</td>
      <td>{{ attempt.totalErrors}}</td>
      <td>{{ attempt.status | selectEnum: $_applicationAttempts}}</td>
      <td><a [href]="attempt.urlReport" target="_blank">Abrir Url</a></td>
      <td>{{ attempt.creationDate | date: 'dd-MM-YYYY hh:mm:ss' }}</td>
    </tr>
  </ng-template>
</p-table>
