<p-card>
  <div class="grid">
    <div class="col-12">
      <h1 class="mb-3 tooltip-title">Aplicaciones de pago <span (click)="showDialog()" class="button-circle"><i class="fa-solid fa-question"></i></span></h1>
      <p class="mb-4">Este apartado permite aplicar pagos a los distintos productos financieros gestionados en la plataforma, se sugiere subir archivos de maximo 500 pagos.</p>
      <p-toast></p-toast>
    </div>
    <div class="col-12 xl:col-4">
      <div class="field field-flex">
        <label for="typeProduct">Tipo de producto al que se le aplicaran pagos:</label>
        <p-dropdown id="typeProduct" [(ngModel)]="typeProduct" [panelStyle]="{'width': '100%' }" [options]="typeProductOptions" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
      </div>
    </div>
    <ng-container *ngIf="showSkipPaymentsAlreadyApplied">
      <div class="col-12 xl:col-4 flex flex-column">
        <label for="skipPaymentAlreadyApplied" class="mb-3">Permitir pagos ya aplicados:</label>
        <p-inputSwitch id="skipPaymentAlreadyApplied" [(ngModel)]="skipPaymentAlreadyApplied"></p-inputSwitch>
      </div>
    </ng-container>
    <ng-container *ngIf="showSkipOverPaid">
      <div class="col-12 xl:col-4 flex flex-column">
        <label for="skipOverpaidProduct" class="mb-3">Permitir sobrepagar productos:</label>
        <p-inputSwitch id="skipOverpaidProduct" [(ngModel)]="skipOverpaidProduct"></p-inputSwitch>
      </div>
    </ng-container>
    <div class="col-12">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
      <ng-container *ngIf="!isUploading; else elseLoading">
        <p-fileUpload
          [maxFileSize]="1024"
          invalidFileSizeMessageSummary="Error en el tamaño del archivo."
          invalidFileSizeMessageDetail="El archivo no debe ser mayor a 1Mb"
          chooseLabel="Seleccionar documento"
          cancelLabel="Cancelar"
          uploadLabel="Subir"
          name="uploadedFiles[]"
          [multiple]="false"
          accept=".csv"
          [maxFileSize]="1000000"
          (uploadHandler)="onUpload($event)"
          [customUpload]="true"
        >
        </p-fileUpload>
      </ng-container>
      <ng-template #elseLoading>
        <div class="loading-application-container w-100 text-center">
          <p class="mb-2">Realizando aplicacion de pagos...</p>
          <p-progressSpinner></p-progressSpinner>
        </div>
      </ng-template>
    </div>
    <div class="col-12">
      <app-application-attempts></app-application-attempts>
    </div>
  </div>
</p-card>
<p-dialog header="Header" size="small" [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <p-avatar image="assets/media/images/avatars/ia-support.jpg" shape="circle" />
      <span class="font-bold white-space-nowrap">Soporte técnico</span>
    </div>
  </ng-template>
  <div class="grid">
    <div class="col-12 p-4">
      <p class="mb-3">El sistema permite realizar aplicaciones de creditos y retiros, pero debe realizarse la aplicacion de forma independientes, es decir, se debe seleccionar el producto al cual se le aplicaran los pagos. El sistema cuenta con 4 capas de validacion que permite validar la informacion antes de cargarse en el sistema y solo la ultima realiza la aplicacion de pagos, a continuacion se describe cada capa:</p>
      <ul>
        <li><strong>Validacion de formato:</strong> La primer capa permite validar el formato de montos, fechas, folios,etc. en este punto no se sube nada al sistema, solo se valida que cada campo cumpla con las caracteristicas esperadas por la aplicacion.</li>
        <li><strong>Validacion de pagos ya aplicados:</strong> Esta es la segunda capa, donde en caso que dentro del archivo se encuentren pagos duplicados o se encuentren en base de datos nos mandara una advertencia, la cual se puede saltar haciendo click en el campo "Permitir pagos ya aplicados", esto solo si se esta seguro que el pago debe volverse a aplicar o solo es una coincidencia, un pago duplicado se determina por la fecha, monto, clave de rastreo o clabe receptora.</li>
        <li><strong>Validacion de productos sobrepagados:</strong> Esta es la tercer capa la cual valida que no exista un producto ya pagado o que con el monto a aplicar quede sobrepagado, por lo que el sistema mandara una advertencia y en caso de estar seguro de ignorar esta alerta, basta con darle check al campo "Permitir sobrepagar productos."</li>
        <li><strong>Validacion de base de datos:</strong> Esta es la cuarta y ultima etapa donde al pasar las etapas anteriores se da por hecho que el archivo cumple con todo lo necesario para aplicarse, por lo que el sistema empezara a cargar la informacion y en caso de haber un error nos regresara un error de base de datos o un error respecto a la aplicacion.</li>
      </ul>
      <div>
        <a class="link-download mt-1" href="assets/documents/aplicacion_pagos.csv" target="_blank">Descargar Formato</a>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="visible = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
  </ng-template>
</p-dialog>
