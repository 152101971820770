import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IFinancial} from '@modules/private/financial/data/interfaces/iapi-financial.interface';
import {IApiBankAccountList, IBankAccount} from '@modules/private/financial/data/interfaces/iapi-bankAccount.interface';
import {ApiBankAccountService} from "@modules/private/bank-accounts/data/services/api/api-bank-account.service";

@Component({
  selector: 'app-disperse-balance',
  templateUrl: './disperse-balance.component.html',
  styleUrls: ['./disperse-balance.component.scss']
})

export class DisperseBalanceComponent implements OnInit {
  bankAccounts: IApiBankAccountList | undefined;
  @Input() financialDetail!: IFinancial;
  @Input() bankAccountDetail!: IApiBankAccountList;
  @Input() bankAccount!: IBankAccount;
  @Output() balanceUpdated = new EventEmitter<any>();
  disperseForm: FormGroup;
  showModal: boolean = false;

  constructor(private fb: FormBuilder,
              private apiBankAccountService: ApiBankAccountService
  ) {
    this.disperseForm = this.fb.group({
      balance: ['', Validators.required],
      account: ['', Validators.required],
      amount: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  ngOnInit(): void {
    this.apiBankAccountService.getAll().subscribe({
      next: (response: any) => {
        this.bankAccounts = response;
      },
      error: (err) => {
        console.error('Error fetching bank accounts:', err);
      }
    });
  }

  showModalDialog() {
    this.showModal = true;
  }

  hideModalDialog() {
    this.showModal = false;
  }

  onSubmit() {
    const dataToSave = {
      originAccount: this.financialDetail._id+':'+this.disperseForm.value.balance._id,
      targetAccount: this.disperseForm.value.account._id,
      amount: this.disperseForm.value.amount,
      type: 'INCOME',
      subType: 'FUNDING_DEPOSIT',
      description: this.disperseForm.value.description
    }
    this.balanceUpdated.emit(dataToSave)
    this.showModal = false;
  }
}
