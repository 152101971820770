<script src="../../withdrawal/list-withdrawal/list-withdrawal.component.ts"></script>
<p-card>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-3">Omnicanal</h1>
      <p class="mb-3 text-color-secondary">En este apartado encontraras todos las solicitudes que entran a la plataforma, tanto nuevos registros de usuario, así como solicitudes de crédito.  </p>
    </div>
    <p-toast></p-toast>
  </div>
  <div class="row">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <ng-container *ngIf="authService.userHasAuthorization('OMNICANAL','READ_LIST_MAS_NOMINA');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-building"></i>
              <span class="ml-2">Más Nómina</span>
            </ng-template>
            <app-main-table
              [apiService]="pushingService"
              [columns]="columns"
              [pluralEntityName]="'solicitudes'"
              [additionalFilters]="{flowProvider: {value: 'MAS_NOMINA',matchMode: 'equals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}"
              [actions]="actionsMasNomina"
              (onAction)="actionMethods($event)"
              (onMainAction)="mainActions($event)"
              [additionalMainActions]="additionalMainActions"
            ></app-main-table>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('OMNICANAL','READ_LIST_PULPI');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-landmark-dome"></i>
              <span class="ml-2">Pulpi</span>
            </ng-template>
            <app-main-table
              #dtPulpi
              [tableIdentifier]="'pulpi'"
              [apiService]="pushingProxyService"
              [columns]="columns"
              [pluralEntityName]="'solicitudes'"
              [additionalFilters]="pushingProxyService.getFilters('pulpi')"
              [actions]="actionsPulpi"
              (onAction)="actionMethods($event)"
              (onMainAction)="mainActions($event)"
              [additionalMainActions]="additionalMainActions"
            ></app-main-table>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('OMNICANAL','READ_LIST_PULPI');">

          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-landmark-dome"></i>
              <span class="ml-2">Presta Facil</span>
            </ng-template>
            <p-tabView styleClass="tabview-custom">
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: BJX LOGISTICA Y TRANSPORTE (CATORCENAL)	</span>
                </ng-template>
                <app-main-table
                  #dtPrestaBJX
                  [tableIdentifier]="'prestabjx'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]="pushingProxyService.getFilters('prestabjx')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: PREPOTO (CATORCENAL)		</span>
                </ng-template>
                <app-main-table
                  #dtPrestaPrepoto
                  [tableIdentifier]="'prestaprepoto'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]= "pushingProxyService.getFilters('prestaprepoto')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: OCHER COMERCIAL (CATORCENAL)	</span>
                </ng-template>
                <app-main-table
                  #dtPrestaOcher
                  [tableIdentifier]="'prestaocher'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]="pushingProxyService.getFilters('prestaocher')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: GOVENDER COMERCIAL (CATORCENAL)	</span>
                </ng-template>
                <app-main-table
                  #dtPrestaGovender
                  [tableIdentifier]="'prestagovender'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]="pushingProxyService.getFilters('prestagovender')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: MICHOPRE (CATORCENAL)	</span>
                </ng-template>
                <app-main-table
                  #dtPrestaMichopre
                  [tableIdentifier]="'prestamichopre'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]="pushingProxyService.getFilters('prestamichopre')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <i class="fa-solid fa-building"></i>
                  <span class="ml-2">PRESTA FACIL: QUERESERV (CATORCENAL)		</span>
                </ng-template>
                <app-main-table
                  #dtPrestaQuereserv
                  [tableIdentifier]="'prestaquereserv'"
                  [apiService]="pushingProxyService"
                  [columns]="columns"
                  [pluralEntityName]="'solicitudes'"
                  [additionalFilters]="pushingProxyService.getFilters('prestamichopre')"
                  [actions]="actionsPulpi"
                  (onAction)="actionMethods($event)"
                  (onMainAction)="mainActions($event)"
                  [additionalMainActions]="additionalMainActions"
                ></app-main-table>
              </p-tabPanel>
            </p-tabView>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('OMNICANAL','READ_LIST_UNKNOWN');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-circle-question"></i>
              <span class="ml-2">Desconocido</span>
            </ng-template>
            <app-main-table
              #dtDesconocido
              [tableIdentifier]="'desconocido'"
              [apiService]="pushingProxyService"
              [columns]="columns"
              [pluralEntityName]="'solicitudes'"
              [additionalFilters]="pushingProxyService.getFilters('desconocido')"
              [actions]="actionsUnknown"
              (onAction)="actionMethods($event)"
              (onMainAction)="mainActions($event)"
              [additionalMainActions]="additionalMainActions"
            ></app-main-table>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('OMNICANAL','READ_LIST_HISTORY');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-receipt"></i>
              <span class="ml-2">Historico</span>
            </ng-template>
            <app-main-table
              #dtHistorico
              [tableIdentifier]="'historic'"
              [apiService]="pushingProxyService"
              [additionalFilters]="pushingProxyService.getFilters('historic')"
              [columns]="columns"
              [pluralEntityName]="'solicitudes'"
              (onAction)="actionMethods($event)"
              (onMainAction)="mainActions($event)"
              [additionalMainActions]="additionalMainActions"
            ></app-main-table>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </div>
  </div>
</p-card>
<p-dialog [(visible)]="createEntity" [style]="{ width: '750px' }" [header]="titleFormHeader" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <ng-container [ngSwitch]="typeAction">
      <ng-container *ngSwitchCase="'UPDATE_PUSHING'">
        <app-update-flow-pushing [entity]="entity" [submitted]="submitted"></app-update-flow-pushing>
      </ng-container>
      <ng-container *ngSwitchCase="'CREATE_PUSHING'">
        <app-pushing-new-process [entity]="entity" [submitted]="submitted"></app-pushing-new-process>
      </ng-container>
      <ng-container *ngSwitchCase="'CALCULATE_NOMIPAY'">
        <app-pushing-provider-calculator typeForm="RFC_FORM" [calculateOffer]="true"></app-pushing-provider-calculator>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <ng-container [ngSwitch]="typeAction">
      <ng-container *ngSwitchCase="'UPDATE_PUSHING'">
        <button pButton pRipple label="Actualizar" icon="pi pi-check" class="p-button-text" (click)="savePushing()" [disabled]="!entity.valid"></button>
      </ng-container>
      <ng-container *ngSwitchCase="'CREATE_PUSHING'">
        <button pButton pRipple label="Crear" icon="pi pi-check" class="p-button-text" (click)="createPushing()" [disabled]="!entity.valid"></button>
      </ng-container>
    </ng-container>

  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

