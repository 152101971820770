import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-supply',
  templateUrl: './create-supply.component.html',
  styleUrls: ['./create-supply.component.scss']
})
export class CreateSupplyComponent implements OnInit {
  @Input() entity!: FormGroup;
  @Input() submitted!: boolean;
  @Input() type: 'CREATE' | 'UPDATE' = 'CREATE';

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    if (!this.entity) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.entity = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      businessName: ['', [Validators.required, Validators.minLength(3)]],
      address: [''],
      market: ['', Validators.required],
      subMarket: ['', Validators.required],
      createdDate: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.entity.valid) {
      this.entity.getRawValue();
    }
  }
}
