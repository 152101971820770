import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ADDRESS_STATES} from "@data/constants/address-states.const";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {MessageService} from "primeng/api";
import {AuthService} from "@data/services/modules/auth/auth.service";

@Component({
  selector: 'app-loan-record',
  templateUrl: './loan-record.component.html',
  styleUrls: ['./loan-record.component.scss'],
  providers: [MessageService]
})
export class LoanRecordComponent implements OnInit{
  @Input() data!: ILoanPopulated
  entity!: FormGroup
  isUpdating = false
  typeIdentityDocuments = [
    {label: 'Selecciona un tipo de documento', value: ''},
    {label: 'Pasaporte', value: 'PASSPORT'},
    {label: 'INE', value: 'INE'},
    {label: 'VISA', value: 'VISA'}
  ]
  states = ADDRESS_STATES
  destinationLoanOptions = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Gastos familiares e imprevistos', value: 'FAMILY_AND_UNFORESEEN_EXPENSES'},
    {label: 'Vacaciones', value: 'HOLIDAY'},
    {label: 'Utiles', value: 'HELPFUL'},
    {label: 'Pagar deuda', value: 'PAY_DEBT'},
    {label: 'Regalos', value: 'GIFTS'},
    {label: 'Comprar auto', value: 'BUY_CAR'},
    {label: 'Compar casa', value: 'BUY_HOUSE'},
    {label: 'Otro', value: 'OTHER'},
  ]
  arrangementMethods = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Transferencia', value: 'TRANSFER'},
    {label: 'Nómina', value: 'PAYROLL_ACCOUNT'},
    {label: 'Cuenta bancaria', value: 'CARD'},
  ]
  personalRelations = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Padre', value: 'FATHER'},
    {label: 'Madre', value: 'MOTHER'},
    {label: 'Hermano(a)', value: 'BROTHER'},
    {label: 'Abuelo(a)', value: 'GRANDPA'},
    {label: 'Tío(a)', value: 'UNCLE'},
    {label: 'Sobrino(a)', value: 'COUSIN'},
    {label: 'Otro(a)', value: 'OTHER'},
  ]
  workRelations = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Jefe(a)', value: 'BOSS'},
    {label: 'Compañero(a)', value: 'FELLOW'},
    {label: 'Ex-Compañero(a)', value: 'EX-FELLOW'},
    {label: 'Director(a)', value: 'DIRECTOR'},
    {label: 'Gerente(a)', value: 'MANAGER'},
    {label: 'Otro(a)', value: 'OTHER'},
  ]
  avalRelations = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Padre', value: 'FATHER'},
    {label: 'Madre', value: 'MOTHER'},
    {label: 'Amigo', value: 'FRIEND'},
    {label: 'Hermano(a)', value: 'BROTHER'},
    {label: 'Abuelo(a)', value: 'GRANDPA'},
    {label: 'Tío(a)', value: 'UNCLE'},
    {label: 'Sobrino(a)', value: 'COUSIN'},
    {label: 'Jefe(a)', value: 'BOSS'},
    {label: 'Compañero(a)', value: 'FELLOW'},
    {label: 'Ex-Compañero(a)', value: 'EX-FELLOW'},
    {label: 'Director(a)', value: 'DIRECTOR'},
    {label: 'Gerente(a)', value: 'MANAGER'},
    {label: 'Otro(a)', value: 'OTHER'},
  ]
  maritalStatus = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Soltero(a)', value: 'SINGLE'},
    {label: 'Casado(a)', value: 'MARRIED'},
    {label: 'Divorciado(a)', value: 'DIVORCED'},
    {label: 'Viudo(a)', value: 'WIDOWER'},
    {label: 'Otro', value: 'OTHER'},
  ]
  nationalities = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Mexicana', value: 'MEXICAN'},
    {label: 'Extranjera', value: 'FOREIGNER'},
  ]
  genders = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Masculino', value: 'MALE_SEX'},
    {label: 'Femenino', value: 'FEMALE_SEX'},
  ]
  kindHouses = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Propia', value: 'OWN'},
    {label: 'Rentada', value: 'RENT'},
  ]
  @Output() updateData = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private apiLoanService: ApiLoanService,
    private apiWorkerService: ApiWorkerService,
    private messageService: MessageService,
    public authService: AuthService
  ) {}

    ngOnInit() {
      const data = this.data && this.data.additionalInformation ? JSON.parse(this.data.additionalInformation) : {}
      this.apiWorkerService.getById(this.data.positionId.workerId._id).subscribe(r => {
        if(!r.error) {
          if ([undefined, null, ''].includes(data['firstName'])) data['firstName'] = r.data.worker.names.firstName
          if ([undefined, null, ''].includes(data['secondName'])) data['secondName'] = r.data.worker.names.secondName
          if (!data['fatherLastName'] || data['fatherLastName'] === '') data['fatherLastName'] =  r.data.worker.names.fatherLastName
          if (!data['motherLastName'] || data['motherLastName'] === '') data['motherLastName'] =  r.data.worker.names.motherLastName
          if (!data['curp'] || data['curp'] === '') data['curp'] =  r.data.worker.curp
          if (!data['emailAddress'] || data['emailAddress'] === '') data['emailAddress'] =  r.data.worker.emailAddress
          if (!data['gender'] || data['gender'] === '') data['gender'] =  r.data.worker.sex
          if (!data['phoneNumber'] || data['phoneNumber'] === '') data['phoneNumber'] =  r.data.worker.phoneNumber
          if (!data['namePosition'] || data['namePosition'] === '') data['namePosition'] =  r.data.position.name
          if (!data['ingressDate'] || data['ingressDate'] === '') data['ingressDate'] =  r.data.position.startDate
          if (!data['address_cp'] || data['address_cp'] === '') data['address_cp'] =  r.data.worker.address.postalCode
          if (!data['address_state'] || data['address_state'] === '') data['address_state'] =  r.data.worker.address.state
          if (!data['address_city'] || data['address_city'] === '') data['address_city'] =  r.data.worker.address.city
          if (!data['address_neighborhood'] || data['address_neighborhood'] === '') data['address_neighborhood'] =  r.data.worker.address.neighborhood
          if (!data['address_street'] || data['address_street'] === '') data['address_street'] =  r.data.worker.address.streetNameAndNumber
          if (!data['address_delegation'] || data['address_delegation'] === '') data['address_delegation'] =  r.data.worker.address.address_delegation
        }
        this.createForm(data)
      })
    }

    createForm(data: any) {
      this.entity = this.fb.group({
        firstName: [data['firstName'] || '', [Validators.required, Validators.minLength(3)]],
        secondName: [data['secondName'] || '', [Validators.minLength(3)]],
        fatherLastName: [data['fatherLastName'] || '', [Validators.required, Validators.minLength(3)]],
        motherLastName: [data['motherLastName'] || '', [Validators.required, Validators.minLength(3)]],
        curp: [data['curp'] || '', [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/)]],
        emailAddress: [data['emailAddress'] || '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
        typeIdentityDocument: [data['typeIdentityDocument'] || '', [Validators.required]],
        gender: [data['gender'] || '', [Validators.required]],
        phoneNumber: [data['phoneNumber'] || '', [Validators.required, Validators.pattern(/^\+52[0-9]{10}$/)]],
        namePosition: [data['namePosition'] || '', [Validators.required, Validators.minLength(3)]],
        ingressDate: [data['ingressDate'] ? new Date(data['ingressDate']) : '', [Validators.required]],
        employeeNumber: [data['employeeNumber'] || ''],
        kindHouse: [data['kindHouse'] || ''],
        timeHouse: [data['timeHouse'] || ''],
        address_country: [data['address_country'] || '',[Validators.required]],
        address_cp: [data['address_cp'] || '',[Validators.required]],
        address_state: [data['address_state'] || '',[Validators.required]],
        address_city: [data['address_city'] || '',[Validators.required]],
        address_delegation: [data['address_delegation'] || '',[Validators.required]],
        address_neighborhood: [data['address_neighborhood'] || '',[Validators.required]],
        address_street: [data['address_street'] || '',[Validators.required]],
        address_externalNumber: [data['address_externalNumber'] || '',[Validators.required]],
        address_internalNumber: [data['address_internalNumber'] || ''],
        address_betweenStreets: [data['address_betweenStreets'] || ''],
        loan_usage: [data['loan_usage'] || ''],
        loan_disposition_method: [data['loan_disposition_method'] || '', [Validators.required]],
        loan_clabe: [data['loan_clabe'] || '', [Validators.required, Validators.pattern(/^\d{18}$/)]],
        maritalStatus: [data['maritalStatus'] || ''],
        nationality: [data['nationality'] || ''],
        personal_reference_relation: [data['personal_reference_relation'] || ''],
        personal_reference_firstName: [data['personal_reference_firstName'] || ''],
        personal_reference_lastName: [data['personal_reference_lastName'] || ''],
        personal_reference_phone: [data['personal_reference_phone'] || ''],
        work_reference_relation: [data['work_reference_relation'] || ''],
        work_reference_firstName: [data['work_reference_firstName'] || ''],
        work_reference_lastName: [data['work_reference_lastName'] || ''],
        work_reference_phone: [data['work_reference_phone'] || ''],
        aval_reference_relation: [data['aval_reference_relation'] || ''],
        aval_reference_firstName: [data['aval_reference_firstName'] || ''],
        aval_reference_lastName: [data['aval_reference_lastName'] || ''],
        aval_reference_phone: [data['aval_reference_phone'] || ''],
        aval_emailAddress: [data['aval_emailAddress'] || ''],
        aval_address_cp: [data['aval_address_cp'] || ''],
        aval_address_state: [data['aval_address_state'] || ''],
        aval_address_country: [data['aval_address_country'] || ''],
        aval_address_city: [data['aval_address_city'] || ''],
        aval_address_delegation: [data['aval_address_delegation'] || ''],
        aval_address_neighborhood: [data['aval_address_neighborhood'] || ''],
        aval_address_street: [data['aval_address_street'] || ''],
        aval_address_externalNumber: [data['aval_address_externalNumber'] || ''],
        aval_address_internalNumber: [data['aval_address_internalNumber'] || ''],
        aval_address_betweenStreets: [data['aval_address_betweenStreets'] || ''],
      })
    }

  getStateName(id: string) {
    return this.states.find(u =>  u.value === id)?.label
  }
  updateDataLoan() {
    this.isUpdating = true
    this.apiLoanService.updateAdditionalInformation({data: this.entity.value, id: this.data._id}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      if(!r.error) this.updateData.emit()
    })
  }

}
