<p-table
  #dtIncomes
  [scrollable]="true"
  scrollHeight="600px"
  [value]="incomes"
  [columns]="cols"
  dataKey="_id"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [loading]="loading"
  [paginator]="true"
  [tableStyle]="{'width': '100%'}"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} movimientos"
  [globalFilterFields]="['folio', 'subType', 'description']"
  (onSort)="customSort($event)">
  <ng-template pTemplate="caption">
    <div class="flex flex-row justify-content-between">
      <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
      <div>
        <p-multiSelect [options]="allCols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas" (onChange)="onColumnToggle($event)"></p-multiSelect>
      </div>
      <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtIncomes.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of cols" [style.min-width.px]="150">
        <div class="flex align-items-center" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-income>
    <tr>
      <td *ngFor="let col of cols">
        <ng-container
          *ngIf="col.field !== 'applicationDate' &&
          col.field !== 'creationDate' &&
          col.field !== 'subType' &&
          col.field !== 'status' &&
          col.field !== 'amount' &&
          col.field !== 'originAccount'"
        >
          <span>{{ income[col.field] }}</span>
        </ng-container>

        <ng-container *ngIf="col.field === 'applicationDate'">
          {{ income.applicationDate | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-container *ngIf="col.field === 'creationDate'">
          {{ income.creationDate | date: 'MM/dd/yyyy' }}
        </ng-container>
        <ng-container *ngIf="col.field === 'subType'">
          <p-tag [value]="getSubTypeEsp(income.subType)" [severity]="getSeverity(income.subType)"></p-tag>
        </ng-container>
        <ng-container *ngIf="col.field === 'status'">
          <a class="link-subsection" (click)="updateStatus(income)"><i class="pi" [ngClass]="{
          'text-green-500 pi-check-circle': income.status === 'APPLIED',
          'text-yellow-500 pi-exclamation-circle': income.status === 'PENDING',
          'text-red-500 pi-times-circle': income.status === 'CANCELED'
          }"></i></a>
        </ng-container >
        <ng-container *ngIf="col.field === 'amount'">
          {{ income.amount | currency }}
        </ng-container>
        <ng-container *ngIf="col.field === 'originAccount'">
          {{ income.originAccount ? income.originAccount : '-'}}
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">No se encontraron entradas</td>
    </tr>
  </ng-template>
</p-table>
