<p-dialog [(visible)]="showModal"  [style]="{ width: '450px', height: '500px' }"  [modal]="true" [closable]="true" header="Dispersar Saldo" styleClass="p-fluid">
  <form [formGroup]="disperseForm">
    <div class="field field-flex">
      <label for="selectedBalance">Indica la linea de fondeo de donde se tomara el dinero</label>
      <div class="field field-flex">
        <p-dropdown formControlName="balance" id="selectedBalance" scrollHeight="150px" [options]="financialDetail.fundingBalances" optionLabel="balanceName"></p-dropdown>
      </div>
    </div>
    <div class="field field-flex">
      <label for="selectedAccount">Indica la cuenta a donde se deposito el fondeo</label>
      <div class="field field-flex">
        <p-dropdown formControlName="account" id="selectedAccount" [panelStyle]="{'width': '100%'}" scrollHeight="150px" [options]="bankAccounts?.data" optionLabel="name"></p-dropdown>
      </div>
    </div>
    <div class="field field-flex">
      <label for="selectedAccount">Monto a dispersar</label>
      <div class="field field-flex">
        <p-inputNumber [min]="0" formControlName="amount" inputId="amount" placeholder="Monto" mode="currency" currency="MXN" locale="es-MX" [showClear]="true"></p-inputNumber>
      </div>
    </div>
    <div class="field">
      <label for="description">Descripción*</label>
      <textarea
        pInputTextarea
        id="description"
        formControlName="description"
        rows="2"
        cols="30"
        placeholder="Descripción de la disperción..."
        required
        [autoResize]="true"
        [style.min-height.px]="100" >
      </textarea>
    </div>
    <p-footer>
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideModalDialog()"></button>
      <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="onSubmit()" [disabled]="disperseForm.invalid"></button>

    </p-footer>
  </form>

</p-dialog>
