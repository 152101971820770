import {Component, Input} from '@angular/core';
import {environment} from "@env/environment";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {IPosition} from "@modules/private/workers/data/interfaces/iposition.interface";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ISodPropertiesInterface} from "@modules/private/workers/data/interfaces/isod-properties.interface";
import {PERIOD_TYPE_ENUM} from "@modules/private/payroll/data/enums/payroll.enum";
import {GENDER_WORKER} from "@modules/private/workers/data/enums/gender.enum";
import {
  PROVIDERS_ENUM,
  WORKER_STATUS_ENUM,
  WORKER_STATUS_ENUM_COLOR, WORKER_STATUS_VERIFICATION_ENUM,
  WORKER_STATUS_VERIFICATION_ENUM_COLOR
} from "@modules/private/workers/data/enums/workerStatus.enum";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {IExpressLeague} from "@modules/private/workers/data/interfaces/iexpressLeague.interface";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-worker-overview',
  templateUrl: './worker-overview.component.html',
  styleUrls: ['./worker-overview.component.scss'],
  providers: [ MessageService ]
})
export class WorkerOverviewComponent {
  $_periodType = PERIOD_TYPE_ENUM
  $_gendersEnum = GENDER_WORKER
  $_statusUserEnum = WORKER_STATUS_ENUM
  $_statusUserEnumColor = WORKER_STATUS_ENUM_COLOR
  $_statusUserVerificationEnum = WORKER_STATUS_VERIFICATION_ENUM
  $_statusUserVerificationEnumColor = WORKER_STATUS_VERIFICATION_ENUM_COLOR
  @Input() data!: {worker: IWorker, position: IPosition, payroll: IPayroll, expressLeague: IExpressLeague}
  @Input() properties!: ISodPropertiesInterface
  providersWorker = PROVIDERS_ENUM
  constructor(
    public authService: AuthService,
    private messageService: MessageService
  ) {
  }

  getExpressLeague() {
    let id = ''
    if (this.data.expressLeague) {
      id = btoa(this.data.expressLeague._id)
    }
    return this.data.expressLeague && this.data.expressLeague._id ? `${environment.ligaExpress}?session=${id}`: ''
  }

  copyLink() {
    navigator.clipboard.writeText(this.getExpressLeague()).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Se copio correctamente la liga', life: 4000});
    }).catch(err => {})
  }
}
