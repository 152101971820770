<ng-container class="min-h-1000">
  <div class="col-12">
    <label class="font-medium">Este apartado permite la asignación masiva de colocaciones dentro de la plataforma, y a su vez, el registro de la transacción en la cuenta bancaria. </label>
  </div>
  <div class="col-12">
    <a class="link-subsection" href="assets/documents/template_pago_letras_empleado.csv" target="_blank">
      <i class="pi pi-file" style="font-size: 1.5rem"></i>
    </a>
    <label>  Formato: Pago de letras por Trabajador</label>
  </div>
  <div class="col-12">
    <a class="link-subsection" href="assets/documents/template_pago_letras_nomina.csv" target="_blank">
      <i class="pi pi-file" style="font-size: 1.5rem"></i>
    </a>
    <label>  Formato: Pago de letras por Nomina</label>
  </div>
    <div class="field">
      <div class="col-12">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
        <p-fileUpload
          invalidFileSizeMessageSummary="Error en el tamaño del archivo."
          invalidFileSizeMessageDetail="El archivo no debe ser mayor a 1Mb"
          chooseLabel="Seleccionar documento"
          cancelLabel="Cancelar"
          uploadLabel="Subir"
          name="uploadedFiles[]"
          [multiple]="false"
          accept=".csv"
          [maxFileSize]="1000000"
          (uploadHandler)="onUpload($event)"
          [customUpload]="true">
        </p-fileUpload>
      </div>
    </div>
</ng-container>
