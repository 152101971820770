<p-dialog [(visible)]="showModal"  [style]="{ width: '450px', height: '500px' }"  [modal]="true" [closable]="true" (onHide)="hideModalDialog()" header="Registrar una Colocación" styleClass="p-fluid">
  <div class="field-flex text-center">
    <label class="font-medium pb-4">Tipo de colocación: Pago de Letras</label>
  </div>
  <form [formGroup]="entity">
    <div class="field field-flex">
      <label for="reference">Referencia*</label>
        <p-dropdown
          id="reference"
          [panelStyle]="{'width': '100%'}"
          scrollHeight="150px"
          [options]="references"
          placeholder="Seleccione la referencia"
          formControlName="destinationType"
        >
        </p-dropdown>
    </div>

    <div class="field">
      <label for=>Monto*</label>
      <p-inputNumber [min]="0" formControlName="amount" inputId="amount" placeholder="Monto" mode="currency" currency="MXN" locale="es-MX" [showClear]="true"></p-inputNumber>
      <ng-container *ngIf="entity.get('amount')?.invalid && (entity.get('amount')?.dirty || entity.get('amount')?.touched)">
        <small class="p-error" *ngIf="entity.get('amount')?.errors?.['required']">El Monto es obligatorio.</small>
      </ng-container>
    </div>

    <div class="field field-flex" *ngIf="entity.get('destinationType')?.value === 'COMPANY'">
      <label for="payroll">Nomina*</label>
      <div class="field field-flex">
        <p-dropdown
          id="payroll"
          [panelStyle]="{'width': '100%'}"
          scrollHeight="150px"
          [options]="payrolls"
          optionLabel="name"
          placeholder="Seleccione una nomina"
          formControlName="company">
        </p-dropdown>
      </div>
    </div>

    <!-- Sección para APLICACION -->
    <div class="field field-flex" *ngIf="entity.get('destinationType')?.value === 'WORKER'">
      <div class="field field-flex">
        <label for="folio">Folio del Producto*</label>
        <input type="text" pInputText id="folio" formControlName="folio" placeholder="Folio" required />
        <ng-container *ngIf="entity.get('folio')?.invalid && (entity.get('folio')?.dirty || entity.get('folio')?.touched)">
          <small class="p-error" *ngIf="entity.get('folio')?.errors?.['required']">El Folio es obligatorio.</small>
        </ng-container>
      </div>
      <div class="field field-flex">
        <label for="applicationDate">Fecha de Aplicación*</label>
        <p-calendar
          id="applicationDate"
          formControlName="applicationDate"
          [showIcon]="true"
          appendTo="body"
          placeholder="Selecciona una fecha"
          dateFormat="yy-mm-dd"
        >
        </p-calendar>
        <ng-container *ngIf="entity.get('applicationDate')?.invalid && (entity.get('applicationDate')?.dirty || entity.get('applicationDate')?.touched)">
          <small class="p-error" *ngIf="entity.get('applicationDate')?.errors?.['required']">La Fecha de Aplicación es obligatoria.</small>
        </ng-container>
      </div>
      <div class="field">
        <label for="trackingId">Folio de Seguimiento*</label>
        <input type="text" pInputText id="trackingId" formControlName="trackingId" placeholder="Folio de Seguimiento" required />
        <ng-container *ngIf="entity.get('trackingId')?.invalid && (entity.get('trackingId')?.dirty || entity.get('trackingId')?.touched)">
          <small class="p-error" *ngIf="entity.get('trackingId')?.errors?.['required']">El Folio de seguimiento es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('trackingId')?.errors?.['minlength']">El Folio de seguimiento debe tener al menos {{entity.get('trackingId')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
        </ng-container>
      </div>
    </div>

    <div class="field">
      <label for="description">Descripción*</label>
      <textarea
        style="min-height: 50px;"
        pInputTextarea
        id="description"
        formControlName="description"
        rows="2"
        cols="30"
        placeholder="Descripción de la colocación..."
        required
        [autoResize]="true">
      </textarea>
    </div>
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideModalDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" [disabled]="!entity.valid" (click)="onSubmit()"></button>
  </form>
</p-dialog>
<p-toast></p-toast>
