import {Component, OnInit} from '@angular/core';
import {ApiApplicationService} from "@modules/private/application/data/services/api-application.service";
import {IApplicationAttempt} from "@modules/private/application/data/interfaces/application.interface";
import {APPLICATION_ATTEMPT_ENUM} from "@modules/private/application/data/application.enum";

@Component({
  selector: 'app-application-attempts',
  templateUrl: './application-attempts.component.html',
  styleUrls: ['./application-attempts.component.scss']
})
export class ApplicationAttemptsComponent implements OnInit {

  $_applicationAttempts = APPLICATION_ATTEMPT_ENUM
  attempts: IApplicationAttempt[] = [];
  constructor(
    private apiApplicationService: ApiApplicationService
  ) { }

  ngOnInit() {
    this.getAll()
  }

  getAll() {
    this.apiApplicationService.getAllAttempts().subscribe(r => {
      this.attempts = r.data
    })
  }
}
