import {Component, Input} from '@angular/core';
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {
  TRANSACTION_SUB_TYPE,
  TRANSACTION_TYPE
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum";
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-loan-update-status',
  templateUrl: './loan-update-status.component.html',
  styleUrls: ['./loan-update-status.component.scss'],
  providers: [MessageService]
})
export class LoanUpdateStatusComponent {
  @Input() data!: ILoanPopulated
  @Input() defaultBankTransaction!: any
  acceptLoan: FormGroup
  cancelLoan: FormGroup
  isUpdating = false
  comment = ''
  statusValidationStatus = ''
  statusValidation = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Aprobado', value: 'APPROVED'},
    {label: 'Rechazado', value: 'REJECTED' }
  ]
  reasons = [
    {label: 'Selecciona una opción', value: ''},
    {label: 'Abandono de proceso', value: 'PROCESS_ABANDONMENT'},
    {label: 'Capacidad de pago', value: 'PAYMENT_CAPACITY'},
    {label: 'Proceso de aclaración en cobranza', value: 'COLLECTION_CLARIFICATION_PROCESS'},
    {label: 'Recomendación de RRHH', value: 'HR_RECOMMENDATION'},
    {label: 'Rechazo de comité', value: 'COMMITTEE_REJECTION'},
    {label: 'Referencias', value: 'REFERENCES'},
    {label: 'No cumplió con los requisitos', value: 'NOT_MEET_THE_REQUIREMENTS'},
    {label: 'Decisión del cliente', value: 'CUSTOMER_DECISION'},
    {label: 'Limite de créditos activos', value: 'ACTIVE_CREDIT_LIMIT'},
    {label: 'Despido / Renuncia', value: 'TERMINATION_RESIGNATION'},
    {label: 'Convenio Suspendido', value: 'SUSPENDED_AGREEMENT'},
    {label: 'Otro', value: 'OTHER'}
  ]
  constructor(
    private fb: FormBuilder,
    private apiLoanService: ApiLoanService,
    private messageService: MessageService,
    private router: Router,
    public authService: AuthService,
    private apiTransactionService: ApiTransactionService,
    private loaderService: LoaderService
  ) {
    this.acceptLoan = this.fb.group({
      comment: [''],
      confirmRecord: [false, [Validators.required]],
      confirmDocuments: [false, [Validators.required]],
      confirmValidation: [false, [Validators.required]],
      confirmContract: [false, [Validators.required]],
    })

    this.cancelLoan = this.fb.group({
      comment: [''],
      rejectReason: ['', [Validators.required]]
    })
  }

  updateStatus(type: string) {
    this.isUpdating = true
    if (this.defaultBankTransaction.error) {
      this.messageService.add({ severity: 'error', summary: 'El crédito no fue aprobado',
        detail: 'Activa una cuenta bancaria para poder dispersar el crédito.',
        life: 5000
      })
      return
    }
    console.log('this.data', this.data)
    console.log('balance', this.defaultBankTransaction.data.balance)
    console.log('this.data.amount', this.data.amount)
    if (this.defaultBankTransaction.data.balance < this.data.amount) {
      this.messageService.add({ severity: 'error', summary: 'Cuenta Bancaria de dispersión',
        detail: 'No se cuenta con saldo suficiente para realizar la disperción',
        life: 5000
      })
      return
    }
    const outcomeTransaction = {
      originAccount: this.defaultBankTransaction.data._id,
      targetAccount:this.data.folio,
      creditFolio:this.data.folio,
      amount:this.data.amount,
      type:TRANSACTION_TYPE.OUTCOME,
      subType:TRANSACTION_SUB_TYPE.LOAN_ALLOCATION,
      destinationType: "WORKER",
      description:"Aprovación de credito",
    }
    console.log(type)

    this.loaderService.showSpinner()
    this.apiLoanService.updateStatus(
      type === 'APPROVED' ? {...this.acceptLoan.value, id: this.data._id, status: 'APPROVED'}
        : {...this.cancelLoan.value, id: this.data._id, status: 'DENIED'}
    ).subscribe((r) => {
      console.log(r)
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      this.isUpdating = false
      if (!r.error) {
        setTimeout(() => {
          this.router.navigate(['loan'])
        }, 2000)
        setTimeout(() => {
          this.router.navigate([`loan/${this.data._id}`])
        }, 3000)
      }
      if(!r.error && type == 'APPROVED') {
        this.apiTransactionService.addIncomeOutcome(outcomeTransaction).subscribe(it => {
          this.messageService.add({
            severity: it.error ? 'error': 'success',
            summary: it.error ? 'Error al registras Dispersión': 'Dispersión exitosa',
            detail: it.error ? it.msg: 'Se registro la dispersión del credito de forma exitosa',
            life: 3000
          })
        })
      }
      this.loaderService.hideSpinner()
    })
  }
}
