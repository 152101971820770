import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import {PushingService} from "@modules/private/pushing/data/services/pushing.service";
import {ConfirmationService, MenuItem, Message, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
import {Router} from "@angular/router";
import {LoaderService} from "@data/services/page/loader.service";
import {PUSHING_MAS_NOMINA} from "@modules/private/pushing/data/constants/pushingColumns.constants";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {Table} from "primeng/table";
import {ViewSaverService} from "@data/services/page/viewSaver.service";
import {MainTableComponent} from "@shared/components";
import { PushingProxyService } from '../../pushing/data/services/pushingProxy.service';

@Component({
  selector: 'app-pushing-list',
  templateUrl: './pushing-list.component.html',
  styleUrls: ['./pushing-list.component.scss'],
  providers: [ MessageService, ConfirmationService ]
})
export class PushingListComponent implements OnDestroy{
  responseMessage: Message[] = []
  showMessage = false
  entity!: FormGroup
  submitted: boolean = false;
  createEntity = false
  loading: boolean = true;
  totalRecords = 0
  itemsByPage = 10
  columns = PUSHING_MAS_NOMINA
  actionsMasNomina = [
    {icon: 'fa-solid fa-shuffle', action: 'REASSIGN_FLOW', title: 'Reasignar flujo', color: 'primary'},
    {icon: 'fa-solid fa-times', action: 'CANCEL_FLOW', title: 'Cancelar proceso', color: 'danger',  rule: {field: 'progressAdvance', value: 10, type: "notEquals"}},
    {icon: 'fa-solid fa-check-double', action: 'COMPLETE_FLOW', title: 'Completar proceso', color: 'success', rule: {field: 'progressAdvance', value: 65, type: "gte"}}
  ]
  actionsPulpi = [
    {icon: 'fa-solid fa-shuffle', action: 'REASSIGN_FLOW', title: 'Reasignar flujo',color: 'primary'},
    {icon: 'fa-solid fa-credit-card', action: 'CREATE_LOAN', title: 'Crear crédito', rule: {field: 'productId', value: null, type: 'equals'}, color: 'help'},
    {icon: 'fa-solid fa-times', action: 'CANCEL_FLOW', title: 'Cancelar proceso', color: 'danger',  rule: {field: 'progressAdvance', value: 10, type: "notEquals"}},
    {icon: 'fa-solid fa-check-double', action: 'COMPLETE_FLOW', title: 'Completar proceso', color: 'success', rule: {field: 'progressAdvance', value: 65, type: "gte"}}
  ]
  actionsUnknown = [
    {icon: 'fa-solid fa-shuffle', action: 'REASSIGN_FLOW', title: 'Reasignar flujo',color: 'primary'},
    {icon: 'fa-solid fa-times', action: 'CANCEL_FLOW', title: 'Cancelar proceso', color: 'danger',  rule: {field: 'progressAdvance', value: 10, type: "notEquals"}},
    {icon: 'fa-solid fa-check-double', action: 'COMPLETE_FLOW', title: 'Completar proceso', color: 'success', rule: {field: 'progressAdvance', value: 65, type: "gte"}}
  ]
  additionalMainActions = [
    {label: 'Nueva proceso', icon: 'fa-solid fa-square-plus', action: 'CREATE_OMNICANAL'},
    {label: 'Calcular Nomipay', icon: 'fa-solid fa-calculator', action: 'CALCULATE_NOMIPAY'}
  ]
  titleFormHeader = 'Actualizar solicitud'
  typeAction = 'UPDATE_OMNICANAL'
  @ViewChild('dtPushings') dtPushings!: MainTableComponent;
  @ViewChild('dtPulpi') dtPulpi!: MainTableComponent;
  //@ViewChild('dtHistorico') dtHistorico!: MainTableComponent;
  //tableKey = 'pushingsTable';
  //filters: { [key: string]: any } = {};
  constructor(
    public pushingService: PushingService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private loanService: ApiLoanService,
    private router: Router,
    private loaderService: LoaderService,
    public authService: AuthService,
    private tableFilterService: ViewSaverService,
    public pushingProxyService: PushingProxyService,
  ) {}

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  mainActions(flow: string) {
    switch (flow) {
      case 'CREATE_OMNICANAL':
        this.newPushing()
        break;
      case 'CALCULATE_NOMIPAY':
        this.newCalculation()
        break;
      default:
        console.log(flow)
        break;
    }
  }
  actionMethods(data: {event: any, action: string, data: any}) {
    switch (data.action) {
      case 'REASSIGN_FLOW':
        this.reassignFlow(data.event, data.data)
        break;
      case 'CREATE_LOAN':
        this.confirmAction(data.event, data.data, 'CREATE_LOAN')
        break;
      case 'CANCEL_FLOW':
        this.confirmAction(data.event, data.data, 'CANCEL_PUSHING')
        break;
      case 'COMPLETE_FLOW':
        this.confirmAction(data.event, data.data, 'COMPLETE_PUSHING')
        break;
    }
  }

  reassignFlow(event: any, data: any) {
    this.entity = this.fb.group({
      flowId: ['', [Validators.required]],
      pushingId: [data._id]
    })
    this.titleFormHeader = 'Actualizar flujo de solicitud'
    this.typeAction = 'UPDATE_PUSHING'
    this.createEntity = true
    this.entity.clearValidators()
    this.submitted = false;
  }

  confirmAction(event: any, data: any, action: string) {
    let header = 'Crear crédito'
    let message = '¿Estas seguro que quires crear un crédito?'
    let icon ='fa-regular fa-file-lines'
    switch (action) {
      case 'CANCEL_PUSHING':
        header = 'Cancelar Solicitud'
        message = '¿Estas seguro que quieres cancelar la solicitud?'
        icon = 'fa-solid fa-ban'
        break;
      case 'COMPLETE_PUSHING':
        header = 'Completar Solicitud'
        message = '¿Estas seguro que quieres completar la solicitud?'
        icon = 'fa-solid fa-check-double'
        break;
    }
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header,
      message,
      icon,
      rejectLabel: 'Cancelar',
      acceptLabel: 'Continuar',
      accept: () => {
        switch (action) {
          case 'CREATE_LOAN':
            this.createLoanFromPushing(data._id)
            break;
          case 'CANCEL_PUSHING':
            this.updateStatusPushing(data._id, 'CANCELLED')
            break;
          case 'COMPLETE_PUSHING':
            this.updateStatusPushing(data._id, 'COMPLETED')
            break;
        }
      }
    });
  }
  newPushing() {
    this.entity = this.fb.group({
      workerId: [ '', [Validators.required, Validators.minLength(24)]],
      flowId: [ '', [Validators.required, Validators.minLength(24)]]
    })
    this.titleFormHeader = 'Crear un nuevo flujo de solicitud'
    this.typeAction = 'CREATE_PUSHING'
    this.createEntity = true
    this.entity.clearValidators()
    this.submitted = false;
  }

  newCalculation() {
    this.titleFormHeader = 'Calcular ofertas'
    this.typeAction = 'CALCULATE_NOMIPAY'
    this.createEntity = true
    this.submitted = false;
  }
  createPushing() {
    this.loaderService.showSpinner()
    this.pushingService.createPushing(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.router.navigate([`/omnicanal/${r.data._id}`])
      } else if (r.error && r.data._id)  {
        setTimeout(() => {
          this.router.navigate([`/omnicanal/${r.data._id}`])
        }, 5000)
      }
    })
  }

  savePushing() {
    this.loaderService.showSpinner()
    this.pushingService.updatePushing(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.router.navigate([`/omnicanal/${r.data._id}`])
      }
    })
  }

  createLoanFromPushing(id: string) {
    this.loaderService.showSpinner()
    this.loanService.createFromOmnicanal({pushingId: id}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.router.navigate([`/loan/${r.data._id}`])
      }
    })
  }

  updateStatusPushing(id: string, status: string) {
    this.loaderService.showSpinner()
    this.pushingService.updateStatus({id, status}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.router.navigate([`/omnicanal/${r.data._id}`])
      }
    })
  }

  ngOnDestroy() {
    this.pushingProxyService.search = false
  }
}
