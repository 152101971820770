import {Component, Input, OnInit} from '@angular/core';
import {IPushingFlowDetail} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {
  IDocumentADDRESS_RECEIPT,
  IDocumentINE, IDocumentPAYROLL_RECEIPT,
  IDocumentSTATEMENT_ACCOUNT
} from "@modules/private/pushing/data/interfaces/idocument.interface";

@Component({
  selector: 'app-pushing-detail-ai-translate',
  templateUrl: './pushing-detail-ai-translate.component.html',
  styleUrls: ['./pushing-detail-ai-translate.component.scss']
})
export class PushingDetailAiTranslateComponent implements OnInit{
  @Input() data = ''
  @Input() documentId = ''
  @Input() allData!: IPushingFlowDetail
  @Input() type: string = 'INE'

  ngOnInit() {
    const documents = this.allData.pushing.flowId.documents
    const document = documents.find(d => d.documentId._id === this.documentId)
    this.type = document?.documentId?.name ?? ''
  }

  getObject(data: string) {

    switch (this.type) {
      case 'PULPI_BALANCE_ACCOUNT':
        return JSON.parse(data) as IDocumentSTATEMENT_ACCOUNT
      case 'PULPI_PROOF_OF_ADDRESS':
        return JSON.parse(data) as IDocumentADDRESS_RECEIPT
      case 'PULPI_LAST_PROOF_OF_PAYROLL':
        return JSON.parse(data) as IDocumentPAYROLL_RECEIPT
      case 'PULPI_PENULTIMATE_PROOF_OF_PAYROLL':
        return JSON.parse(data) as IDocumentPAYROLL_RECEIPT
      case 'INE':
        return JSON.parse(data) as IDocumentINE
    }
    return JSON.parse(data)
  }

}
