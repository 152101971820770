<ng-container *ngIf="data">
  <div class="grid">
    <div class="col-12">
      <div class="main-title">
        Datos Generales
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nombre:</h3>
      <p>{{data.positionId.workerId.names.firstName}}  {{data.positionId.workerId.names.secondName}} {{data.positionId.workerId.names.fatherLastName}} {{data.positionId.workerId.names.motherLastName}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">RFC:</h3>
      <p><a [routerLink]="'/worker/'+data.positionId.workerId._id">{{data.positionId.workerId.rfc}}</a></p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">CURP:</h3>
      <p>{{data.positionId.workerId.curp}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de cumpleaños:</h3>
      <p>{{data.positionId.workerId.birthDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Genero:</h3>
      <p>{{data.positionId.workerId.sex | selectEnum:$_gendersEnum}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Datos de contacto
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Teléfono:</h3>
      <p>{{data.positionId.workerId.phoneNumber || '-'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Email:</h3>
      <p>{{data.positionId.workerId.emailAddress || '-'}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Datos de empleo
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Email:</h3>
      <p>{{data.positionId.workerId.status}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de ingreso:</h3>
      <p>{{data.positionId.startDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de egreso:</h3>
      <p>{{lastPosition.endDate | date: 'dd-MM-YYYY'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nombre del puesto:</h3>
      <p>{{data.positionId.name}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Salario Anualizado:</h3>
      <p>{{data.positionId.salary | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Salario mensual:</h3>
      <p>{{ (data.positionId.salary / 12) | currency}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Datos de empresa
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Nómina:</h3>
      <p>{{data.positionId.payrollId.name}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Periodo:</h3>
      <p>{{data.positionId.payrollId.periodType | selectEnum:$_periodTypeEnum}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Estatus de créditos:</h3>
      <p>{{data.positionId.payrollId.loansEnabled ? 'Activo': 'Desactivado'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Estatus de SoD:</h3>
      <p>{{data.positionId.payrollId.withdrawalsEnabled ? 'Activo': 'Desactivado'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Comisión por apertura:</h3>
      <p>{{data.positionId.payrollId.loanCreationFeeRate | percent}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Tasa de interés anual:</h3>
      <p>{{data.positionId.payrollId.loanRate | percent}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Dirección del usuario
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Código postal:</h3>
      <p>{{data.positionId.workerId.address.postalCode || '-' }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Estado:</h3>
      <p>{{data.positionId.workerId.address.state || '-' }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Ciudad:</h3>
      <p>{{data.positionId.workerId.address.city || '-'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Colonia:</h3>
      <p>{{data.positionId.workerId.address.neighborhood || '-'}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Calle y número:</h3>
      <p>{{data.positionId.workerId.address.streetNameAndNumber || '-'}}</p>
    </div>
  </div>
</ng-container>
