import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {environment} from "@env/environment";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiBalanceService extends ApiClass{

  constructor() {
    super();
    this.path = 'balance'
  }

  getBalance(type: 'INCOME' | 'OUTCOME' | 'ALL', detail:boolean, filters:any) {
    const body:any = {
      type: type,
      details: detail
    }
    if (filters.targetAccountId) body.targetAccount=filters.targetAccountId
    if (filters.originAccountId) body.originAccount=filters.originAccountId
    if (filters.subType && filters!= 'ALL') body.subType=filters.subType
    return this.http.post(environment.financialGatewayUrl + this.path, body).pipe(
      map((r) => r as {error: boolean; msg: string; data: any })
    )
  }
}
