<div class="container">
  <div class="grid">
    <div class="col-12">
      <small>Listado de todas las direcciones del usuario</small>
    </div>
    <ng-container *ngFor="let a of address">
      <div class="col-12 md:col-6 xl:col-4">
        <div class="address">
          <i class="fa-solid fa-pen-to-square address-edit"></i>
          <i class="fa-solid fa-trash address-delete"></i>
          <p class="mb-1">
            <small> CP. {{a.addressId.cp}}, {{a.addressId.street}} {{a.addressId.externalNumber}} {{a.addressId.internalNumber}}</small> <br>
            {{a.addressId.country}}, {{a.addressId.state}}
          </p>
          <i class="fa-solid fa-copy address-copy"></i>
        </div>
      </div>
    </ng-container>
  </div>
</div>
