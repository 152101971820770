import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatesExportService {

  constructor() { }

  formatDate(date: string): string {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  formatDatesExportData(supplierData: any[], columns: any[]): any[] {
    return supplierData.map(supplier =>
      columns.reduce((formattedData, col) => {
        formattedData[col.header] = (
          col.field === 'agreementDate' ||
          col.field === 'creationDate' ||
          col.field === 'createdDate' ||
          col.field === 'applicationDate'
        )
          ? this.formatDate(supplier[col.field])
          : supplier[col.field];
        return formattedData;
      }, {})
    );
  }
}
