<p-dialog [(visible)]="showModal"  [style]="{ maxWidth: '750px', maxHeight: '850px' }" [modal]="true" [closable]="true" (onHide)="hideModalDialog()" header="Completar Transacción" styleClass="p-fluid">
  <ng-container  *ngIf="transactionData">
    <div class="grid">
      <div class="col-12">
        <div class="main-title">Detalles de transacción</div>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Estatus:</h3>
        <i class="pi" [ngClass]="{'text-yellow-500 pi-exclamation-circle':TRANSACTION_STATUS.PENDING}" *ngIf="TRANSACTION_STATUS.PENDING === transactionData.status"> {{TRANSACTION_STATUS_ESP.PENDING}}</i>
        <i class="pi" [ngClass]="{'text-red-500 pi-times-circle':TRANSACTION_STATUS.CANCELED}" *ngIf="TRANSACTION_STATUS.CANCELED === transactionData.status"> {{TRANSACTION_STATUS_ESP.CANCELED}}</i>
        <i class="pi" [ngClass]="{'text-green-500 pi-check-circle':TRANSACTION_STATUS.APPLIED}" *ngIf="TRANSACTION_STATUS.APPLIED === transactionData.status"> {{TRANSACTION_STATUS_ESP.APPLIED}}</i>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Folio:</h3>
        <p>{{transactionData.folio}}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Sub Tipo:</h3>
        <p>{{transactionType(transactionData.subType)}}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">ID Cuenta Origen:</h3>
        <p>{{transactionData.originAccount}}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Descripcción:</h3>
        <p>{{transactionData.description}}</p>
      </div>
      <div class="col-4">
        <h3 class="mb-2">Cantidad:</h3>
        <p>{{transactionData.amount | currency}}</p>
      </div>
      <div *ngIf="transactionData.subType=== 'APPLICATION'" class="col-6">
        <h3 class="mb-2">Nomina ID:</h3>
        <p>{{transactionData.payroll.id}}</p>
      </div>
      <div *ngIf="transactionData.subType=== 'APPLICATION'" class="col-6">
        <h3 class="mb-2">Nomina:</h3>
        <p>{{transactionData.payroll.name}}</p>
      </div>
      <div *ngIf="transactionData.subType=== 'APPLICATION'" class="col-12">
        <div class="col-12">
          <div class="main-title">Pagos a procesar</div>
        </div>
        <div class="col-12">
          <label class="font-medium">Usa el siguiente formato para agregar los pagos relacionados con el folio en cuestión. </label>
        </div>
        <div class="col-12">
          <a class="link-subsection" href="assets/documents/template_pago_letras_empleado.csv" target="_blank">
            <i class="pi pi-file" style="font-size: 1.5rem"></i>
          </a>
          <label>  Formato: Pago de creditos por trabajador</label>
        </div>
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false"></p-messages>
        <p-fileUpload
          invalidFileSizeMessageSummary="Error en el tamaño del archivo."
          invalidFileSizeMessageDetail="El archivo no debe ser mayor a 1Mb"
          chooseLabel="Seleccionar documento"
          cancelLabel="Cancelar"
          uploadLabel="Subir"
          name="uploadedFiles[]"
          [multiple]="false"
          accept=".csv"
          [maxFileSize]="1000000"
          (uploadHandler)="onUpload($event)"
          [customUpload]="true">
        </p-fileUpload>
      </div>
    </div>

    <div class="card flex flex-wrap gap-3 justify-content-center pt-5">
      <p-button label="Salir" (onClick)="hideModalDialog()"></p-button>
      <p-button *ngIf="transactionData.subType!== 'APPLICATION'" icon="pi pi-check" label="Autorizar" styleClass="p-button-text" (onClick)="complete('APPLIED')" [disabled]="TRANSACTION_STATUS.APPLIED === transactionData.status"/>
      <p-button *ngIf="transactionData.subType!== 'APPLICATION'" icon="pi pi-times" label="Cancelar" styleClass="p-button-text" (onClick)="complete('CANCELED')" [disabled]="TRANSACTION_STATUS.CANCELED === transactionData.status"/>
    </div>
  </ng-container>
</p-dialog>
