import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {IApiFinancialList, IFinancial } from '@modules/private/financial/data/interfaces/iapi-financial.interface';
import { ApiClass } from '@data/class/api-class';
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiFinancialService extends ApiClass {

  constructor() {
    super();
    this.path = 'fundingEntity';
  }

  getAll(): Observable<IApiFinancialList> {
    return this.getCollections().pipe(map((r) => r as IApiFinancialList));
  }

  getById(id: string): Observable<{ error: boolean; msg: string; data: IFinancial }> {
    return this.getCollection(id).pipe(map((r) => r as { error: boolean; msg: string; data: IFinancial }));
  }

  createFinancial(data: IFinancial): Observable<any> {
    return this.postCollection(data).pipe(map((r) => r as any));
  }

  updateFinancial(data: IFinancial): Observable<any> {
    return this.patchCollection(data).pipe(map((r) => r as any));
  }

  createFundingBalance(data: { id: string; fundingBalances: { balanceName: string; balance: number }[] }): Observable<any> {
    const subPath = 'balance';
    return this.postCollection(data, subPath).pipe(map((r) => r as any));
  }

  updateFundingBalance(data: { id: string; idBalance: string; balance: number }): Observable<any> {
    const subPath = 'balance';
    return this.patchCollection(data, environment.apiUrl, this.path+'/balance').pipe(map((r) => r as any));
  }
}
