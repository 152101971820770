import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_ESP, getSubTypeEsp, TRANSACTION_SUB_TYPE
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum"
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service"
import {Message, MessageService} from "primeng/api"
import {NgxCsvParser} from "ngx-csv-parser"
import {LoaderService} from "@data/services/page/loader.service"

@Component({
  selector: 'app-complete-modal',
  templateUrl: './complete-modal.component.html',
  styleUrls: ['./complete-modal.component.scss']
})
export class CompleteModalComponent implements OnInit {
  @Input() transactionData: any
  @Input() bankAccountData: any
  @Input() showModal!: any
  @Output() hideModalDialogEvent = new EventEmitter<any>()
  @Output() completeTransactionEvent = new EventEmitter<any>()
  uploadData: any = []
  responseMessage: Message[] = []
  showMessage = false
  showMassiveColocation = false
  isUploading = false

  constructor(
    private apiTransactionService: ApiTransactionService,
    private messageService: MessageService,
    private ngxCsvParser: NgxCsvParser,
    private loaderService: LoaderService,
  ) {
  }

  ngOnInit(): void {
    if (this.transactionData && this.transactionData.subType === TRANSACTION_SUB_TYPE.APPLICATION) {
      this.showMassiveColocation = true
    }
  }

  hideModalDialog() {
    this.hideModalDialogEvent.emit('COMPLETE_MODAL')
  }

  complete(action: string) {
    const dataToSave = {
      folio: this.transactionData.folio,
      action: action
    }
    this.apiTransactionService.completeTransaction(dataToSave).subscribe( r => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Transacción exitosa', detail: r.error ? r.msg: r.msg, life: 3000 })
      if (!r.error ) {
        this.hideModalDialog()
      }
    })
    this.completeTransactionEvent.emit()
  }

  protected readonly TRANSACTION_STATUS_ESP = TRANSACTION_STATUS_ESP
  protected readonly TRANSACTION_STATUS = TRANSACTION_STATUS
  protected readonly transactionType = getSubTypeEsp

  onUpload(event: any) {
    const files = event.files
    this.isUploading = true
    this.loaderService.showSpinner()
    this.ngxCsvParser.parse(files[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
      next: (result: any): void => {
        this.loaderService.hideSpinner()
        this.uploadData = []
        let formatError = false
        let hasValidData = false
        result.some((object: string[], key: number) => {
          if (key === 0 || object.every(value => !value || value.trim() === '')) {
            return false
          }
          hasValidData = true
          if (object[0].toUpperCase() !== 'TRABAJADOR') {
            this.displayErrorMessage('Error de formato', 'El archivo contiene referencia no conocida.')
            formatError = true
            return true
          }

          if (object.length < 5) {
            this.displayErrorMessage('Error de formato', 'El archivo no contiene los campos requeridos.')
            formatError = true
            return true
          }

          this.uploadData.push(this.mapUploadData(object))
          return false
        })
        if (!hasValidData) {
          this.displayErrorMessage('Archivo vacío', 'El archivo no contiene datos válidos. Asegúrate de que las filas contengan información.')
          this.isUploading = false
          return
        }

        if (!formatError && this.uploadData.length > 0) {

          this.isUploading = false
          const totalAmount = this.uploadData.reduce((sum: number, data: any) => sum + data.amount, 0)

          if (totalAmount > this.transactionData.amount) {
            this.displayErrorMessage('Fondos Insuficientes', 'El monto total de los movimientos excede el monto disponible.')
            this.isUploading = false
            return
          }

          const bodyUploadData = {
            folio: this.transactionData.folio,
            subType: 'APPLICATION',
            action: TRANSACTION_STATUS.APPLIED,
            clabe: this.bankAccountData.clabe,
            transactions:this.uploadData
          }
          this.apiTransactionService.completePayrollApplication(bodyUploadData).subscribe( r => {
            this.isUploading = false
            this.responseMessage = [
              {
                summary: r.error ? 'Hubo un error al completar la transacción':'La transacción se completo exitosamente',
                severity: r.error ? 'error': 'success',
                closable: true,
                detail: r.error ? r.msg +
                  (r.data.applicationsIssues.urlReport ?
                    `: <a href="${r.data.applicationsIssues.urlReport}" target="_blank">Descargar reporte</a>`
                    : '')
                  : ''
              }]
            if (Object.keys(r?.data.businessErrors).length !== 0) {
              this.responseMessage.push({
                summary: 'Hubo un error al procesar el archivo',
                severity: r.data.transactionCompleted.length>=1 ? 'warn' : 'error',
                closable: true,
                detail: `${r?.data.businessErrors.msg}:
                      <a href="${r?.data.businessErrors.urlReport}" target="_blank">Descargar reporte</a>`,
              })
            }
            if (!r.error) {
              setTimeout(() => {
                this.completeTransactionEvent.emit()
              }, 2000)
            }
          })
        } else {
          this.isUploading = false
        }


      }
    })
  }

  private displayErrorMessage(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
      life: 8000
    })
  }

  private mapUploadData(object: string[]): {} {
    return {
      amount: Number(object[1]),
      creditFolio: object[2],
      applicationDate: object[3],
      trackingId: object[4],
      description: object[5] ? object[5] : 'Aplicación Masiva',
    }
  }

}
