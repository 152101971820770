import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {catchError, map, of} from "rxjs";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiTransactionService extends ApiClass {

  constructor() {
    super();
    this.path = 'transaction'
  }

  addIncomeOutcome(data: any) {
    return this.postCollection(data, '', environment.financialGatewayUrl).pipe(map((r) => r as {error: boolean; msg: string; data: any}))
  }

  completeTransaction(data: any) {
    return this.patchCollection(data, '', environment.financialGatewayUrl + this.path).pipe(map((r) => r as {error: boolean; msg: string; data: any}))
  }

  completePayrollApplication(data: any) {
    return this.patchCollection(data, '', environment.financialGatewayUrl + this.path+'/'+'payroll').pipe(map((r) => r as {error: boolean; msg: string; data: any}))
  }

  massiveColocation(data: any) {
    return this.http.post<{error: boolean, msg: string, data: any }>(environment.financialGatewayUrl + this.path + '/batch', {data}).pipe(
      map((r) => {
        return r
      }),
      catchError((r) => {
        return of(r.error)
      })
    )
  }
}
