import { Injectable } from '@angular/core';
import {ApiClass} from "@data/class/api-class";
import {environment} from "@env/environment";
import {map} from "rxjs";
import {IAddress} from "@modules/private/workers/data/interfaces/iaddress.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiAddressService extends ApiClass {
  constructor() {
    super();
    this.path = 'address'
  }

  getById(id: string) {
    return this.getCollection(id, environment.apiUrl, this.path + '/entity').pipe(map((r) => r as {data: IAddress[], error: boolean; msg: string}))
  }
}
