import { Injectable } from '@angular/core';
import { PushingService } from './pushing.service'; // Importa el servicio original
import {map, Observable, of} from 'rxjs';
import { tap } from 'rxjs/operators';
import {IPushingList} from "@modules/private/pushing/data/interfaces/ipushing.interface";

@Injectable({
  providedIn: 'root',
})
export class PushingProxyService {
  private readonly storageKey = 'pushingServiceFilters';

  dataTables: any = {}
  search = true
  constructor(private pushingService: PushingService) {}

  getAll(page: number, limit: number, filters: any, identifier: any){
    if (this.dataTables && this.dataTables[identifier] && !this.search) {
      const r: IPushingList = {
        data: this.dataTables[identifier].data,
        total: this.dataTables[identifier].data.length,
        page: this.dataTables[identifier].page,
        itemsByPage: this.dataTables[identifier].limit,
        error: false,
        msg: 'Data loaded from cache',
        status: 200,
        totalItems: this.dataTables[identifier].totalItems
      }
      this.search = true
      return of(r);
    } else {
      return this.pushingService.getAll(page, limit, filters).pipe(map(rr => {
        this.dataTables[identifier] = {page, limit, filters, data: rr.data, totalItems: rr.totalItems}
        return rr
      }))
    }
  }

  getFilters(identifier: string){
    switch (identifier) {
      case 'pulpi':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestabjx':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7909c2f4cd522e0e16988d70', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7909c2f4cd522e0e16988d70', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestaprepoto':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7ccc2ecf02ede0daa867dba7', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7ccc2ecf02ede0daa867dba7', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestaocher':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7925316fe7295171062dae65', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: '7925316fe7295171062dae65', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestagovender':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'c31fc9fcd528804362d7c6eb', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'c31fc9fcd528804362d7c6eb', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestamichopre':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'be31081365ec9d2d4885a1bb', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'be31081365ec9d2d4885a1bb', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'prestaquereserv':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filter, flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'e99ccea7b7e87a706e9341e6', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, payrollId: {value: 'e99ccea7b7e87a706e9341e6', matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'notEquals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'desconocido':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters, flowProvider: {value: 'PULPI',matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'equals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}} : {flowProvider: {value: 'PULPI',matchMode: 'equals'}, flowIdentifier: {value: 'UNKNOWN', matchMode: 'equals'}, status: {value: 'IN_PROCESS', matchMode: 'equals'}}
      case 'historic':
        return (this.dataTables && this.dataTables[identifier]) ? {...this.dataTables[identifier].filters} : {}
      default:
        return {}
    }
  }

}
