import {Table} from "primeng/table";
import { ActivatedRoute } from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@data/services/modules/auth/auth.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import {IFinancial, IFundingBalance} from '@modules/private/financial/data/interfaces/iapi-financial.interface';
import {DisperseBalanceComponent} from "@modules/private/financial/components/disperse-balance/disperse-balance.component";
import {IncreaseBalanceComponent} from "@modules/private/financial/components/increase-balance/increase-balance.component";
import {ApiFinancialService} from "@modules/private/financial/data/services/api/api-financial.service";
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service";

@Component({
  selector: 'app-detail-financial',
  templateUrl: './detail-financial.component.html',
  styleUrls: ['./detail-financial.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class DetailFinancialComponent implements OnInit {
  showModal: boolean = false;
  createFunding: boolean = false;
  financialId!: string;
  financialDetail!: IFinancial;
  items: MenuItem[] = [];
  formAction: 'CREATE' | 'UPDATE' = 'UPDATE';
  entity!: FormGroup;
  foundingBalanceForm!: FormGroup;
  createEntity = false;
  loading: boolean = false;
  public selectedFundingBalance: any;
  @ViewChild('dtFoundingBalance') dtFoundingBalance!: Table;
  @ViewChild(DisperseBalanceComponent) modalComponent!: DisperseBalanceComponent;
  @ViewChild(IncreaseBalanceComponent) modalIncrease!: IncreaseBalanceComponent;

  showForm: boolean = false;
  submitted = false;

  cols: any[] = [
    {field: 'balanceName', header: 'Nombre'},
    {field: 'balance', header: 'Balance'}
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private apiFinancialService: ApiFinancialService,
    public authService: AuthService,
    private apiTransactionService: ApiTransactionService,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.financialId = params['id'];
      this.getFinancialDetail(this.financialId);
    });

    this.financialDetail = {
      _id: '',
      name: '',
      businessName: '',
      agreementDate: new Date(),
      address: '',
      fundingBalances: []
    };
  }

  newEntity(formAction: 'CREATE' | 'UPDATE', data: IFinancial | null = null) {
    this.formAction = formAction;
    if (formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        businessName: ['', [Validators.required]],
        agreementDate: ['', [Validators.required]],
        address: ['', [Validators.required]],
        fundingBalances: this.fb.array([]),
      });
    } else {
      this.entity = this.fb.group({
        name: [data?.name, [Validators.required, Validators.minLength(3)]],
        businessName: [data?.businessName, [Validators.required]],
        agreementDate: [data?.agreementDate ? new Date(data.agreementDate) : '', [Validators.required]],
        address: [data?.address, [Validators.required]],
        fundingBalances: this.fb.array(
          data?.fundingBalances.map(fb => this.fb.group({
            balanceName: [fb.balanceName, Validators.required],
            balance: [fb.balance, Validators.required],
            _id: [fb._id]
          })) || []
        ),
        id: [data?._id]
      });
    }
    this.entity.clearValidators();
    this.submitted = false;
    this.createEntity = true;
  }

  newFunding(formAction: 'CREATE' | 'UPDATE', fundingBalance: IFundingBalance | null = null): void {
    this.formAction = formAction
    this.selectedFundingBalance = fundingBalance || {};

    if (formAction === 'CREATE') {
      this.foundingBalanceForm = this.fb.group({
        balanceName: ['', [Validators.required, Validators.minLength(3)]],
        balance: ['', [Validators.required]],
      });
    } else {
      this.foundingBalanceForm = this.fb.group({
        balanceName: [fundingBalance?.balanceName || '', [Validators.required, Validators.minLength(3)]],
        balance: [{ value: fundingBalance?.balance }, [Validators.required]],
        _id: [fundingBalance?._id || '']
      });
    }
    this.submitted = false;
    this.showModal = true;
    this.createFunding = true;
  }

  handleSave(): void {
    if (this.foundingBalanceForm.valid) {
      const newBalance = this.foundingBalanceForm.value;
      if (this.formAction === 'UPDATE' && newBalance.balance === undefined) {
        newBalance.balance = this.selectedFundingBalance?.balance || 0;
      }
      this.createFundingBalance(newBalance, this.formAction);
    }
  }

  initializeForm(formAction: 'CREATE' | 'UPDATE') {
    if (formAction === 'UPDATE' && this.financialDetail) {
      this.entity = this.fb.group({
        name: [this.financialDetail.name || '', [Validators.required, Validators.minLength(3)]],
        businessName: [this.financialDetail.businessName || '', [Validators.required]],
        agreementDate: [this.financialDetail.agreementDate || '', [Validators.required]],
        address: [this.financialDetail.address || '', [Validators.required]],
        fundingBalances: this.fb.array(
          this.financialDetail.fundingBalances.map(fb => this.fb.group({
            balanceName: [fb.balanceName, Validators.required],
            balance: [fb.balance, Validators.required],
            _id: [fb._id]
          }))
        ),
        id: [this.financialDetail._id || '']
      });
    } else {
      this.entity = this.fb.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        businessName: ['', [Validators.required]],
        agreementDate: ['', [Validators.required]],
        address: ['', [Validators.required]],
        fundingBalances: this.fb.array([]),
        id: ['']
      });
    }
    this.showForm = true;
  }

  getFinancialDetail(id: string) {
    this.apiFinancialService.getById(id).subscribe(response => {
      if (!response.error) {
        this.financialDetail = response.data;
        this.financialDetail.fundingBalances = this.financialDetail.fundingBalances || [];
        this.initializeForm('UPDATE');
        this.createMenu();
      }
    });
  }

  createFundingBalance(newBalance: { balanceName: string; balance: number; _id?: string }, formAction: 'CREATE' | 'UPDATE'): void {
    const fundingId = this.entity.get('id')?.value;
    if (!fundingId) {
      console.error("ID de fondeadora no localizado en el formulario.");
      return;
    }
    this.apiFinancialService.getById(fundingId).subscribe({
      next: (response) => {
        const funding = response.data
        if (funding) {
          if (formAction === 'CREATE') {
            const requestPayload = {
              id: fundingId,
              fundingBalances: [
                {
                  balanceName: newBalance.balanceName,
                  balance: newBalance.balance
                }
              ]
            };
            this.apiFinancialService.createFundingBalance(requestPayload).subscribe(r => {
              if (r.error) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Creación de linea de fondeo',
                  detail: r.msg,
                  life: 5000
                })
                console.error('Error al crear la línea de fondeo:', r.msg)
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Creación de linea de fondeo',
                  detail: 'Se creo de forma exitosa',
                  life: 3000
                })
                this.findFunding();
                this.hideDialog();
              }
            });
          } else if (formAction === 'UPDATE') {
            if (!newBalance._id) {
              console.error('Falta el _id en newBalance para la actualización:', newBalance);
              return;
            }
            const requestPayload = {
              id: fundingId,
              idBalance: newBalance._id,
              balance: newBalance.balance,
              balanceName: newBalance.balanceName
            };

            this.apiFinancialService.updateFundingBalance(requestPayload).subscribe(r => {
              if (r.error) {
                console.error('Error al actualizar la línea de fondeo:', r.msg);
                this.messageService.add({
                  severity: 'error',
                  summary: 'Actualización de linea de fondeo',
                  detail: r.msg,
                  life: 3000
                })
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Actualización de linea de fondeo',
                  detail: 'Se actualizo de forma exitosa',
                  life: 3000
                })
                this.findFunding();
                this.hideDialog();
              }
            });
          } else {
            console.error('Acción de formulario no válida:', formAction);
          }
        } else {
          console.error('Fondeadora no válida:', funding);
        }
      },
      error: (err) => {
        console.error('Error al obtener la fondeadora:', err);
      }
    });
  }

  hideDialog() {
    this.createEntity = false;
    this.createFunding = false;
    this.submitted = false;
  }

  handleSaveEvent(formValue: any) {
    this.createFundingBalance( formValue, 'UPDATE');
  }

  saveProduct() {
    this.submitted = true;
    if (this.entity.valid) {
      const financialData = this.entity.value;
      if (this.formAction === 'CREATE') {
        this.apiFinancialService.createFinancial(financialData).subscribe(() => {
          this.hideDialog();
        });
      } else {
        this.apiFinancialService.updateFinancial(financialData).subscribe(() => {
          this.hideDialog();
        });
      }
    }
  }

  disperseFunding(formValue: any) {
    this.loading = true
    this.apiTransactionService.addIncomeOutcome(formValue).subscribe( r => {
      if ( r.error) {
        console.error('ERROR: ', r.msg)
        this.messageService.add({ severity: 'error', summary: 'Error', detail: r.msg });
        this.loading = false
      } else {
        this.loading = false
        this.hideDialog()
        this.findFunding()
        this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Monto dispersado' });
      }
    })

  }

  findFunding(): void {
    this.loading = true;
    this.apiFinancialService.getById(this.financialId).subscribe({
      next: (response) => {
        if (!response.error) {
          this.financialDetail = response.data;
        }
        this.loading = false;
      },
      error: (err) => {
        console.error('Error al obtener la fondeadora:', err);
        this.loading = false;
      }
    });
  }

  openModal() {
    if (this.modalComponent) {
      this.modalComponent.showModalDialog();}
  }

  openIncrease() {
    if (this.modalIncrease) {
      this.modalIncrease.showModalDialog();
    }
  }

  createMenu() {
    if (this.authService.userHasAuthorization('FINANCIAL', 'UPDATE')) {
      this.items = [
        { separator: true },
        {
          label: 'Editar Información',
          icon: 'fa-solid fa-pen',
          command: () => {
            this.newEntity('UPDATE', this.financialDetail);
          }
        },
        {
          label: 'Incrementar Saldo',
          icon: 'fa-solid fa-money-bill-trend-up',
          command: () => {
            this.openIncrease();
          }
        },
        {
          label: 'Dispersar Saldo',
          icon: 'fa-solid fa-solid fa-arrows-turn-to-dots',
          command: () => {
            this.openModal();
          }
        },
        {
          label: 'Crear Linea de Fondeo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newFunding('CREATE');
          }
        }
      ];
    }
  }
}
