import {Component, EventEmitter, Input, Output} from '@angular/core'
import {Message, MessageService} from "primeng/api"
import {NgxCsvParser, NgxCSVParserError} from "ngx-csv-parser"
import {LoaderService} from "@data/services/page/loader.service"
import {IMassiveColocation} from "@modules/private/bank-accounts/data/interfaces/iapi-balance.interface"
import {
  TRANSACTION_SUB_TYPE,
  TRANSACTION_TYPE
} from "@modules/private/bank-accounts/data/enums/balance-transaction.enum"
import {ApiTransactionService} from "@modules/private/bank-accounts/data/services/api/api-transaction.service"


@Component({
  selector: 'app-massive-colocation',
  templateUrl: './massive-colocation.component.html',
  styleUrls: ['./massive-colocation.component.scss']
})
export class MassiveColocationComponent{
  @Input() bankAccountData: any
  @Output() onSubmitEvent = new EventEmitter<any>()
  uploadData: IMassiveColocation[] = []
  responseMessage: Message[] = []
  showMessage = false
  isUploading = false
  incomeSubType = 'APPLICATION'

  constructor(
    private messageService: MessageService,
    private loaderService: LoaderService,
    private ngxCsvParser: NgxCsvParser,
    private apiTransactionService: ApiTransactionService
) { }

  onUpload(event: any) {
    const files = event.files
    let isApplicationPayroll = false
    let isApplicationWorker = false
    this.isUploading = true

    this.loaderService.showSpinner()
    this.ngxCsvParser.parse(files[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
      next: (result: any): void => {
        this.loaderService.hideSpinner()
        this.uploadData = []
        let formatError = false
        let hasValidData = false
        let referenceType : string

        result.some((object: string[], key: number) => {
          if (key === 0 || object.every(value => !value || value.trim() === '')) {
            return false
          }
          hasValidData = true
          referenceType = object[0].toUpperCase()
          switch (referenceType) {
            case 'NOMINA':
              isApplicationPayroll = true
              break
            case 'TRABAJADOR':
              isApplicationWorker = true
              break
            default:
              this.displayErrorMessage('Error de formato', 'El archivo contiene referencia no conocida.')
              formatError = true
              return true
          }
          if ( this.incomeSubType === TRANSACTION_SUB_TYPE.APPLICATION && (isApplicationPayroll && isApplicationWorker)) {
            this.displayErrorMessage('Error de formato', 'El archivo contiene referencias mezcladas entre Nomina y Trabajador. Utiliza el documento correspondiente para cada referencia.')
            formatError = true
            return true
          }

          if ((referenceType === "TRABAJADOR" && object.length < 5) || (referenceType === "NOMINA" && object.length != 4)) {
            const requiredColumns = referenceType === "TRABAJADOR" ? 5 : 4
            this.displayErrorMessage('Error de formato', `El archivo no contiene suficientes columnas para el tipo ${referenceType}. Se requieren al menos ${requiredColumns} columnas.`)
            formatError = true
            return true
          }

          this.uploadData.push(this.mapUploadData(object, referenceType, TRANSACTION_TYPE.INCOME))
          return false
        })

        if (!hasValidData) {
          this.displayErrorMessage('Archivo vacío', 'El archivo no contiene datos válidos. Asegúrate de que las filas contengan información.')
          this.isUploading = false
          return
        }
        let hasNotErrors = true
        if (!formatError && this.uploadData.length > 0) {

          this.isUploading = false
          const totalAmount = this.uploadData.reduce((sum: number, data: any) => sum + data.amount, 0)
          if (totalAmount > this.bankAccountData.balance) {
            this.displayErrorMessage('Fondos Insuficientes', 'El monto total de los movimientos excede el monto disponible en esta cuenta bancaria.')
            this.isUploading = false
            hasNotErrors = false
            return
          }
          const bodyUploadData = {
            subType: this.incomeSubType,
            originAccount: this.bankAccountData._id,
            transactions:this.uploadData
          }
          this.apiTransactionService.massiveColocation(bodyUploadData).subscribe(r => {
            this.isUploading = false
            hasNotErrors = !r.error
            if (referenceType === "TRABAJADOR") {
              this.responseMessage = [
                {
                  summary: r.error ? 'Hubo un error al procesar el archivo':'El archivo se subió exitosamente',
                  severity: r.error ? 'error': 'success',
                  closable: true,
                  detail: (Object.keys(r.data?.applicationsIssues).length !== 0)  ? `${r.msg}: <a href="${r.data?.applicationsIssues?.urlReport}" target="_blank">Descargar reporte</a>`:''
                }]
            } else {
              this.responseMessage = [
                {
                  summary: r.error ? 'Hubo un error al procesar el archivo':'El archivo se subió exitosamente',
                  severity: r.error ? 'error': 'success',
                  closable: true,
                  detail: r?.msg
                }]
            }

            if (Object.keys(r?.data.businessErrors).length !== 0 && r?.data.businessErrors.status !== '') {
              hasNotErrors = false
              this.responseMessage.push({
                summary: 'Hubo un error al procesar el archivo',
                severity: r.data.transactionCompleted.length>=1 ? 'warn' : 'error',
                closable: true,
                detail: `${r?.data.businessErrors.msg}:
                      <a href="${r?.data.businessErrors.urlReport}" target="_blank">Descargar reporte</a>`,

              })
            }
            this.showMessage = true
            this.isUploading = false
            if (hasNotErrors) {
              setTimeout(() => {
                this.onSubmitEvent.emit()
              }, 2000)
            }
          })
        } else {
          this.showMessage = true
          this.isUploading = false
        }

      },
      error: (error: NgxCSVParserError): void => {
        console.error('Error', error)
        this.isUploading = false
      }
    })


  }

  private displayErrorMessage(summary: string, detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
      life: 8000
    })
  }

  private mapUploadData(object: string[], referenceType: string, transactionType: string): IMassiveColocation {
    if (referenceType === "TRABAJADOR") {
      return {
        type: transactionType,
        subType: this.incomeSubType,
        destinationType: 'WORKER',
        amount: Number(object[1]),
        creditFolio: object[2],
        applicationDate: object[3],
        trackingId: object[4],
        description: object[5] ? object[5] : 'Aplicación Masiva',
        originAccount: this.bankAccountData._id,
        clabe: this.bankAccountData.clabe,
      }
    } else {
      return {
        type: transactionType,
        subType: this.incomeSubType,
        destinationType: 'COMPANY',
        amount: Number(object[1]),
        company: object[2],
        description: object[3] ? object[3] : 'Aplicación Masiva',
        originAccount: this.bankAccountData._id
      }
    }
  }
}
