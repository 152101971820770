import {Component, Input, OnInit} from '@angular/core';
import {PushingService} from "@modules/private/pushing/data/services/pushing.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {IPushingFlowDetail, StageItem} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {PUSHING_FLOW_STATUS} from "../data/enums/pushing.enum";
import {LoaderService} from "@data/services/page/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
@Component({
  selector: 'app-pushing-detail',
  templateUrl: './pushing-detail.component.html',
  styleUrls: ['./pushing-detail.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class PushingDetailComponent implements OnInit {
  @Input() pushingId = ''
  stages: StageItem[] = []
  data!: IPushingFlowDetail
  $_pushing_flow_status = PUSHING_FLOW_STATUS
  items: MenuItem[] = []
  baseItems: MenuItem[] = [
    {
      label: 'Cancelar flujo',
      icon: 'fa-solid fa-times',
      command: () => {
        this.confirmAction('CANCEL_PUSHING')
      }
    },
    {
      label: 'Completar flujo',
      icon: 'fa-solid fa-check-double',
      command: () => {
        this.confirmAction('COMPLETE_PUSHING')
      }
    },
    {
      label: 'Reasignar flujo',
      icon: 'fa-solid fa-shuffle',
      command: () => {
        this.reassignFlow()
      }
    },
  ]
  entity!: FormGroup
  submitted: boolean = false;
  createEntity = false
  @Input() id = ''
  constructor(
    private pushingService: PushingService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private loanService: ApiLoanService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    if (this.id === '') {
      console.log("indsonasdofi")
      this.pushingId = this.route.snapshot.paramMap.get('id') as string
    } else {
      this.pushingId = this.id
    }
    console.log(this.pushingId, "miraaa")
    this.getPushing()
  }
  constructExtractedData() {

  }

  getPushing() {
    this.items = []
    this.pushingService.getById(this.pushingId).subscribe(r => {
      if (!r.error) {
        console.log("data", r.data)
        this.data = r.data
        this.stages = r.data.pushing.flowId.documents.map(d => {
          return {
            documentId: d.documentId._id,
            stage: d.order,
            title: d.documentId.title,
            subtitle: d.documentId.subtitle,
            type: d.documentId.type,
            daysToBeEffective: d.documentId.daysToBeEffective,
            dependsOn: r.data.pushing.flowId.documents.filter(fd => d.dependsOn.includes(fd.order)).map(fd => fd.documentId.title),
            status: r.data.flowData.find(fd => fd.documentId === d.documentId._id)?.status ?? 'NOT_STARTED',
            creationDate:  r.data.flowData.find(fd => fd.documentId === d.documentId._id)?.creationDate ?? null,
            lastUpdated:  r.data.flowData.find(fd => fd.documentId === d.documentId._id)?.updatedDate ?? null,
            comment:  r.data.flowData.find(fd => fd.documentId === d.documentId._id)?.comment ?? '',
            img: this.getImage(d.documentId.type)
          }
        })
        console.log(this.stages)
        if (this.data.pushing.status === 'IN_PROCESS' && !this.items.find(i => i.label === 'Crédito')) {
          this.items =  this.baseItems
          console.log(this.data.pushing)
          if (this.data.pushing.flowId.provider === 'PULPI' && this.data.pushing.productId === null) {
            this.items.push(
              {
                label: 'Crear crédito',
                icon: 'fa-solid fa-landmark',
                command: () => {
                  this.confirmAction('CREATE_LOAN')
                }
              },
            )
          }
        }
      }
    })
  }
  getImage(type: string) {
    switch (type) {
      case 'FORM':
        return 'FILL FORM.jpg'
      case 'DOCUMENT':
        return 'UPLOAD_DOCUMENT.jpg'
      case 'WEETRUST':
        return 'WEETRUST.jpg'
      case 'INCODE':
        return 'INCODE.jpg'
      case 'OFFER':
        return 'SELECT_OPTION.jpg'
      case 'EXTERNAL':
        return 'EXTERNAL.jpg'
      default:
        return 'DEFAULT.jpg'
    }
  }

  getCurrentStage(stage: string) {
    return this.data.pushing.flowId.documents.find(pf => pf.documentId.name === stage)?.documentId?.title ?? '-'
  }
  get getAdvance() {
    return Math.floor((this.data.pushing.progress / this.data.pushing.stages) * 100)
  }

  updateStatusPushing(id: string, status: string) {
    this.loaderService.showSpinner()
    this.pushingService.updateStatus({id, status}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        //this.hideDialog()
        this.getPushing()
      }
    })
  }

  confirmAction(action: string) {
    let header = 'Reasignar flujo'
    let message = '¿Estas seguro que deseas reasignar flujo?'
    let icon ='fa-regular fa-file-lines'
    switch (action) {
      case 'CANCEL_PUSHING':
        header = 'Cancelar Solicitud'
        message = '¿Estas seguro que quieres cancelar la solicitud?'
        icon = 'fa-solid fa-ban'
        break;
      case 'COMPLETE_PUSHING':
        header = 'Completar Solicitud'
        message = '¿Estas seguro que quieres completar la solicitud?'
        icon = 'fa-solid fa-check-double'
        break;
      case 'CREATE_LOAN':
        header = 'Crear crédito'
        message = '¿Estas seguro que quires crear un crédito?'
        icon = 'fa-regular fa-file-lines'
        break;
    }
    this.confirmationService.confirm({
      header,
      message,
      icon,
      rejectLabel: 'Cancelar',
      acceptLabel: 'Continuar',
      accept: () => {
        switch (action) {
          case 'CANCEL_PUSHING':
            this.updateStatusPushing(this.pushingId, 'CANCELLED')
            break;
          case 'COMPLETE_PUSHING':
            this.updateStatusPushing(this.pushingId, 'COMPLETED')
            break;
          case 'CREATE_LOAN':
            this.createLoanFromPushing(this.pushingId)
            break;
        }
      }
    });
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }
  reassignFlow() {
    this.entity = this.fb.group({
      flowId: ['', [Validators.required]],
      pushingId: [this.pushingId]
    })
    this.createEntity = true
    this.entity.clearValidators()
    this.submitted = false;
  }

  createLoanFromPushing(id: string) {
    this.loaderService.showSpinner()
    this.loanService.createFromOmnicanal({pushingId: id}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.router.navigate([`/loan/${r.data._id}`])
      }
    })
  }
  savePushing() {
    this.loaderService.showSpinner()
    this.pushingService.updatePushing(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 10000 });
      if (!r.error) {
        this.hideDialog()
        this.getPushing()
      }
    })
  }

}
