<p-card>
  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="mb-3">Nóminas</h1>
      <p class="text-color-secondary">
        Las nóminas estan compuestas por un grupo de colaboradores que se rigen por determinadas reglas, lo que permite cambiar su configuración en cuanto a los productos brindados asi como las restricciones con las que se gobierna a este grupo de personas.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtPayrolls
    [scrollable]="true"
    scrollHeight="600px"
    [value]="payrolls"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    [(selection)]="selectedPayrolls"
    [rowHover]="true"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} nóminas"
    [globalFilterFields]="['company.id.name','contractId.entity.name', 'contractId.remuneratorId.name', 'loanCreationFeeRate', 'loanRate','name','periodType', 'withdrawableSalaryRatio', 'withdrawalFee', 'creationDate']"
    (onFilter)="onFilter($event, dtPayrolls)"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtPayrolls.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
        </th>
        <th></th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter type="text" field="name" class="mt-1"></p-columnFilter>
            <p-sortIcon field="name" pSortableColumn="name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter type="text" field="companyId.name" class="mt-1"></p-columnFilter>
            <p-sortIcon field="companyId.name" pSortableColumn="companyId.name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter type="text" field="contractId.entity.name" class="mt-1"></p-columnFilter>
            <p-sortIcon field="contractId.entity.name" pSortableColumn="contractId.entity.name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter type="text" field="contractId.remuneratorId.name" class="mt-1"></p-columnFilter>
            <p-sortIcon field="contractId.remuneratorId.name" pSortableColumn="contractId.remuneratorId.name"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter field="periodType" matchMode="equals">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="periods" (onChange)="filter($event.value)" placeholder="Cualquiera">
                  <ng-template let-option pTemplate="item">
                    <p-tag [value]="option.value" [severity]="getSeverity(option.label)"></p-tag>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="periodType" pSortableColumn="periodType"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center">
            <p-columnFilter type="boolean" field="loansEnabled" class="mt-1"></p-columnFilter>
            <p-sortIcon field="loansEnabled" pSortableColumn="loansEnabled"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:15rem">
          <div class="flex align-items-center" >
            <p-columnFilter type="boolean" field="withdrawalsEnabled" class="mt-1"></p-columnFilter>
            <p-sortIcon field="withdrawalsEnabled" pSortableColumn="withdrawalsEnabled"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            <p-columnFilter type="numeric" field="loanCreationFeeRate" class="mt-1" [minFractionDigits]="2"></p-columnFilter>
            <p-sortIcon field="loanCreationFeeRate" pSortableColumn="loanCreationFeeRate"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            <p-columnFilter type="numeric" field="loanRate" class="mt-1" [minFractionDigits]="2"></p-columnFilter>
            <p-sortIcon field="loanRate" pSortableColumn="loanRate"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            <p-columnFilter type="numeric" field="withdrawalFee" currency="MXN" class="mt-1" [minFractionDigits]="2"></p-columnFilter>
            <p-sortIcon field="withdrawalFee" pSortableColumn="withdrawalFee"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            <p-columnFilter type="boolean" field="withdrawalFeeIsPercent" class="mt-1"></p-columnFilter>
            <p-sortIcon field="withdrawalFeeIsPercent" pSortableColumn="withdrawalFeeIsPercent"></p-sortIcon>
          </div>
        </th>
        <th style="min-width:20rem">
          <div class="flex align-items-center">
            <p-columnFilter type="date" field="creationDate" class="mt-1"></p-columnFilter>
            <p-sortIcon field="creationDate" pSortableColumn="creationDate"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('PAYROLL','UPDATE')">
          <th style="min-width:10rem">
          </th>
        </ng-container>
      </tr>
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Id de Nómina</th>
        <th>Nombre de la nómina</th>
        <th>Corporativo</th>
        <th>Convenio</th>
        <th>Pagadora</th>
        <th>Tipo de periodo</th>
        <th>Estatus de créditos</th>
        <th>Estatus de retiros</th>
        <th>Comisión por apertura del crédito</th>
        <th>Tasa de interes anual</th>
        <th>Comisión de retiro</th>
        <th>La comisión del retiro es porcentual</th>
        <th>Fecha de creación</th>
        <th>Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payroll>
      <tr>
        <td>
          <p-tableCheckbox [value]="payroll"></p-tableCheckbox>
        </td>
        <td>{{ payroll._id }}</td>
        <td>{{ payroll.name }}</td>
        <td>{{ payroll.companyId.name }}</td>
        <td>{{ payroll.contractId.entity.name}}</td>
        <td>{{ payroll.contractId.remuneratorId.name}}</td>
        <td><p-tag [value]="payroll.periodType" [severity]="getSeverity(payroll.periodType)"></p-tag></td>
        <td>
          <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': payroll.loansEnabled, 'text-red-500 pi-times-circle': !payroll.loansEnabled }"></i>
        </td>
        <td>
          <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': payroll.withdrawalsEnabled, 'text-red-500 pi-times-circle': !payroll.withdrawalsEnabled }"></i>
        </td>
        <td>{{ payroll.loanCreationFeeRate }}%</td>
        <td>{{ payroll.loanRate }}%</td>
        <td>{{ payroll.withdrawalFeeIsPercent ?  (payroll.withdrawalFee +'%' ) :(payroll.withdrawalFee  | currency) }}</td>
        <td>
          <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': payroll.withdrawalFeeIsPercent, 'text-red-500 pi-times-circle': !payroll.withdrawalFeeIsPercent }"></i>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{ payroll.creationDate | date: 'MM/dd/yyyy' }}</span>
        </td>

          <td>
            <ng-container *ngIf="authService.userHasAuthorization('PAYROLL','UPDATE')">
              <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', payroll)" class="p-button-rounded p-button-text"></button>
            </ng-container>
            <ng-container *ngIf="authService.userHasAuthorization('PAYROLL','UPDATE_CALENDAR')">
              <button pButton pRipple type="button" icon="fa-solid fa-calendar" [routerLink]="'/payroll/'+payroll._id+'/calendar'" class="p-button-rounded p-button-text"></button>
              <!--<button pButton pRipple type="button" icon="fa-solid fa-calendar" (click)="newEntity('CALENDAR', payroll)" class="p-button-rounded p-button-text"></button>-->
            </ng-container>
          </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron nóminas</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE AGREEMENT ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '90vw' }" [header]="formAction === 'CREATE' ? 'Nueva nómina' : ((formAction === 'CALENDAR') ?'Configurar calendario':'Actualizar nómina')" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <ng-container *ngIf="['CREATE', 'UPDATE'].includes(formAction)">
      <app-create-payroll [entity]="entity" [type]="formAction" [submitted]="submitted"></app-create-payroll>
    </ng-container>
    <ng-container *ngIf="formAction === 'CALENDAR' && currentPayrollId !== ''">
      <app-calendar-payroll [id]="currentPayrollId"></app-calendar-payroll>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngIf="['CREATE', 'UPDATE'].includes(formAction)">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
    </ng-container>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
