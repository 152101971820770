<ng-container *ngIf="accountDetail">
  <app-loader *ngIf="isLoading"></app-loader>
  <div class="grid">
    <div class="col-12">
      <div class="main-title">BALANCE GENERAL</div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Disponible:</h3>
      <p>{{ '$' + (accountDetail.balance | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Total de Ingresos:</h3>
      <p>{{ '$' + (incomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Total de Egresos:</h3>
      <p>{{ '$' + (outcomeData.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-12">
      <div class="main-title">MONTOS POR INGRESOS</div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Ingresos por Fondeadora:</h3>
      <p>{{ '$' + (fundingIncomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Ingresos por Pago de Empresas:</h3>
      <p>{{ '$' + (bankIncomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Total por Aplicar:</h3>
      <p>{{ '$' + (pendingIncomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-12">
      <div class="main-title">MONTOS POR EGRESOS</div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Pagos a Proveedores:</h3>
      <p>{{ '$' + (supplierOutcomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Pagos Generales:</h3>
      <p>{{ '$' + (generalOutcomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Colocación de Creditos:</h3>
      <p>{{ '$' + (creditOutcomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total de Colocación de SoD:</h3>
      <p>{{ '$' + (sodOutcomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Total por Aplicar:</h3>
      <p>{{ '$' + (pendingOutcomeTotal.totalAmount | number:'1.3-3') }}</p>
    </div>
  </div>
</ng-container>
