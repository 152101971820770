import {Component, Input} from '@angular/core';
import {IDocument, IFlowData} from "@modules/private/pushing/data/interfaces/ipushing.interface";
import {IWeetrustDocument} from "@modules/private/loan/data/interfaces/weetrusth.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ApiWeetrusthService} from "@modules/private/loan/data/services/api/api-weetrusth.service";

@Component({
  selector: 'app-pushing-record-manual-validation',
  templateUrl: './pushing-record-manual-validation.component.html',
  styleUrls: ['./pushing-record-manual-validation.component.scss']
})
export class PushingRecordManualValidationComponent {
  @Input() data!: IDocument
  @Input() flowId!: string
  @Input() values!: IFlowData | null
  isUpdating = false
  agreement!: IWeetrustDocument
  constructor(
    private fb: FormBuilder,
    private apiWeetrustService: ApiWeetrusthService
  ) {
  }

  getEntity() {
    const data = new FormData()
    data.append('value', '')
    data.append('documentId', this.data._id)
    data.append('flowId', this.flowId)
    return data
  }
}
