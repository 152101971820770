export enum WORKER_STATUS_ENUM {
  ACTIVE = 'Activo',
  PENDING_ACTIVATION = 'Pendiente de activación',
  DEACTIVATED = 'Desactivado'
}

export enum WORKER_STATUS_VERIFICATION_ENUM {
  PENDING_VERIFICATION = 'Pendiente de verificación',
  NO_REQUIRED_VERIFICATION = 'No require  verificación',
  COMPLETED = 'Verificación completada'
}

export enum WORKER_STATUS_ENUM_COLOR {
  ACTIVE = 'success',
  PENDING_ACTIVATION = 'warning',
  DEACTIVATED = 'danger'
}

export enum WORKER_STATUS_VERIFICATION_ENUM_COLOR {
  PENDING_VERIFICATION = 'danger',
  NO_REQUIRED_VERIFICATION = 'warning',
  COMPLETED = 'success'
}

export enum AFILIATION_ENUM {
  AFILIADO = 'Activo',
  DESAFILIADO = 'Baja'
}
export enum AFILIATION_ENUM_COLOR {
  AFILIADO = 'success',
  DESAFILIADO = 'danger'
}

export enum WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM {
  COMPLETED = 'Completa',
  INCOMPLETE = 'Incompleta'
}

export enum WORKER_MINIMUM_REQUIRED_DATA_STATUS_ENUM_COLOR {
  COMPLETED = 'success',
  INCOMPLETED = 'danger'
}

export enum PROVIDERS_ENUM {
  MAS_NOMINA = 'Más Nómina',
  PULPI = 'Pulpi',
  UNKNOWN = 'Desconocido',
}
