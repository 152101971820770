import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-founding-balance-modal',
  templateUrl: './founding-balance-modal.component.html',
  styleUrls: ['./founding-balance-modal.component.scss']
})
export class FoundingBalanceModalComponent implements OnInit {

  @Input() entity!: FormGroup;
  @Input() submitted!: boolean;
  @Input() fundingBalance: any;
  @Input() type: 'CREATE' | 'UPDATE' = 'CREATE';
  @Output() save = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    if (!this.entity) {
      this.entity = this.fb.group({
        balanceName: [this.fundingBalance?.balanceName || '', [Validators.required, Validators.minLength(3)]],
        balance: [{ value: this.type === 'UPDATE' ? this.fundingBalance?.balance || 0 : 0, disabled: this.type === 'UPDATE' }, [Validators.required, Validators.min(0)]],
      });
    } else {
      if (this.type === 'UPDATE') {
        this.entity.get('balance')?.setValue(this.fundingBalance?.balance || 0);
        this.entity.get('balance')?.disable();
      } else {
        this.entity.get('balance')?.setValue(0);
        this.entity.get('balance')?.enable();
      }
    }
  }

  onSubmit(): void {
    if (this.entity.valid) {
      const formValue = this.entity.getRawValue();
      this.save.emit(formValue);
    }
  }

}
