import { Injectable } from '@angular/core';
import {PDFDocument} from "pdf-lib";
import {firstValueFrom, map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {IInstallmentCalculated} from "@modules/private/loan/data/interfaces/installment.interface";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {GENDER_WORKER} from "@modules/private/workers/data/enums/gender.enum";
import {BANKS} from "@data/constants/banks.const";
import {KIND_HOUSE} from "@modules/private/workers/data/enums/kindHouse.enum";
import {CIVIL_STATUS} from "@modules/private/workers/data/enums/civilStatus.enum";

@Injectable({
  providedIn: 'root'
})
export class PdfAgreementService {
  path = '/assets/documents/contrato_creditos.pdf'
  LOAN_PERIOD_TYPE_NOUNS = {
    BIWEEKLY_PERIOD: 'catorcenas',
    MONTHLY_PERIOD: 'meses',
    SEMIMONTHLY_PERIOD: 'quincenas',
    WEEKLY_PERIOD: 'semanas'
  };
  LOAN_PERIOD_TYPE_ADJECTIVES = {
    BIWEEKLY_PERIOD: 'Catorcenal',
    MONTHLY_PERIOD: 'Mensual',
    SEMIMONTHLY_PERIOD: 'Quincenal',
    WEEKLY_PERIOD: 'Semanal'
  };
  BIRTHPLACE = {
    'AS': 'AGUASCALIENTES',
    'BC': 'BAJA CALIFORNIA',
    'BS': 'BAJA CALIFORNIA SUR',
    'CC': 'CAMPECHE',
    'CL': 'COAHUILA',
    'CM': 'COLIMA',
    'CS': 'CHIAPAS',
    'CH': 'CHIHUAHUA',
    'DF': 'DISTRITO FEDERAL',
    'DG': 'DURANGO',
    'GT': 'GUANAJUATO',
    'GR': 'GUERRERO',
    'HG': 'HIDALGO',
    'JC': 'JALISCO',
    'MC': 'MÉXICO',
    'MN': 'MICHOACÁN',
    'MS': 'MORELOS',
    'NT': 'NAYARIT',
    'NL': 'NUEVO LEÓN',
    'OC': 'OAXACA',
    'PL': 'PUEBLA',
    'QT': 'QUERÉTARO',
    'QR': 'QUINTANA ROO',
    'SP': 'SAN LUIS POTOSÍ',
    'SL': 'SINALOA',
    'SR': 'SONORA',
    'TC': 'TABASCO',
    'TS': 'TAMAULIPAS',
    'TL': 'TLAXCALA',
    'VZ': 'VERACRUZ',
    'YN': 'YUCATÁN',
    'ZS': 'ZACATECAS',
    'NE': 'NACIDO EN EL EXTRANJERO'
  }
  $_gendersEnum = GENDER_WORKER
  $_months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio','agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
  $_banks = BANKS
  $_kindHouses = KIND_HOUSE
  $_civilStatus = CIVIL_STATUS
  constructor(
    private httpService: HttpClient
  ) { }
  async getFile() {
    return this.httpService.get(this.path, {responseType: "arraybuffer"})
  }

  downloadPDF(url: string) {
    //const newUrl = url.replace('https://storage.googleapis.com', '')
    return this.httpService.get(url, { responseType: 'blob' }).pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      }))
  }

  async fillAgreement(data: ILoanPopulated, installments: IInstallmentCalculated[]) {
    const file = await firstValueFrom(await this.getFile())
    const pdf = await PDFDocument.load(file)
    const form = pdf.getForm();
    const gapTimeZone = 60 * 60 * 6 * 1000
    const dynamicData = data.additionalInformation ? JSON.parse(data.additionalInformation) : {}

    form.getTextField('loan-tracking-number').setText(data.folio)
    form.getTextField('worker-names').setText(`${dynamicData['firstName'] ?? data.positionId.workerId.names.firstName} ${dynamicData['secondName'] ?? data.positionId.workerId.names.secondName} ${dynamicData['fatherLastName'] ?? data.positionId.workerId.names.fatherLastName} ${dynamicData['motherLastName'] ?? data.positionId.workerId.names.motherLastName}`)

    const generatedDate = data.date ? new Date(data.date) : new Date()
    const federativeEntity = data.positionId.workerId.curp ? data.positionId.workerId.curp.substring(11,13) : ''
    const ingressDate = (new Date().getTime() - new Date(dynamicData['ingressDate'] ?? data.positionId.startDate ).getTime()) / (60 * 60 * 24 * 365 * 1000)
    const antiquity = ingressDate > 1 ? `${Math.floor(ingressDate)} Años`: `${Math.floor(ingressDate * 12)} Meses`

    console.log("data: ", data)
    form.getTextField('loan-date-day').setText(`${generatedDate.getDate()}`)
    form.getTextField('loan-date-month').setText(`${this.$_months[generatedDate.getMonth()]}`)
    form.getTextField('loan-date-year').setText(`${generatedDate.getFullYear()}`)
    form.getTextField('loan-yearly-rate').setText(`${(data.rate * 100).toLocaleString('es-MX', { maximumFractionDigits: 2 })}%`)
    form.getTextField('loan-monthly-rate').setText(`${((data.rate / 12) * 100).toLocaleString('es-MX', { maximumFractionDigits: 2 })}%`)
    form.getTextField('loan-amount').setText(`${data.amount.toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-payment-amount-sum').setText(`${(data.installmentCount * data.installmentAmount + data.installmentAccessoryAmount * data.installmentCount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-creation-fee').setText(`${(data.creationFee).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    // loan-total-amount-to-send, loan-insurance-accessory-amount, loan-other-accessory-amount, loan-accessory-administration-payment
    /**
     * New fields
     */
    const dispersionAmount = data.amount - data.accessoryCreation - data.creationFee
    form.getTextField('loan-total-amount-to-send').setText(`${(dispersionAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })} `)
    form.getTextField('loan-insurance-accessory-amount').setText(`${(0).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-other-accessory-amount').setText(`${(0).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-accessory-administration-payment').setText(`${(data.installmentAccessoryAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })} / ${this.LOAN_PERIOD_TYPE_ADJECTIVES[data.positionId.payrollId.periodType.toString() as 'WEEKLY_PERIOD' | 'BIWEEKLY_PERIOD' | 'SEMIMONTHLY_PERIOD' | 'MONTHLY_PERIOD']}`)
    /**
     * End new fields
     */
    //form.getTextField('loan-accessory-payment').setText(`${(data.accessoryAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-payment-count').setText(`${data.installmentCount}`)
    form.getTextField('loan-payment-amount').setText(`${(data.installmentAmount + data.installmentAccessoryAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    form.getTextField('loan-period-type').setText(`${this.LOAN_PERIOD_TYPE_ADJECTIVES[data.positionId.payrollId.periodType.toString() as 'WEEKLY_PERIOD' | 'BIWEEKLY_PERIOD' | 'SEMIMONTHLY_PERIOD' | 'MONTHLY_PERIOD']}`)
    form.getTextField('loan-term').setText(`${data.installmentCount} ${this.LOAN_PERIOD_TYPE_NOUNS[data.positionId.payrollId.periodType.toString() as 'WEEKLY_PERIOD' | 'BIWEEKLY_PERIOD' | 'SEMIMONTHLY_PERIOD' | 'MONTHLY_PERIOD']}`)
    form.getTextField('loan-date').setText(`${generatedDate.toLocaleDateString('es-MX', { day: 'numeric', month: 'long', year: 'numeric' })}`)
    console.log(new Date(installments[installments.length - 1].dueDate), installments[installments.length - 1].dueDate)
    form.getTextField('last-payment-date').setText(`${(new Date(new Date(installments[installments.length - 1].dueDate).getTime() + gapTimeZone)).toLocaleDateString('es-MX', { day: 'numeric', month: 'long', year: 'numeric' })}`)
    form.getTextField('payment-1-date').setText(`${(new Date(new Date(installments[0].dueDate).getTime() + gapTimeZone)).toLocaleDateString('es-MX', { day: 'numeric', month: 'long', year: 'numeric' })}`)
    form.getTextField('contract-entity-name').setText(`${data.positionId.payrollId.contractId.entity.name}`)
    form.getTextField('worker-rfc').setText(`${data.positionId.workerId.rfc}`)
    form.getTextField('País de Nacimiento').setText(`${federativeEntity === 'NE' ? 'Extranjero' : 'México'}`)
    form.getTextField('Nacionalidad').setText(`${federativeEntity === 'NE' ? 'Extranjero' : 'Mexicana'}`)
    form.getTextField('worker-birth-date').setText(`${data.positionId.workerId.birthDate ?  data.positionId.workerId.birthDate.toString().substring(0,10): ''}`)
    form.getTextField('Entidad de Nacimiento').setText(`${this.BIRTHPLACE[federativeEntity as keyof typeof this.BIRTHPLACE]}`)
    console.log(antiquity)
    form.getTextField('Antigüedad').setText(antiquity)
    form.getTextField('position-name').setText(data.positionId.name)
    form.getTextField('position-monthly-salary').setText((data.positionId.salary / 12).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' }))

    if(dynamicData['gender']) form.getTextField('worker-sex').setText(`${new SelectEnumPipe().transform(dynamicData['gender'], this.$_gendersEnum) }`)
    if(dynamicData['CAT']) form.getTextField('CAT').setText(`${dynamicData['CAT']}`)
    if(dynamicData['employeeNumber']) form.getTextField('Número de Empleado').setText(`${dynamicData['employeeNumber']}`)
    if(dynamicData['loan_clabe']) {
      const digitBank = dynamicData['loan_clabe'].toString().substring(0,3)
      const bank = this.$_banks.find(b => b.digito === digitBank)
      if(bank) form.getTextField('loan-account-bank-name').setText(`${bank.banco}`)
    }
    if(dynamicData['phoneNumber']) form.getTextField('worker-phone-number').setText(`${dynamicData['phoneNumber']}`)
    if(dynamicData['phoneNumber']) form.getTextField('Número de Teléfono').setText(`${dynamicData['phoneNumber']}`)
    if(dynamicData['address_cp']) form.getTextField('Domicilio del Empleado').setText(
      `
      ${dynamicData['address_street'] ?? ''} ${dynamicData['address_externalNumber'] ?? ''} ${dynamicData['address_internalNumber'] ?? ''} ${dynamicData['address_neighborhood'] ?? ''} ${dynamicData['address_cp'] ?? ''} ${dynamicData['address_delegation'] ?? ''} ${dynamicData['address_state'] ?? ''} ${dynamicData['address_city'] ?? ''}
      `
    )
    if(dynamicData['maritalStatus']) form.getTextField('Estado Civil').setText(`${new SelectEnumPipe().transform(dynamicData['maritalStatus'], this.$_civilStatus)}`)
    if(dynamicData['curp']) form.getTextField('worker-curp').setText(`${dynamicData['curp']}`)
    if(dynamicData['address_country']) form.getTextField('País').setText(`${dynamicData['address_country']}`)
    if(dynamicData['address_street']) form.getTextField('Calle').setText(`${dynamicData['address_street']}`)
    if(dynamicData['address_externalNumber']) form.getTextField('Número Exterior').setText(`${dynamicData['address_externalNumber']}`)
    if(dynamicData['address_internalNumber']) form.getTextField('Número Interior').setText(`${dynamicData['address_internalNumber']}`)
    if(dynamicData['address_neighborhood']) form.getTextField('Colonia').setText(`${dynamicData['address_neighborhood']}`)
    if(dynamicData['address_delegation']) form.getTextField('Municipio').setText(`${dynamicData['address_delegation']}`)
    if(dynamicData['address_cp']) form.getTextField('Código Postal').setText(`${dynamicData['address_cp']}`)
    if(dynamicData['address_state']) form.getTextField('Estado').setText(`${dynamicData['address_state']}`)
    if(dynamicData['country']) form.getTextField('País').setText(`${dynamicData['country']}`)
    if(dynamicData['address_betweenStreets']) form.getTextField('Entre Calles').setText(`${dynamicData['address_betweenStreets']}`)
    if(dynamicData['emailAddress']) form.getTextField('worker-email-address').setText(`${dynamicData['emailAddress']}`)
    if(dynamicData['loan_clabe']) form.getTextField('loan-account-number').setText(`${dynamicData['loan_clabe']}`)
    if(dynamicData['personal_reference_firstName']) form.getTextField('Nombre de la Referencia').setText(`${dynamicData['personal_reference_firstName']} ${dynamicData['personal_reference_lastName']}`)
    if(dynamicData['personal_reference_phone']) form.getTextField('Número de Teléfono 1 de la Referencia').setText(`${dynamicData['personal_reference_phone']}`)
    if(dynamicData['aval_address_cp']) form.getTextField('Domicilio del Aval').setText(
      `
      ${dynamicData['aval_address_street'] ?? ''} ${dynamicData['aval_address_externalNumber'] ?? ''} ${dynamicData['aval_address_internalNumber'] ?? ''} ${dynamicData['aval_address_neighborhood'] ?? ''} ${dynamicData['aval_address_cp'] ?? ''} ${dynamicData['aval_address_delegation'] ?? ''} ${dynamicData['aval_address_state'] ?? ''} ${dynamicData['aval_address_city'] ?? ''}
      `
    )
    if(dynamicData['aval_reference_firstName']) form.getTextField('Nombre del Aval').setText(`${dynamicData['aval_reference_firstName']} ${dynamicData['aval_reference_lastName']}`)
    if(dynamicData['aval_reference_phone']) form.getTextField('Número de Teléfono 1 del Aval').setText(`${dynamicData['aval_reference_phone']}`)
    if(dynamicData['aval_address_cp']) form.getTextField('Código Postal del Aval').setText(`${dynamicData['aval_address_cp']}`)
    if(dynamicData['aval_address_delegation']) form.getTextField('Municipio del Aval').setText(`${dynamicData['aval_address_delegation']}`)
    if(dynamicData['aval_address_state']) form.getTextField('Estado del Aval').setText(`${dynamicData['aval_address_state']}`)
    if(dynamicData['aval_address_country']) form.getTextField('País del Aval').setText(`${dynamicData['aval_address_country']}`)
    if(dynamicData['aval_emailAddress']) form.getTextField('Correo Electrónico del Aval').setText(`${dynamicData['aval_emailAddress']}`)
    if(dynamicData['kindHouse']) form.getTextField('Tipo de Vivienda').setText(`${new SelectEnumPipe().transform(dynamicData['kindHouse'], this.$_kindHouses)}`)
    if(dynamicData['timeHouse']) form.getTextField('Tiempo de residencia').setText(`${dynamicData['timeHouse']}`)

    // Add installments
    let balance = installments.reduce((sum, installment ) => sum + installment.principalAmount + installment.revenueAmount + installment.accessoryPrincipalAmount + installment.accessoryRevenueAmount, 0)
    console.log(installments)
    for (const key in installments) {
      balance = balance - installments[key].principalAmount - installments[key].revenueAmount - installments[key].accessoryPrincipalAmount - installments[key].accessoryRevenueAmount
      form.getTextField(`payment-${+key + 1}-date`).setText(`${installments[key].dueDate.toString().substring(0,10)}`)
      form.getTextField(`payment-${+key + 1}-amount`).setText(`${(installments[key].principalAmount + installments[key].revenueAmount + installments[key].accessoryPrincipalAmount + installments[key].accessoryRevenueAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
      form.getTextField(`payment-${+key + 1}-principal-amount`).setText(`${(installments[key].principalAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
      form.getTextField(`payment-${+key + 1}-revenue-amount`).setText(`${(installments[key].revenueAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
      form.getTextField(`payment-${+key + 1}-accessory`).setText(`${(installments[key].accessoryPrincipalAmount + installments[key].accessoryRevenueAmount).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
      form.getTextField(`payment-${+key + 1}-balance`).setText(`${(balance).toLocaleString('es-MX', { currency: 'MXN', style: 'currency' })}`)
    }
    const newPdf = await pdf.save()
    const newPdfBlob = new Blob([newPdf], {
      type: 'application/pdf'
    })
    window.open(window.URL.createObjectURL(newPdfBlob))
  }
}
