<p-card>
  <div class="row">
    <div class="col-12 mb-5 mb-3">
      <h1 class="mb-3">Cuentas Bancarias</h1>
      <p class="text-color-secondary">
        Las Cuentas Bancarias son entidades que permiten a los usuarios realizar operaciones financieras, como depósitos, transferencias y pagos. Cada cuenta está asociada a PULPI y se utiliza para gestionar fondos de manera segura.
      </p>
      <p-toast></p-toast>
    </div>
  </div>
  <p-table
    #dtAccounts
    [scrollable]="true"
    scrollHeight="600px"
    [value]="accounts"
    [columns]="cols"
    dataKey="_id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [loading]="loading"
    [paginator]="true"
    [tableStyle]="{'width': '100%'}"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cuentas bancarias"
    [globalFilterFields]="['name', 'agreementDate', 'businessName', 'bank', 'clabe', 'creationDate']">
    <ng-template pTemplate="caption">
      <div class="flex flex-row justify-content-between">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
        <div>
          <p-multiSelect [options]="allCols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columnas seleccionadas" [style]="{'min-width': '200px'}" placeholder="Escoge las columnas" (onChange)="onColumnToggle($event)"></p-multiSelect>
        </div>
        <div>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dtAccounts.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [style.min-width.px]="150">
          <div class="flex align-items-center" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-columnFilter
              *ngIf="col.field !== 'isLoanTransactionDefault' && col.field !== 'isSoDTransactionDefault' && col.field !== 'agreementDate' && col.field !== 'creationDate'"
              [type]="col.type || 'text'"
              [field]="col.field"
              display="menu"
              class="mt-1"
            ></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'isLoanTransactionDefault'"
                            type="boolean"
                            field="isLoanTransactionDefault"
            ></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'isSoDTransactionDefault'"
                            type="boolean"
                            field="isSoDTransactionDefault"
            ></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'agreementDate' || col.field === 'creationDate'"
                            type="date"
                            [field]="col.field"
                            display="menu"
                            class="mt-1"
            ></p-columnFilter>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </div>
        </th>
        <ng-container *ngIf="authService.userHasAuthorization('BANK_ACCOUNT', 'UPDATE')">
          <th style="min-width:10rem">
            Acciones
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account>
      <tr>
        <td *ngFor="let col of cols">
          <ng-container
            *ngIf="col.field !== 'agreementDate' &&
            col.field !== 'creationDate' &&
            col.field !== 'isLoanTransactionDefault' &&
            col.field !== 'isSoDTransactionDefault'
          ">
            <a class="link-subsection" *ngIf="col.field === 'name'" [routerLink]="['/bankAccount', account._id]">{{ account[col.field] }}</a>
            <span *ngIf="col.field !== 'name'">{{ account[col.field] }}</span>
          </ng-container>
          <ng-container *ngIf="col.field === 'agreementDate'">
            {{ account.agreementDate | date: 'MM/dd/yyyy' }}
          </ng-container>
          <ng-container *ngIf="col.field === 'creationDate'">
            {{ account.creationDate | date: 'MM/dd/yyyy'}}
          </ng-container>
          <ng-container *ngIf="col.field === 'isLoanTransactionDefault'">
            <i class="pi" [ngClass]="{
          'text-green-500 pi-check-circle': account.isLoanTransactionDefault === true,
          'text-red-500 pi-times-circle': account.isLoanTransactionDefault === false
          }"></i>
          </ng-container >
          <ng-container *ngIf="col.field === 'isSoDTransactionDefault'">
            <i class="pi" [ngClass]="{
          'text-green-500 pi-check-circle': account.isSoDTransactionDefault === true,
          'text-red-500 pi-times-circle': account.isSoDTransactionDefault === false
          }"></i>
          </ng-container >
        </td>
        <ng-container *ngIf="authService.userHasAuthorization('BANK_ACCOUNT', 'UPDATE')">
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" (click)="newEntity('UPDATE', account)" class="p-button-rounded p-button-text"></button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No se encontraron pagadoras</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<!------------------------CREATE BANK ACCOUNT ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '25vw' }" [header]="formAction === 'CREATE' ? 'Nueva Cuenta de Banco' : 'Actualizar Cuenta de Banco'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-accounts [entity]="entity" [type]="formAction" [submitted]="submitted"></app-create-accounts>
    <div class="row">
      <div class="col-12 flex justify-content-end flex-direction-row">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()" [disabled]="!entity.valid"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

