import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ListCompanyComponent} from "@modules/private/company/list-company/list-company.component";
import {ListPayerComponent} from "@modules/private/payer/list-payer/list-payer.component";
import {ListAgreementComponent} from "@modules/private/agreement/list-agreement/list-agreement.component";
import {ListPayrollComponent} from "@modules/private/payroll/list-payroll/list-payroll.component";
import {ListWorkerComponent} from "@modules/private/workers/list-worker/list-worker.component";
import {ListLoanComponent} from "@modules/private/loan/list-loan/list-loan.component";
import {ListWithdrawalComponent} from "@modules/private/withdrawal/list-withdrawal/list-withdrawal.component";
import {ListPaymentComponent} from "@modules/private/payment/list-payment/list-payment.component";
import {ListInstallmentComponent} from "@modules/private/installment/list-installment/list-installment.component";
import {MassiveWorkerComponent} from "@modules/private/workers/massive-worker/massive-worker.component";
import {DetailWorkerComponent} from "@modules/private/workers/detail-worker/detail-worker.component";
import {DetailLoanComponent} from "@modules/private/loan/detail-loan/detail-loan.component";
import {ListApplicationComponent} from "@modules/private/application/list-application/list-application.component";
import {
  MassiveApplicationComponent
} from "@modules/private/application/massive-application/massive-application.component";
import {LoginComponent} from "@modules/public/login/login.component";
import {NoAuthGuard} from "@app/no-auth.guard";
import {AuthGuard} from "@app/auth.guard";
import {LogoutComponent} from "@modules/private/logout/logout.component";
import {
  PlatformSettingsComponent
} from "@modules/private/platform-settings/platform-settings/platform-settings.component";
import {ReportsDetailComponent} from "@modules/private/reports/reports-detail/reports-detail.component";
import {PushingListComponent} from "@modules/private/pushing/pushing-list/pushing-list.component";
import {PushingDetailComponent} from "@modules/private/pushing/pushing-detail/pushing-detail.component";
import {HomeDashboardComponent} from "@modules/private/home/home-dashboard/home-dashboard.component";
import {ListAdminComponent} from "@modules/private/admin/list-admin/list-admin.component";
import {ListAccesoryComponent} from "@modules/private/accesory/list-accesory/list-accesory.component";
import {ListWriteOffComponent} from "@modules/private/write-off/list-write-off/list-write-off.component";
import {MassiveAssignmentComponent} from "@modules/private/assignment/massive-assignment/massive-assignment.component";
import {ListFinancialComponent} from "@modules/private/financial/list-financial/list-financial.component";
import {DetailFinancialComponent} from "@modules/private/financial/detail-financial/detail-financial.component";
import {ListSupplyComponent} from "@modules/private/supply/list-supply/list-supply.component";
import {ListAccountsComponent} from "@modules/private/bank-accounts/list-accounts/list-accounts.component";
import {DetailAccountsComponent} from "@modules/private/bank-accounts/detail-accounts/detail-accounts.component";
import {
  CalendarPayrollComponent
} from "@modules/private/payroll/components/calendar-payroll/calendar-payroll.component";



const routes: Routes = [
  {
    path: '',
    component: HomeDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    component: ListCompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: ListAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accessory',
    component: ListAccesoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payer',
    children: [
      {
        path: '',
        component: ListPayerComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'financial',
    children: [
      {
        path: '',
        component: ListFinancialComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: DetailFinancialComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'supply',
    children: [
      {
        path: '',
        component: ListSupplyComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'bankAccount',
    children: [
      {
        path: '',
        component: ListAccountsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: DetailAccountsComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'agreement',
    children: [
      {
        path: '',
        component: ListAgreementComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'payroll',
    children: [
      {
        path: '',
        component: ListPayrollComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id/calendar',
        component: CalendarPayrollComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'worker',
    children: [
      {
        path: '',
        component: ListWorkerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'massive',
        component: MassiveWorkerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: DetailWorkerComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'loan',
    children: [
      {
        path: '',
        component: ListLoanComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: DetailLoanComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'assignment',
    children: [
      {
        path: '',
        component: MassiveAssignmentComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'withdrawal',
    children: [
      {
        path: '',
        component: ListWithdrawalComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'payment',
    children: [
      {
        path: '',
        component: ListPaymentComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'installment',
    children: [
      {
        path: '',
        component: ListInstallmentComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'application',
    children: [
      {
        path: '',
        component: ListApplicationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'apply',
        component: MassiveApplicationComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'platform-settings',
    component: PlatformSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'omnicanal',
    children: [
      {
        path: '',
        component: PushingListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: PushingDetailComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'writeoff',
    component: ListWriteOffComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
