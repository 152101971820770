import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {IPayroll} from "@modules/private/payroll/data/interfaces/ipayroll.interface";
import {ApiPayrollService} from "@modules/private/payroll/data/services/api/api-payroll.service";
import {TYPE_PERIOD_ENUM, TYPE_PERIOD_ENUM_ES} from "@modules/private/company/data/enums/typePeriods.enum";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {LoaderService} from "@data/services/page/loader.service";

@Component({
  selector: 'app-list-payroll',
  templateUrl: './list-payroll.component.html',
  styleUrls: ['./list-payroll.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ListPayrollComponent implements OnInit{
  payrolls!: IPayroll[];
  selectedPayrolls!: IPayroll[] | null;
  payrollsFiltered!: IPayroll[]
  cols: any[] = [
    {field: '_id', header: 'id'},
    {field: 'name', header: 'Nombre de nomina'},
    {field: 'companyId.name', header: 'Nombre de empresa'},
    {field: 'contractId.entity.name', header: 'Nombre de convenio'},
    {field: 'contractId.remuneratorId.name', header: 'Nombre de pagadora'},
    {field: 'periodType', header: 'Tipo de periodo'},
    {field: 'loanCreationFeeRate', header: 'Comision por apertura'},
    {field: 'loanRate', header: 'Tasa de interes anual'},
    {field: 'withdrawableSalaryRatio', header: 'Porcentaje disponible del salario'},
    {field: 'withdrawalFee', header: 'Comisión del retiro'},
    {field: 'loansEnabled', header: 'Estatus de creditos'},
    {field: 'withdrawalsEnabled', header: 'Estatus de retiros'},
    {field: 'creationDate', header: 'Fecha de creacion'}
  ]
  colsPdf: any[] = [
    {dataKey: '_id', title: 'Id de nómina'},
    {dataKey: 'name', title: 'Nombre de nómina'},
    {dataKey: 'companyName', title: 'Nombre del corporativo'},
    {dataKey: 'contractName', title: 'Nombre del convenio'},
    {dataKey: 'payerName', title: 'Nombre de la pagadora'},
    {dataKey: 'periodType', title: 'Tipo de periodo'},
    {dataKey: 'loanCreationFeeRate', title: 'Comisión por apertura'},
    {dataKey: 'loanRate', title: 'Tasa de interés anual'},
    {dataKey: 'withdrawableSalaryRatio', title: 'Porcentaje disponible del salario'},
    {dataKey: 'withdrawalFee', title: 'Comisión del retiro'},
    {dataKey: 'loansEnabled', title: 'Estatus de créditos'},
    {dataKey: 'withdrawalsEnabled', title: 'Estatus de retiros'},
    {dataKey: 'creationDate', title: 'Fecha de creación'}
  ]
  loading: boolean = true;
  periods = [
    {label: 'Mensual', value: 'Mensual'},
    {label: 'Quincenal', value: 'Quincenal'},
    {label: 'Catorcenal', value: 'Catorcenal'},
    {label: 'Semanal', value: 'Semanal'}
  ]
  items: MenuItem[] = [];
  entity!: FormGroup
  submitted = false
  formAction : 'CREATE' | 'UPDATE' | 'CALENDAR' = 'CREATE'
  createEntity = false
  currentPayrollId = ''
  @ViewChild('dtPayrolls') dtPayrolls!: Table;
  constructor(
    private apiPayrollService: ApiPayrollService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private fb: FormBuilder,
    public authService: AuthService,
    private loaderService: LoaderService
  ) {
    this.createMenu()
  }

  ngOnInit() {
   this.find()
  }

  onFilter(event: any, dt:any) {
    this.payrollsFiltered = event.filteredValue
  }

  find() {
    this.loading = true
    this.apiPayrollService.getAll().subscribe(r => {
      if(!r.error) {
        this.payrolls = r.data.map(r => {
          r.creationDate = new Date(r.creationDate)
          r.periodType = TYPE_PERIOD_ENUM[`${r.periodType}` as keyof typeof TYPE_PERIOD_ENUM]
          return r
        })
        this.payrollsFiltered = this.payrolls
      }
      this.loading = false
    })
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  lockWithdrawals() {
    console.log(this.selectedPayrolls)
  }
  transformData() {
    return this.payrollsFiltered.map((i) => {
      return {
        id: i._id,
        'Nombre': i.name,
        'Nombre de empresa': i.companyId.name,
        'Nombre de convenio': i.contractId.entity.name,
        'Nombre de pagadora': i.contractId.remuneratorId.name,
        'Tipo de periodo': i.periodType,
        'Comisión por apertura': i.loanCreationFeeRate,
        'Tasa de interés anual': i.loanRate,
        'Porcentaje disponible del salario': i.withdrawableSalaryRatio,
        'Comisión del retiro': i.withdrawalFee,
        'Estatus de creditos': i.loansEnabled,
        'Estatus de retiros': i.withdrawalsEnabled,
        'Fecha de creacion': i.creationDate
      }
    })
  }

  hideDialog() {
    this.createEntity = false;
    this.submitted = false;
  }

  newEntity(formAction: 'CREATE' | 'UPDATE' | 'CALENDAR', data: IPayroll | null = null ) {
    console.log(data?._id)
    this.currentPayrollId = data?._id ?? ''
    this.formAction = formAction
    console.log(data)
    if(formAction === 'CREATE') {
      this.entity = this.fb.group({
        name: [ '', [Validators.required, Validators.minLength(3)]],
        contractId: [ '', [Validators.required, Validators.minLength(24)]],
        companyId: [ '', [Validators.required, Validators.minLength(24)]],
        loanCreationFeeRate: [ 0, [Validators.required, Validators.min(0)]],
        loanRate: [ 0, [Validators.required, Validators.min(0)]],
        loansEnabled: [ false, [Validators.required]],
        periodType: [ '', [Validators.required]],
        withdrawableSalaryRatio: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
        withdrawalFee: [0, [Validators.required, Validators.min(0)]],
        withdrawalFeeIsPercent: [false, [Validators.required]],
        typeWithdrawalCalculation: ['', [Validators.required]],
        withdrawalsEnabled: [false, [Validators.required]],
        amountByWithdrawal: [0, [Validators.required, Validators.min(0)]],
        daysBeforeForIncidence: [0, [Validators.required, Validators.min(0)]],
        daysBeforeForCutOffDate: [0, [Validators.required, Validators.min(0)]],
        firstDay: ['', [Validators.required]],
        daysToGenerateTheFirstInstallment: [0, [Validators.required, Validators.min(0)]],
        secondDay: [''],
        typeCalculation: ['', [Validators.required]]
      })

    } else {
      console.log(data)
      const startDate = (data?.firstPeriodEndDates[0]) ? (new Date(data?.firstPeriodEndDates[0]?.toString().replace('Z', ''))): null
      const secondDate =  (data?.firstPeriodEndDates[1]) ? (new Date(data?.firstPeriodEndDates[1]?.toString().replace('Z', ''))): null
      this.entity = this.fb.group({
        id: [data?._id],
        name: [ data?.name, [Validators.required, Validators.minLength(3)]],
        contractId: [ data?.contractId._id, [Validators.required, Validators.minLength(24)]],
        companyId: [ data?.companyId._id, [Validators.required, Validators.minLength(24)]],
        loanCreationFeeRate: [ data?.loanCreationFeeRate, [Validators.required, Validators.min(0)]],
        loanRate: [ data?.loanRate, [Validators.required, Validators.min(0)]],
        loansEnabled: [ data?.loansEnabled, [Validators.required]],
        periodType: [ TYPE_PERIOD_ENUM_ES[`${ data?.periodType}` as keyof typeof TYPE_PERIOD_ENUM_ES], [Validators.required]],
        withdrawableSalaryRatio: [data?.withdrawableSalaryRatio, [Validators.required, Validators.min(0), Validators.max(100)]],
        withdrawalFee: [data?.withdrawalFee, [Validators.required, Validators.min(0)]],
        withdrawalFeeIsPercent: [data?.withdrawalFeeIsPercent, [Validators.required]],
        typeWithdrawalCalculation: [data?.typeWithdrawalCalculation, [Validators.required]],
        withdrawalsEnabled: [data?.withdrawalsEnabled, [Validators.required]],
        amountByWithdrawal: [data?.amountByWithdrawal, [Validators.required,  Validators.min(0)]],
        firstDate: [(startDate) ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1 < 10) ? 0 :''}${startDate.getMonth() + 1}-${(startDate.getDate() < 10) ? 0 :''}${startDate.getDate()}`: '' , [Validators.required]],
        secondDate: [(secondDate) ? `${secondDate.getFullYear()}-${(secondDate.getMonth() + 1 < 10) ? 0 :''}${secondDate.getMonth() + 1}-${(secondDate.getDate() < 10) ? 0 :''}${secondDate.getDate()}`: ''],
        daysBeforeForIncidence: [data?.daysBeforeForIncidence, [Validators.required, Validators.min(0)]],
        daysBeforeForCutOffDate: [data?.daysBeforeForCutOffDate, [Validators.required, Validators.min(0)]],
        firstDay: [data?.firstDay ?? '', [Validators.required]],
        daysToGenerateTheFirstInstallment: [data?.daysToGenerateTheFirstInstallment, [Validators.required, Validators.min(0)]],
        secondDay: [data?.secondDay ?? ''],
        typeCalculation: [data?.typeCalculation ?? '', [Validators.required]]
      })
    }
    this.entity.get('periodType')?.valueChanges.subscribe(value => {
      this.validateFirstDate()
      this.validateSecondDate()
    })
    this.entity.get('secondDate')?.valueChanges.subscribe(value => {
      this.validateFirstDate()
      this.validateSecondDate()
    })

    this.entity.get('firstDate')?.valueChanges.subscribe(value => {
      this.validateFirstDate()
      this.validateSecondDate()
    })
    this.entity.clearValidators()
    this.submitted = false;
    this.createEntity = true;
  }

  saveProduct() {
    this.submitted = true
    this.loaderService.showSpinner()
    const api = this.formAction === 'CREATE' ? this.apiPayrollService.create({...this.entity.value, firstPeriodEndDates: [this.entity.value.firstDate, this.entity.value.secondDate]}) : this.apiPayrollService.patch({...this.entity.value, firstPeriodEndDates: [this.entity.value.firstDate, this.entity.value.secondDate]})
    api.subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 7000 });
      if (!r.error ) {
        this.hideDialog()
        this.find()
      }
      this.loaderService.hideSpinner()
    })
  }

  validateFirstDate() {
    const firstDate = this.entity.get('firstDate')?.value
    if (new Date(firstDate).getTime() + (60 * 60 * 23 * 1000) > new Date().getTime()) this.entity.get('firstDate')?.setErrors({invalidDate: true})
    else {
      this.entity.get('firstDate')?.removeValidators(Validators.required)
      this.entity.get('firstDate')?.setErrors(null)
    }
  }

  validateSecondDate() {
    const firstDate = this.entity.get('firstDate')?.value
    const secondDate = this.entity.get('secondDate')?.value
    const periodType = this.entity.get('periodType')?.value
    if(periodType === 'SEMIMONTHLY_PERIOD') {
      this.entity.get('secondDate')?.setValidators(Validators.required)
      if(this.entity.get('secondDate')?.value === '') this.entity.get('secondDate')?.setErrors({required: true})
      if(firstDate !== '' && secondDate !== '' && new Date(firstDate).getTime() >= new Date(secondDate).getTime() || (firstDate !== '' && secondDate !== '' && (new Date(secondDate).getTime() + (60 * 60 * 23 * 1000)) > new Date().getTime())) this.entity.get('secondDate')?.setErrors({invalidDate: true})
    } else {
      this.entity.get('secondDate')?.removeValidators(Validators.required)
      this.entity.get('secondDate')?.setErrors(null)
    }
  }

  getSeverity(status: string) {
    switch (status) {
      case 'Mensual':
        return 'danger';

      case 'Quincenal':
        return 'success';

      case 'Catorcenal':
        return 'info';

      case 'Semanal':
        return 'warning';
      default:
        return ''
    }
  }

  /**
   * Method to create the menu in base of permissions
   */
  createMenu() {
    if (this.authService.userHasAuthorization('PAYROLL','EXPORT')) {
      this.items.push( {
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'CSV',
            command: () => {
              this.dtPayrolls.exportCSV()
              this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
            }
          },
          {
            label: 'Excel',
            command: () => {
              this.dataTableService.exportExcel(this.transformData())
              this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.dataTableService.exportPdf(this.colsPdf, this.transformData())
              this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
            }
          }
        ]
      })
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.find()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtPayrolls.clear()
          this.find()
        }
      },
    ]
    if (this.authService.userHasAuthorization('PAYROLL', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-square-plus',
          command: () => {
            this.newEntity('CREATE')
          }
        }
      ]
    }
  }

}
