import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ILoanOwnership, ILoanPopulated} from "@modules/private/loan/data/interfaces/iloan.interface";
import {ApiInstallmentService} from "@modules/private/installment/data/services/api/api-installment.service";
import {IInstallment} from "@modules/private/installment/data/interfaces/iinstallment.interface";
import {
  LOAN_PAYMENT_STATUS_ENUM,
  LOAN_STATUS_ENUM,
  LOAN_STATUS_ENUM_COLOR
} from "@modules/private/loan/data/enums/loan.enum";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {Table} from "primeng/table";
import {DataTableService} from "@data/services/utils/data-table.service";
import {ApiPaymentService} from "@modules/private/payment/data/services/api/api-payment.service";
import {IPayment} from "@modules/private/payment/data/interfaces/ipayment.interface";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WriteOffService} from "@modules/private/write-off/data/services/write-off.service";
import {ApiWriteOffService} from "@modules/private/write-off/data/services/api/api-write-off.service";
import {
  WRITE_OFF_CONCEPT,
  WRITE_OFF_STATUS_ENUM,
  WRITE_OFF_STATUS_ENUM_ES
} from "@modules/private/write-off/data/enums/writeOff.enum";
import {LoaderService} from "@data/services/page/loader.service";
import {ApiLoanService} from "@modules/private/loan/data/services/api/api-loan.service";
console.log("Intentando generar el PDF...");
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
console.log("Librería pdf-lib importada correctamente.");

import {Router} from "@angular/router";

@Component({
  selector: 'app-loan-resume',
  templateUrl: './loan-resume.component.html',
  styleUrls: ['./loan-resume.component.scss'],
  providers: [MessageService, DatePipe, CurrencyPipe, ConfirmationService]
})
export class LoanResumeComponent implements OnInit, AfterViewInit {
  @Input() data!: ILoanPopulated & { amountToPay: number}
  installments:IInstallment[] = []
  payments: {
    payment: IPayment,
    application: {
      amountApplied: number;
      admin: string;
      dateApplication: Date;
      status: string;
      schemaApplication: {
        moraInterest: number;
        ivaMoraInterest: number;
        ivaAccessoryInterest: number;
        interest: number;
        capital: number;
        accessoryInterest: number;
        accessory: number;
      }
    },
    installment: IInstallment
  }[] = []
  cleanPayments: ({
    index: number
  } & IPayment)[] = []
  $_statusLoan = LOAN_STATUS_ENUM
  $_statusPaymentLoan = LOAN_PAYMENT_STATUS_ENUM
  $_statusLoanColor = LOAN_STATUS_ENUM_COLOR
  $_statusWriteOff = WRITE_OFF_STATUS_ENUM
  $_writeOffConcept = WRITE_OFF_CONCEPT
  items: MenuItem[] = []
  itemsPayments: MenuItem[] = []
  itemsRealPayments: MenuItem[] = []
  installmentsReport: {index: number, capital: number, interest: number, total: number, date: Date, paidAmount: number, status: string; realRevenueAmount: number;totalWithRealRevenue: number;accessoryPrincipalAmount: number; accessoryRevenueAmount: number;}[] = []
  paymentsReport: {index: number, dueDate: Date, amountApplied: number; dateApplication: Date; admin: string;accountNumber: string;gatewayPaymentId: string; amount: number; date: Date; applicationStatus: string;
    moraInterest: number;
    ivaMoraInterest: number;
    ivaAccessoryInterest: number;
    interest: number;
    capital: number;
    accessoryInterest: number;
    accessory: number;
  }[] = []
  colsPdf: any[] = [
    {dataKey: 'index', title: 'Número de letra'},
    {dataKey: 'capital', title: 'Monto de capital'},
    {dataKey: 'interest', title: 'Monto de intéres'},
    {dataKey: 'realRevenueAmount', title: 'Monto de intereses devengados'},
    {dataKey: 'accessoryPrincipalAmount', title: 'Monto de capital de accesorios'},
    {dataKey: 'accessoryRevenueAmount', title: 'Monto de intereses de accesorios'},
    {dataKey: 'total',title: 'Monto total'},
    {dataKey: 'installmentAmountWithGeneratedAmount',title: '"Monto total con intereses devengados"'},
    {dataKey: 'date', title: 'Fecha de exigibilidad'},
    {dataKey: 'paidAmount' ,title: 'Monto cobrado'},
    {dataKey: 'status', title: 'Estatus de pagado'}
  ]
  colsPdfPayments: any[] = [
    {dataKey: 'index', title: 'Número de letra'},
    {dataKey: 'dueDate', title: 'Fecha de exigibilidad'},
    {dataKey: 'accessoryInterest', title: 'Monto aplicado de intereses de accesorios'},
    {dataKey: 'accessory', title: 'Monto aplicado de capital de accesorios'},
    {dataKey: 'interest', title: 'Monto aplicado de intereses'},
    {dataKey: 'capital', title: 'Monto aplicado a capital'},
    {dataKey: 'amountApplied', title: 'Monto de la aplicación'},
    {dataKey: 'dateApplication', title: 'Fecha de aplicación'},
    {dataKey: 'admin', title: 'Responsable de aplicación'},
    {dataKey: 'accountNumber', title: 'CLABE receptora'},
    {dataKey: 'gatewayPaymentId', title: 'Clave de rastreo'},
    {dataKey: 'amount', title: 'Monto del pago original'},
    {dataKey: 'date', title: 'Fecha del pago'},
    {dataKey: 'status', title: 'Estatus'},
  ]
  colsPdfRealPayments:  any[] = [
    {dataKey: 'index', title: 'Número de pago'},
    {dataKey: 'date', title: 'Fecha de pago'},
    {dataKey: 'amount', title: 'Monto del pago'},
    {dataKey: 'gatewayPaymentId', title: 'Clave de rastreo'},
    {dataKey: 'accountNumber', title: 'CLABE receptora'},
    {dataKey: 'creationDate', title: 'Fecha de creacion'},
    {dataKey: 'status', title: 'Estatus'}
  ]
  entity!: FormGroup
  createEntity = false
  writeOffsToApply: any[] = []
  writeOffData: { loanId: string, loanFolio: string, writeOffTypes: string[], installments: {} } = {
    loanId: '',
    loanFolio: '',
    writeOffTypes: [],
    installments: {}
  };
  writeOffs: {}[] =[]
  hasPendingWriteOff: boolean = false
  isWriteOffAndPayroll: boolean = false
  writeOffDisabled: boolean = true
  @ViewChild('dtLoanResume') dtLoanResume!: Table;
  @ViewChild('dtPayments') dtPayments!: Table;
  @ViewChild('dtWriteOff') dtWriteOff!: Table;
  private payrolls: any;

  assignments : ILoanOwnership[] = []
  @Output() onDataChange = new EventEmitter()

  // Reestructura
  pendingRestructuring = false
  restructuringForm!: FormGroup
  // Renovation
  pendingRenovation = false
  renovationForm!: FormGroup
  modalContent = {
    kind: 'RENOVATION',
    title: 'Renovar crédito'
  }
  constructor(
    private apiInstallmentService: ApiInstallmentService,
    public dataTableService: DataTableService,
    private messageService: MessageService,
    private apiPaymentService: ApiPaymentService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    public authService: AuthService,
    private fb: FormBuilder,
    private writeOffService: WriteOffService,
    private apiWriteOffService: ApiWriteOffService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private apiLoanService: ApiLoanService,
    private router: Router
  ) {
  }

  ngAfterViewInit() {
    this.createMenus()
  }

  ngOnInit() {
    this.getAllData()
  }


  getAllData() {
    this.getInstallments()
    this.getPayments()
    this.getWriteOffs()
    this.getAssignments()
  }
  transformInstallments(data: any) {
    console.log("haber",data)
    return data.map((r: any) => {
      return {
        ...r,
        index: r.index,
        date: this.datePipe.transform(new Date(new Date(r.date).getTime() + 60 * 60 * 6 * 1000), 'YYYY-MM-dd'),
        capital: this.currencyPipe.transform(r.capital),
        interest: this.currencyPipe.transform(r.interest),
        realRevenueAmount: this.currencyPipe.transform(r.realRevenueAmount),
        accessoryPrincipalAmount: this.currencyPipe.transform(r.accessoryPrincipalAmount),
        accessoryRevenueAmount: this.currencyPipe.transform(r.accessoryRevenueAmount),
        installmentAmount: this.currencyPipe.transform(r.interest + r.capital + r.accessoryPrincipalAmount + r.accessoryRevenueAmount),
        installmentAmountWithGeneratedAmount: this.currencyPipe.transform(r.realRevenueAmount + r.capital + r.accessoryPrincipalAmount + r.accessoryRevenueAmount),
        total: this.currencyPipe.transform(r.total),
        paidAmount: this.currencyPipe.transform(r.paidAmount),

      }
    })
  }

  transformPayments(data: any) {
    return data.map((r:any) => {
      console.log(r)
      return {
        ...r,
        interest: this.currencyPipe.transform(r.interest),
        capital: this.currencyPipe.transform(r.capital),
        accessoryInterest: this.currencyPipe.transform(r.accessoryInterest),
        accessory: this.currencyPipe.transform(r.accessory),
        dueDate: this.datePipe.transform(new Date(new Date(r.dueDate).getTime() + 60 * 60 * 6 * 1000), 'YYYY-MM-dd'),
        amount: this.currencyPipe.transform(r.amount),
        amountApplied: this.currencyPipe.transform(r.amountApplied),
        dateApplication: this.datePipe.transform(r.dateApplication, 'YYYY-MM-dd hh:mm:ss'),
        date: this.datePipe.transform((new Date(new Date(r.date).getTime() + 60 * 60 * 6 * 1000)), 'YYYY-MM-dd'),
        status: r.applicationStatus === 'APPLIED' ? 'Aplicado' : 'Cancelado'
      }
    })
  }

  transformRealPayments(data: any) {
    return data.map((r:any) => {
      console.log(r)
      return {
        ...r,
        date: this.datePipe.transform((new Date(new Date(r.date).getTime() + 60 * 60 * 6 * 1000)),'YYYY-MM-dd'),
        amount: this.currencyPipe.transform(r.amount),
        creationDate: this.datePipe.transform(r.creationDate,'YYYY-MM-dd'),
        status: r.status === 'CANCELLED' ? 'Cancelado' : 'Aplicado'
      }
    })
  }

  getInstallments() {
    this.apiInstallmentService.getByLoanId(this.data._id).subscribe((r) => {
      console.log(r)
      this.installments = r.data.map((i) => {
        return {
          ...i,
          status:  (i.collectedAmount + 0.1) >= (i.principalAmount + i.revenueAmount + i.accessoryPrincipalAmount + i.accessoryRevenueAmount - 1) ? 'Pagado' : (i.status === 'RESTRUCTURING' ? 'Restructurado' :'Pendiente')
        }
      })
      this.installmentsReport = []
      this.installments.forEach((i) => {
        this.installmentsReport.push({
          index: i.index + 1,
          capital: i.principalAmount,
          realRevenueAmount: i.realRevenueAmount,
          interest: i.revenueAmount,
          accessoryPrincipalAmount: i.accessoryPrincipalAmount,
          accessoryRevenueAmount: i.accessoryRevenueAmount,
          total: i.principalAmount + i.revenueAmount + i.accessoryRevenueAmount + i.accessoryPrincipalAmount,
          totalWithRealRevenue: i.principalAmount + i.revenueAmount + i.accessoryRevenueAmount + i.accessoryPrincipalAmount,
          date: i.dueDate,
          paidAmount: i.collectedAmount,
          status: (i.collectedAmount + 0.1) >= (i.principalAmount + i.revenueAmount + i.accessoryPrincipalAmount + i.accessoryRevenueAmount - 1) ? 'Pagado' : (i.status === 'RESTRUCTURING' ? 'Restructurado' :'Pendiente')
        })
      })
    })
  }

  getAssignments() {
    this.apiLoanService.getAssignmentsByProductId({module: 'LOAN', id: this.data._id}).subscribe(r => {
      console.log("assignments",r)
      this.assignments = r.data
    })
  }

  getPayments() {
    this.payments = []
    this.cleanPayments = []
    this.paymentsReport = []
    this.apiPaymentService.getByProductId(this.data._id).subscribe(r => {
      console.log(r.data)
      if (!r.error) {
        this.payments = r.data.sort((a,b) => new Date(a.payment.creationDate).getTime() - new Date(b.payment.creationDate).getTime())
        console.log(r.data, "dataa")
        this.payments.sort((a,b) => new Date(a.payment.creationDate).getTime() - new Date(b.payment.creationDate).getTime()).forEach(p => {
          console.log(p)
          this.paymentsReport.push({
            index: p.installment.index + 1,
            dueDate: p.installment.dueDate,
            amountApplied: p.application.amountApplied,
            dateApplication: p.application.dateApplication,
            admin: p.application.admin,
            accountNumber: p.payment.accountNumber,
            gatewayPaymentId: p.payment.gatewayPaymentId,
            amount: p.payment.amount,
            date: p.payment.date,
            applicationStatus: p.application.status,
            moraInterest: p.application.schemaApplication ? p.application.schemaApplication.moraInterest : 0,
            ivaMoraInterest: p.application.schemaApplication ? p.application.schemaApplication.ivaMoraInterest : 0,
            ivaAccessoryInterest: p.application.schemaApplication ? p.application.schemaApplication.ivaAccessoryInterest : 0,
            interest: p.application.schemaApplication ? p.application.schemaApplication.interest : 0,
            capital: p.application.schemaApplication ? p.application.schemaApplication.capital : 0,
            accessoryInterest: p.application.schemaApplication ? p.application.schemaApplication.accessoryInterest : 0,
            accessory: p.application.schemaApplication ? p.application.schemaApplication.accessory : 0
          })
          if (this.cleanPayments.filter(py => py._id === p.payment._id).length === 0) {
            console.log(p.payment)
            this.cleanPayments.push({
              index: this.cleanPayments.length + 1,
              ...p.payment
            })
          }
        })
      }
      console.log(this.cleanPayments)
    })
  }

  getWriteOffs() {
    const filter = {
      "loanId": {"value": this.data._id, "matchMode": "equals"}
    }
    this.writeOffs = [];
    const sort = { date: 1, _id: -1 }
    this.apiWriteOffService.getAll(0, 100, filter, sort).subscribe(response => {
      response.data.forEach((data: any) => {
        if (data.status == WRITE_OFF_STATUS_ENUM_ES.Pendiente){
          this.hasPendingWriteOff=true
        }
        const writeOff = {
          id:data._id,
          reason: data.reason,
          status: data.status,
          index: data.index + 1,
          debtRelief: data.debtRelief,
          date: data.date,
          adminId: data.adminId,
          adminFullName: data.adminFullName
        }
        this.writeOffs.push(writeOff)
      })
    })
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  confirmCancellation(event: Event, id: string) {
    console.log(id)
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: 'Cancelación de pago',
      message: '¿Estas seguro de cancelar el pago?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.loaderService.showSpinner()
        this.apiPaymentService.cancel({
          paymentId: id,
          productId: this.data._id
        }).subscribe(r => {
          this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Exitoso', detail: r.error ? r.msg: r.msg, life: 5000 });
          this.loaderService.hideSpinner()
          if (!r.error) {
            this.getAllData()
            this.onDataChange.emit()
          }
        })
      }
    });
  }

  isTheLastAppliedPayment(id: string) {
    const appliedPayments = this.cleanPayments.filter(p => p.status === 'APPLIED')
    return appliedPayments.length > 0 ? appliedPayments[appliedPayments.length - 1]._id === id : false
  }

  createMenus() {
    if (this.authService.userHasAuthorization('LOAN', 'EXPORT_AMORTIZATION_TABLE')) {
      this.items = [
        {
          label: 'Condonación',
          icon: 'fa-solid fa-file-contract',
          items: [
            {
              label: 'Condonar',
              command: () => {
                this.isWriteOffAndPayroll = false
                this.newEntity();
                this.modalContent = {
                  kind: 'WRITEOFF',
                  title: 'Nueva condonación'
                }
              }
            }
          ]
        },
        {
          label: 'Restructuración',
          icon: 'fa-solid fa-sliders',
          items: [
            {
              label: 'Reestructurar',
              command: () => {
                this.modalContent = {
                  kind: 'RESTRUCTURING',
                  title: 'Nueva reestructura'
                }
                this.pendingRestructuring = true
                this.newRestructuring()
              }
            }
          ]
        },
      ]
    }

    if (this.authService.userHasAuthorization('LOAN', 'EXPORT_APPLICATIONS_TABLE')) {
      this.itemsPayments = [
        {
          label: 'Condonación',
          icon: 'fa-solid fa-file-contract',
          items: [
            {
              label: 'Condonar',
              command: () => {
                this.isWriteOffAndPayroll = false
                this.newEntity();
              }
            }
          ]
        },
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'Excel',
              command: () => {
                this.dataTableService.exportExcel(this.transformPayments(this.paymentsReport).map((r: any) => {
                  return {
                    "Número de letra": r.index,
                    "Fecha de exigibilidad": r.dueDate,
                    "Monto aplicado de intereses de accesorios": r.accessoryInterest,
                    "Monto aplicado de capital de accesorios": r.accessory,
                    "Monto aplicado de intereses": r.interest,
                    "Monto aplicado a capital": r.capital,
                    "Monto de la aplicación": r.amountApplied,
                    "Fecha de aplicación": r.dateApplication,
                    "Responsable de aplicación": r.admin,
                    "CLABE receptora": r.accountNumber,
                    "Clave de rastreo": r.gatewayPaymentId,
                    "Monto original del pago": r.amount,
                    "Fecha de pago": r.date,
                    "Estatus": r.status
                  }
                }))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.dataTableService.exportPdf(this.colsPdfPayments, this.transformPayments(this.paymentsReport))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
      this.itemsRealPayments = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'Excel',
              command: () => {
                this.dataTableService.exportExcel(this.transformRealPayments(this.cleanPayments).map((r: any) => {
                  return {
                      "Número de pago": r.index,
                      "Fecha de pago": r.date,
                      "Monto del pago": r.amount,
                      "Clave de rastreo": r.gatewayPaymentId,
                      "CLABE receptora": r.accountNumber,
                      "Fecha de creacion": r.creationDate,
                      "Estatus": r.status
                  }
                }))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.dataTableService.exportPdf(this.colsPdfRealPayments, this.transformRealPayments(this.cleanPayments))
                this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa' });
              }
            }
          ]
        }
      ];
      if (this.data && this.data.referenceLoanId === null) {
        this.items.push({
          label: 'Renovación',
          icon: 'fa-solid fa-rotate-right',
          items: [
            {
              label: 'Renovar',
              command: () => {
                this.modalContent = {
                  kind: 'RENOVATION',
                  title: 'Nueva renovación'
                }
                this.pendingRenovation = true
                this.newRenovation()
              }
            }
          ]
        })
      }
      const itemsDownload = {
        label: 'Descargar',
        icon: 'fa-solid fa-cloud-arrow-down',
        items: [
          {
            label: 'Excel',
            command: () => {
              this.dataTableService.exportExcel(this.transformInstallments(this.installmentsReport).map((r: any) => {
                return {
                  "Número de letra": r.index,
                  "Monto de capital": r.capital,
                  "Monto de interés": r.interest,
                  "Monto de intereses devengados": r.realRevenueAmount,
                  "Monto del capital de accesorios": r.accessoryPrincipalAmount,
                  "Monto de intereses de accesorios": r.accessoryRevenueAmount,
                  "Monto total": r.total,
                  "Monto total con intereses devengados": r.installmentAmountWithGeneratedAmount,
                  "Fecha de exigibilidad": r.date,
                  "Monto cobrado": r.paidAmount,
                  "Estatus de pago": r.status
                }
              }))
              this.messageService.add({severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa'});
            }
          },
          {
            label: 'PDF',
            command: () => {
              this.dataTableService.exportPdf(this.colsPdf, this.transformInstallments(this.installmentsReport))
              this.messageService.add({severity: 'success', summary: 'Exitoso', detail: 'Descarga exitosa'});
            }
          },
          {
            label: 'Estado de cuenta',
            command: () => {
              this.loaderService.showSpinner()
              this.apiLoanService.getLoanBalanceReport(this.data._id).subscribe(r => {
                if (r.data && r.data.url) {
                  setTimeout(() => {
                    console.log('hola')
                    window.open(r.data.url)
                    this.loaderService.hideSpinner()
                    this.messageService.add({severity: 'success', summary: 'Éxito', detail: 'Descarga exitosa'});
                  }, 3000)

                } else {
                  this.loaderService.hideSpinner()
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Error',
                    detail: 'Error al generar reporte'
                  });
                }
              })
            }
          }
        ]
      }
      if (this.data.outstandingPrincipalAmount + this.data.outstandingAccessoryPrincipalAmount + this.data.outstandingInterestAmount + this.data.outstandingAccessoryInterestAmount <= 1) {
        itemsDownload.items.push(
          {
            label: 'Carta de liquidación',
          command: () => {
            this.generateFinishLetter()
          },
        })
      }
      this.items.push(itemsDownload)
    }
    if (this.authService.userHasAuthorization('WRITEOFF','WRITEOFF_N_PAYROLL')) {
      this.items.forEach((item => {
        if (item.label == 'Condonación') {
          item.items?.push({
            label: 'Condonar y pagar',
            command: () => {
              this.modalContent = {
                kind: 'WRITEOFF',
                title: 'Condonar y pagar'
              }
              this.isWriteOffAndPayroll = true
              this.newEntity();
            }
          })
        }
      }))

    }
  }

  newEntity() {
    const installments:{
      id:string,
      index: number,
      capital: number,
      revenue: number,
      accessoryPrincipalAmount: number,
      accessoryRevenueAmount: number,
      total: number,
      date: Date,
      paidAmount: number,
      status: string;}[] = []
    this.installments.forEach((i) => {
      installments.push({
        id:i._id,
        index: i.index + 1,
        capital: i.principalAmount,
        revenue: i.revenueAmount,
        total: i.principalAmount + i.revenueAmount,
        date: i.dueDate,
        paidAmount: i.collectedAmount,
        status:  i.collectedAmount > (i.principalAmount + i.revenueAmount) ? 'Pagado' : 'Pendiente',
        accessoryPrincipalAmount: i.accessoryPrincipalAmount,
        accessoryRevenueAmount: i.accessoryRevenueAmount
      })
    })
    this.writeOffData = {
      loanId: this.data._id,
      loanFolio:this.data.folio,
      writeOffTypes:this.writeOffService.getTypes('ENG'),
      installments: installments
    }
    let formControl: {[key: string]: any;} = {};
    this.writeOffData.writeOffTypes.forEach(type => {
      formControl[`reason`] = ['',[Validators.required]]
      formControl[`${type}Amount`] = [0,[Validators.required]]
    })
    formControl['reason'] = ['',[Validators.required]]
    this.entity = this.fb.group(formControl)
    this.createEntity = true
  }

  hideDialog() {
    this.createEntity = false
  }

  saveWriteOff(authorized: boolean) {
    this.loaderService.showSpinner()
    if (authorized && this.isWriteOffAndPayroll){
      const data = {
        writeOffs: this.writeOffsToApply,
        payrolls: this.payrolls
      }
      data.payrolls.forEach((payroll: { clabe: any; }) => {
        payroll.clabe = payroll.clabe.replace(/\s+/g, '')
      })
      this.apiWriteOffService.createAndPayroll(data).subscribe((r) => {
        this.loaderService.hideSpinner()
        if (r.error ) {
          console.log(r.data)
          this.messageService.add({ severity: 'error', summary: 'Error', detail: r.msg, life:8000});
        } else {
          this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Se ha completado la condonación.', life:8000 });
        }
        this.getInstallments()
        this.getWriteOffs()
        this.getData()
        this.getPayments()
        this.hideDialog()
      })
    } else if (authorized) {
      this.apiWriteOffService.create(this.writeOffsToApply).subscribe((r) => {
        this.loaderService.hideSpinner()
        if (r.error ) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: r.msg, life:8000});
          console.log(r.data)
        } else {
          this.messageService.add({ severity: 'success', summary: 'Completado', detail: 'Se ha completado la condonación.', life:8000 });
        }
        this.getInstallments()
        this.getWriteOffs()
        this.getData()
        this.hideDialog()
      })
    } else {
      this.apiWriteOffService.preWriteOff(this.writeOffsToApply).subscribe((r) => {
        this.loaderService.hideSpinner()
        if (r.error ) {
          console.log(r.data)
          this.messageService.add({ severity: 'error', summary: 'Error', detail: r.msg, life:8000});
        }else {
          this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Se ha generado la solicitud de condonación.', life:8000 });
        }
        this.getInstallments()
        this.getWriteOffs()
        this.getData()
        this.hideDialog()
      })
    }
  }

  newRenovation() {
    this.renovationForm =  this.fb.group({
      referenceLoanId: [ this.data._id, [Validators.required]],
      amount: [ 0, [Validators.required, Validators.min(this.data.amountToPay + 1)]],
      installmentCount: [ 0, [Validators.required, Validators.min(1)]]
    })
    this.createEntity = true
  }

  newRestructuring() {
    this.restructuringForm =  this.fb.group({
      referenceLoanId: [ this.data._id, [Validators.required]],
      amount: [ 0, [Validators.required, Validators.min(this.data.amountToPay)]],
      installmentCount: [ 0, [Validators.required, Validators.min(1)]]
    })
    this.createEntity = true
  }

  saveRestructuring() {
    this.loaderService.showSpinner()
    this.apiLoanService.create({...this.restructuringForm.value, workerId: this.data.positionId.workerId._id, kind: 'RESTRUCTURING'}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      if (!r.error ) {
        console.log(r.data)
        setTimeout(() => {
          this.router.navigate([`/loan`])

        }, 2000)
        setTimeout(() => {
          this.router.navigate([`/loan/${r.data._id}`])

        }, 3000)
      }
      this.loaderService.hideSpinner()
    })
  }

  saveRenovation(){
    this.loaderService.showSpinner()
    this.apiLoanService.create({...this.renovationForm.value, workerId: this.data.positionId.workerId._id, kind: 'RENOVATION'}).subscribe((r) => {
      this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.msg: r.msg, life: 5000 });
      if (!r.error ) {
        console.log(r.data)
        setTimeout(() => {
          this.router.navigate([`/loan`])

        }, 2000)
        setTimeout(() => {
          this.router.navigate([`/loan/${r.data._id}`])

        }, 3000)
      }
      this.loaderService.hideSpinner()
    })
  }

  generateFinishLetter() {
    this.loaderService.showSpinner();
    this.apiLoanService.getAll().subscribe(async (data) => {
      const totalDebt =
        this.data.outstandingAccessoryInterestAmount +
        this.data.outstandingAccessoryPrincipalAmount +
        this.data.outstandingPrincipalAmount +
        this.data.outstandingPrincipalAmount;

      if (totalDebt >= 1) {
        setTimeout(() => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'El credito aun no esta liquidado'});
        }, 3000);
        this.loaderService.hideSpinner();
        console.log(totalDebt)
        return;
      }

      try {

        const pdfDoc = await PDFDocument.create();
        console.log("PDF creado con éxito");
        const page = pdfDoc.addPage([595.28, 841.89]); // Tamaño A4
        console.log("Página añadida");


        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontSize = 12;
        const marginLeft = 50;
        let currentY = 750;
        console.log("Fuente cargada");


        page.drawText('PRODUCTOS PARA EMPLEADOS, S.A.P.I. DE C.V.', {
          x: marginLeft,
          y: currentY,
          size: fontSize,
          font: fontBold,
          color: rgb(0, 0, 0),
        });
        console.log("Texto añadido");

        currentY -= 20;


        page.drawText(`Ciudad de México a ${new Date().toLocaleDateString()}`, {
          x: marginLeft,
          y: currentY,
          size: fontSize,
          font: font,
        });
        console.log("Lugar y fecha añadida")
        currentY -= 40;


        page.drawText(`Estimado/a ${this.data.positionId.workerId.names.firstName} ${this.data.positionId.workerId.names.secondName} ${this.data.positionId.workerId.names.fatherLastName} ${this.data.positionId.workerId.names.motherLastName}`, {
          x: marginLeft,
          y: currentY,
          size: fontSize,
          font: fontBold,
        });
        console.log("Estimado")
        currentY -= 30;

        const bodyText = `La empresa PRODUCTOS PARA EMPLEADOS, S.A.P.I. DE C.V. se complace en saludarlo e informarle que ha terminado de pagar su crédito con folio ${this.data.folio}, el cual saldó a entera satisfacción de la empresa. Agradecemos profundamente la preferencia que ha mostrado por nuestro servicio y esperamos haber satisfecho sus necesidades. Esperamos que su confianza en nosotros se incremente ya que ello aumenta nuestro compromiso con su satisfacción. Queremos comunicarle también que estamos complacidos de servirlo y ofrecerle un servicio de calidad. Nos despedimos de usted, no sin antes insistir en nuestro agradecimiento y profundo compromiso con su bienestar. Estamos disponibles cuando usted así lo requiera.`;

// Función para dividir texto en líneas sin cortar palabras
        function wrapText(text: string, maxWidth: number) {
          const words = text.split(" ");
          let lines: string[] = [];
          let line = "";

          for (let word of words) {
            const width = font.widthOfTextAtSize(line + " " + word, fontSize);
            if (width < maxWidth) {
              line += ` ${word}`;
            } else {
              lines.push(line.trim());
              line = word;
            }
          }
          lines.push(line.trim());
          return lines;
        }

        const textLines = wrapText(bodyText, 450); // Ajusta el ancho máximo del texto
        const lineHeight = 16;

// Dibujar texto línea por línea
        for (let line of textLines) {
          page.drawText(line, { x: marginLeft, y: currentY, size: fontSize, font: font });
          currentY -= lineHeight;
        }

        currentY -= 30;

        console.log("Cuerpo carta")
        page.drawText('A T E N T A M E N T E', {
          x: marginLeft,
          y: currentY,
          size: fontSize,
          font: fontBold,
        });
        console.log("Firma")
        currentY -= 30;

        page.drawText('PRODUCTOS PARA EMPLEADOS, S.A.P.I. DE C.V.', {
          x: marginLeft,
          y: currentY,
          size: fontSize,
          font: fontBold,
        });

        console.log("PDF guardando en memoria");

        const pdfBytes = await pdfDoc.save();
        console.log("PDF guardado en memoria");


        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const fileName = `Carta_Liquidacion_${this.data.folio}.pdf`;
        console.log("PDF descargado en memoria");

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        console.log("PDF descargado con éxito");

        this.loaderService.hideSpinner();
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Carta de liquidación generada exitosamente.',
        });
        console.log("Datos del crédito:", this.data);
      } catch (err: any) {
        console.log(err.message);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Ocurrió un error al generar la carta de liquidación.',
        });
        this.loaderService.hideSpinner();
        console.log("Datos del crédito:", this.data);
      }
    });
  }

  writeOffResult(event: {writeOffs: any[], payrolls: any[]}) {
    this.writeOffsToApply = event.writeOffs
    this.payrolls = event.payrolls
    this.writeOffDisabled = event.writeOffs.length < 1
  }

  getData() {
    this.apiLoanService.getById(this.data._id).subscribe(r => {
      this.data = {...r.data.loan, amountToPay: r.data.amountToPay}
    })
  }
}
