<p-card>
  <div class="grid">
    <div class="col-12 lg:col-6">
      <h1 class="mb-3">Detalle de solicitud</h1>
      <p-toast></p-toast>
    </div>
    <div class="col-12 lg:col-6 text-right">
      <ng-container *ngIf="items.length > 0">
        <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info p-button-sm mr-2 mb-2 px-4"></p-splitButton>
      </ng-container>
    </div>
    <ng-container *ngIf="data && stages">
      <div class="col-12 insights">
        <p>RFC: <strong><a class="link-subsection" routerLink="/worker/{{data.pushing.workerId._id}}">{{data.pushing.workerId.rfc}}</a></strong></p>
        <p>El usuario ha completado <strong>{{data.pushing.progress}}</strong> de <strong>{{data.pushing.stages}}</strong> etapas</p>
        <p>El usuario inicio el proceso hace <strong>{{data.pushing.creationDate | timeElapsed}}</strong> y la última respuesta fue hace <strong>{{data.pushing.lastUserResponse | timeElapsed}}</strong></p>
        <p>Estatus actual: <strong>{{data.pushing.status | selectEnum:$_pushing_flow_status}}</strong></p>
        <p class="mb-2">Etapa actual: <strong>{{getCurrentStage(data.pushing.currentStage)}}</strong></p>
        <p-progressBar [style]="{ height: '12px', fontSize: '12px' }" [value]="getAdvance"></p-progressBar>
      </div>
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-book"></i>
            <span class="ml-2">Expediente</span>
          </ng-template>
          <app-pushing-record-request [data]="data" (onCompleteUpdate)="getPushing()"></app-pushing-record-request>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-bars-progress"></i>
            <span class="ml-2">Avance del proceso</span>
          </ng-template>
          <app-pushing-timeline [data]="data" [stages]="stages"></app-pushing-timeline>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-id-card"></i>
            <span class="ml-2">Resumen</span>
          </ng-template>
          <app-pushing-resume [data]="data" [stages]="stages"></app-pushing-resume>
        </p-tabPanel>
        <ng-container *ngIf="data && data.pushing.flowId.provider === 'MAS_NOMINA'">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-id-card"></i>
              <span class="ml-2">Calculadora</span>
            </ng-template>
            <app-pushing-provider-calculator [workerId]="data.pushing.workerId._id"></app-pushing-provider-calculator>
          </p-tabPanel>
        </ng-container>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-brain"></i>
            <span class="ml-2">Extracción de datos</span>
          </ng-template>
          <ng-container *ngFor="let flowData of data.flowData">
            <app-pushing-detail-ai-translate [data]="flowData.data" [documentId]="flowData.documentId" [allData]="data"></app-pushing-detail-ai-translate>
          </ng-container>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-brain"></i>
            <span class="ml-2">Analisis de datos</span>
          </ng-template>
          <app-pushing-detail-ai-analyse [data]="data"></app-pushing-detail-ai-analyse>
        </p-tabPanel>
      </p-tabView>
    </div>
    </ng-container>
  </div>
</p-card>
<p-dialog [(visible)]="createEntity" [style]="{ width: '650px' }" [header]="'Reasignar flujo'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-update-flow-pushing [entity]="entity" [submitted]="submitted"></app-update-flow-pushing>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Actualizar" icon="pi pi-check" class="p-button-text" (click)="savePushing()" [disabled]="!entity.valid"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
