import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DataTableService} from "@data/services/utils/data-table.service";
import {Table} from "primeng/table";
import {IRecordsRequestWorkers, IWorker} from "@modules/private/workers/data/interfaces/iworker.interface";
import {ApiWorkerService} from "@modules/private/workers/data/services/api/api-worker.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {
  WORKER_STATUS_ENUM,
  WORKER_STATUS_VERIFICATION_ENUM
} from "@modules/private/workers/data/enums/workerStatus.enum";
import {TYPE_PERIOD_ENUM} from "@modules/private/company/data/enums/typePeriods.enum";
import {GENDER_WORKER} from "@modules/private/workers/data/enums/gender.enum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "@data/services/page/loader.service";
import {Router} from "@angular/router";
import {ITableCol} from "@data/interfaces/itable.interface";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {SelectEnumPipe} from "@data/pipes/enum/select-enum.pipe";
import {WORKER_COLUMNS} from "@modules/private/workers/data/constants/workerColumns.constant";
import {AuthService} from "@data/services/modules/auth/auth.service";
import {ViewSaverService} from "@data/services/page/viewSaver.service";
import {prerenderPages} from "@angular-devkit/build-angular/src/utils/server-rendering/prerender";

@Component({
  selector: 'app-list-worker',
  templateUrl: './list-worker.component.html',
  styleUrls: ['./list-worker.component.scss'],
  providers: [MessageService, ConfirmationService, SelectEnumPipe, CurrencyPipe, DatePipe]
})
export class ListWorkerComponent implements OnInit, AfterViewInit, OnDestroy{
  items: MenuItem[] = [];
  workers!: IRecordsRequestWorkers[];
  totalRecords = 0
  itemsByPage = 10
  selectedWorkers!: IWorker[] | null;
  createEntity = false
  entity!: FormGroup
  loading: boolean = true;
  _selectedColumns!: ITableCol[]
  columns = WORKER_COLUMNS
  @ViewChild('dtWorkers') dtWorkers!: Table;
  tableKey = 'workersTable';
  filters: { [key: string]: any } = {};
  constructor(
    private apiWorkerService: ApiWorkerService,
    public dataTableService: DataTableService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private router: Router,
    private selectEnumPipe: SelectEnumPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    public authService: AuthService,
    private tableFilterService: ViewSaverService,
  ) {
    this.createMenu()
    this.filterNoAuthorizedColumns()
    this.selectedColumns = this.columns
  }

  ngAfterViewInit() {
    const savedData =  this.tableFilterService.getFilters(this.tableKey)
    console.log(savedData, "savedData")
    if (savedData && !savedData.search) {
      this.itemsByPage = this.itemsByPage
      this.workers = savedData.data
      this.dtWorkers.filters = savedData.filters
      this.totalRecords = savedData.totalRecords
      this.dtWorkers.first = savedData.page * this.itemsByPage
    }
    this.tableFilterService.changeSearchStatus(this.tableKey, true)
  }

  ngOnInit() {
  }

  clear() {
    this.tableFilterService.clearFilters(this.tableKey, this.dtWorkers, () => this.findWorkers());
  }

  filterNoAuthorizedColumns() {
    if (!this.authService.userHasAuthorization('WORKER', 'READ_SALARY')) this.columns = this.columns.filter(c => c.field !== 'salary')
    if (!this.authService.userHasAuthorization('WORKER', 'READ_CONTACT')) this.columns = this.columns.filter(c => c.field !== 'correo' && c.field !== 'phoneNumber')
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.columns.filter((col) => val.includes(col));
  }
  findWorkers(page = 0, limit = this.itemsByPage, filters = {}) {
    this.loading = true;
    let downFilters = {}
    console.log("finding", page)
    this.apiWorkerService.getAll(page, limit, filters).subscribe(r => {
      this.totalRecords = r.totalItems
      this.workers = r.data
      this.loading = false
      if (this.dtWorkers) this.tableFilterService.updateData(this.tableKey, this.workers, this.totalRecords);
    })
  }

  getValue($event: Event) {
    return ($event.target as HTMLInputElement).value
  }

  loadWorkers(e: any) {
    const savedData =  this.tableFilterService.getFilters(this.tableKey)
    if (!(savedData && !savedData.search)) {
      const currentPage = (e.first / this.itemsByPage) // e.first / this.itemsByPage
      this.itemsByPage = e.rows
      this.totalRecords = e.totalRecords
      const finalFilters = {...e.filters}
      if (e.filters.global) finalFilters['folio'] = e.filters.global
      this.findWorkers(currentPage, this.itemsByPage, finalFilters)
      if(Object.keys(finalFilters).length <= 0) {
        setTimeout(() => {
          this.tableFilterService.saveFilters(this.tableKey, this.dtWorkers.filters, currentPage, this.itemsByPage, this.workers, true, this.totalRecords);
        }, 1000)
      } else {
        this.tableFilterService.saveFilters(this.tableKey, finalFilters, currentPage, this.itemsByPage, this.workers, true, this.totalRecords);
      }

    } else {
      this.loading = false
    }
  }

  exportPdf() {
    this.loaderService.showSpinner()
    this.apiWorkerService.getAll(0, 100000000, this.dtWorkers.filters).subscribe(r => {
      this.dataTableService.exportPdf([
        {dataKey: 'rfc', title: 'RFC'},
        ...this.selectedColumns.map(sc => {
          return {
            dataKey: sc.field,
            title: sc.header
          }
        })
      ], this.convertData(r.data))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000 });
    })

  }

  exportExcel() {
    this.loaderService.showSpinner()
    this.apiWorkerService.getAll(0, 100000000, this.dtWorkers.filters).subscribe(r => {
      const dataConverted = this.convertData(r.data)

      this.dataTableService.exportExcel(dataConverted.map(i => {
        const response: any = {RFC: i.rfc}
        for (const column of this.selectedColumns) {
          const ii = i as any
          response[column.header] = ii[column.field]
        }
        return response
      }))
      this.loaderService.hideSpinner()
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descarga exitosa', life: 5000});
    })
  }

  convertData(d: IRecordsRequestWorkers[]) {
    return d.map(i => {
      return {
        ...i,
        gender: this.selectEnumPipe.transform(i.gender, GENDER_WORKER),
        registerStatus: this.selectEnumPipe.transform(i.registerStatus, WORKER_STATUS_ENUM),
        verificationSatus: this.selectEnumPipe.transform(i.verificationSatus, WORKER_STATUS_VERIFICATION_ENUM),
        period: this.selectEnumPipe.transform(i.period, TYPE_PERIOD_ENUM),
        isActiveLoan: i.isActiveLoan ? 'Activo' : 'Desactivado',
        isActiveWithdrawal: i.isActiveWithdrawal ? 'Activo' : 'Desactivado',
        salary: this.currencyPipe.transform(i.salary),
        withdrawalsAmountPaid: this.currencyPipe.transform(i.withdrawalsAmountPaid),
        withdrawalsAmountPending: this.currencyPipe.transform(i.withdrawalsAmountPending),
        withdrawalsAmounTotalToPaid: this.currencyPipe.transform(i.withdrawalsAmounTotalToPaid),
        loansAmountPaid: this.currencyPipe.transform(i.loansAmountPaid),
        loansAmountTotalToPaid: this.currencyPipe.transform(i.loansAmountTotalToPaid),
        loansAmountPending: this.currencyPipe.transform(i.loansAmountPending),
        startDate: this.datePipe.transform(i.startDate, 'dd-MM-YYYY'),
        endDate: this.datePipe.transform(i.endDate, 'dd-MM-YYYY'),
        activationDate: this.datePipe.transform(i.activationDate, 'dd-MM-YYYY mm:hh:ss'),
        creationDate: this.datePipe.transform(i.creationDate, 'dd-MM-YYYY mm:hh:ss'),
      }
    })
  }

  getValueObject(obj: any, keys: string) {
    const keysArray = keys.split('.')
    let value: any
    for (const key in keysArray) {
      if(key === '0') {
        value = obj[keysArray[key]]
      } else {
        value = value[keysArray[key]]
      }
    }
    return value
  }

  hideDialog() {
    this.createEntity = false;
  }

  createForm() {
    this.entity = this.fb.group({
      rfc: ['', [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][0-9A-Z]{3}$/)]],
      firstName: ['', [Validators.required]],
      secondName: [''],
      fatherLastName: ['', [Validators.required]],
      motherLastName: ['', [Validators.required]],
      sex: ['', [Validators.required]],
      birthDate: [''],
      curp: ['', [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z]{6}[0-9A-Z]{2}$/)]],
      emailAddress: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]*[a-zA-Z][a-zA-Z0-9_.+-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      phoneNumber: ['',  [Validators.required, Validators.pattern((/^\d{10}$/))]],
      countryCode: ["+52", [Validators.required]],
      secondEmailAddress: ['', [Validators.pattern(/^[a-zA-Z0-9_.+-]*[a-zA-Z][a-zA-Z0-9_.+-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      secondPhoneNumber: ['', [Validators.pattern(/^\d{10}$/)]],
      status: ['PENDING_ACTIVATION', [Validators.required]],
      position: ['', [Validators.required]],
      // clabe: ['', [Validators.required, Validators.pattern(/^\d{18}$/)]],
      payrollId: ['', [Validators.required]],
      salary: [ 0, [Validators.required]],
      notifyUser: [false, [Validators.required]],
      civilStatus: [''],
      nationality: [''],
      startDate: [null, [Validators.required]],
      endDate: [null],
      city: [''],
      neighborhood: [''],
      postalCode: [''],
      state: [''],
      streetNameAndNumber: [''],
      statusVerification: ['PENDING_VERIFICATION', [Validators.required]],
    })
  }

  create() {
    console.log(this.entity.value)
    this.loaderService.showSpinner()
    const formattedPhoneNumber = `${this.entity.value.countryCode}${this.entity.value.phoneNumber}`;
    const secondPhoneNumber = this.entity.value.secondPhoneNumber !== '' ? `${this.entity.value.countryCode}${this.entity.value.secondPhoneNumber}`: ''
    const updatedData = {
      ...this.entity.value,
      phoneNumber: formattedPhoneNumber,
      secondPhoneNumber
    };
    this.apiWorkerService.create({data: {worker: this.entity.value, ...updatedData}}).subscribe(r => {
      this.loaderService.hideSpinner()
      setTimeout(() => {
        this.messageService.add({ severity: r.error ? 'error': 'success', summary: r.error ? 'Error': 'Success', detail: r.error ? r.data?.errors[0]: r.msg, life: 4000});
      }, 3100)
      if (!r.error) {
        this.router.navigate(['/worker/'+r.data._id])
      }
    })
  }

  createMenu() {
    if (this.authService.userHasAuthorization('WORKER','EXPORT')) {
      this.items = [
        {
          label: 'Descargar',
          icon: 'fa-solid fa-cloud-arrow-down',
          items: [
            {
              label: 'Excel',
              command: () => {
                this.exportExcel()
              }
            },
            {
              label: 'PDF',
              command: () => {
                this.exportPdf()
              }
            }
          ]
        }
      ]
    }
    this.items = [
      ...this.items,
      {
        label: 'Refrescar',
        icon: 'fa-solid fa-rotate-right',
        command: () => {
          this.findWorkers()
        }
      },
      {
        label: 'Limpiar',
        icon: 'fa-solid fa-broom',
        command: () => {
          this.dtWorkers.clear()
          this.findWorkers()
        }
      }
    ]
    if (this.authService.userHasAuthorization('WORKER', 'CREATE')) {
      this.items = [
        ...this.items,
        { separator: true },
        {
          label: 'Nuevo',
          icon: 'fa-solid fa-user-plus',
          command: () => {
            this.createForm()
            this.createEntity = true
          }
        },
      ]
    }
  }

  ngOnDestroy() {
    if (this.dtWorkers) this.tableFilterService.changeSearchStatus(this.tableKey, false)

  }
}


